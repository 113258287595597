import { Badge, Card } from "@shopify/polaris";
function Header() {
  return (
    <div className="billing-header">
      <Card>
        <div className="header">
          <div className="header__left">
            <h2>Free</h2>
            <Badge status="success">Current Plan</Badge>
          </div>
          <div className="header__right">
            <div className="package-price">
              <strong>$0 USD</strong>/month
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Header;
