import React from 'react'
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!

type ContextProps = { 
    user: {
      fullName: string;
    owner_email: string;
    email_verified: boolean;
    onboarding_completed: boolean;
    id: string;
    isScriptEnabled: boolean;
    }
    updateContextUser?: any;
    isAuthenticated: boolean;
    setIsAuthenticated: any;
    isPaidUser: boolean;
    setIsPaidUser: any;
    redirectToSubscription?: boolean;
};

export const UserContext = React.createContext<Partial<ContextProps>>({});;