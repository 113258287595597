import React from 'react'

function Index(props:any) {
  return (
    <div style={{textAlign:"center"}}>
      Nothing to do. just click "enable/disable" for this section.
    </div>
  )
}

export default Index
