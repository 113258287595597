import { Card, Icon, Button, Badge } from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";
import { useState } from "react";

function Packages() {
    let [data, setData] = useState([
        {
            type: "Starter",
            price: "$49 USD",
            recommended: false,
            features: [
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics"
            ]
        },
        {
            type: "Medium",
            price: "$199 USD",
            recommended: true,
            features: [
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics"
            ]
        },
        {
            type: "Pro",
            price: "$599 USD",
            recommended: false,
            features: [
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics",
                "Custom Graphics"
            ]
        }
    ]);

    let upgradePackage = function (type: any) {
        console.log(type);
    };
    const test = (item: any) => {
        if (item.recommended) {
            return <Badge status="success">Recommended</Badge>;
        } else {
            return null;
        }
    };
    return (
        <div className="billing-main">
            {data.map((item, index) => (
                <Card>
                    <h3 className="package-type">
                        {item.type}
                        {test(item)}
                    </h3>
                    <div className="package-price">
                        <strong>{item.price}</strong>/month
                    </div>
                    <ul className="package-features">
                        {item.features.map((feature, index) => (
                            <div className="package-feature__item">
                                <Icon source={TickMinor} color="base" />
                                <span className="feature-details">
                                    {feature}
                                </span>
                            </div>
                        ))}
                    </ul>
                    <Button onClick={() => upgradePackage(item.type)} primary>
                        SELECT
                    </Button>
                </Card>
            ))}
        </div>
    );
}

export default Packages;
