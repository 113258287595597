import React, { useState } from "react";
import { SwitchOnIcon, SwitchOffIcon } from "../../../common/icons";
import { Card, Stack, TextStyle } from "@shopify/polaris";

export default function EnableSwitch(props: any) {
    console.log(props.val);
    const textStatus = props.val ? "Enable" : "Disable";
    return (
        <div style={{ width: "175px" }}>
            <Card>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px",
                        marginBottom: "20px"
                    }}
                >
                    <div
                        style={{ marginRight: "15px" }}
                        onClick={props.onChange}
                    >
                        {props.val ? <SwitchOnIcon /> : <SwitchOffIcon />}
                    </div>
                    <p
                        style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px"
                        }}
                    >
                        {" "}
                        {textStatus} Page
                    </p>
                </div>
            </Card>
        </div>
    );
}
