import { Page, AppProvider } from "@shopify/polaris";

import Header from "../../../components/Billing/Header";
import Packages from "../../../components/Billing/Packages";
import Reviews from "../../../components/Billing/Reviews";
import Contact from "../../../components/Billing/Contact";

import "./billing.scss";

function Billing() {
    return (
        <Page
            title="Billing"
            subtitle="Manage your billing plan and information"
        >
            <Header></Header>
            <Packages></Packages>
            <Reviews></Reviews>
            <Contact></Contact>
        </Page>
    );
}

export default Billing;
