import React, { useState, useEffect, useCallback, useContext } from "react";
import {
    Stack,
    RadioButton,
    Layout,
    DropZone,
    Thumbnail,
    Caption,
    TextField,
    Button
} from "@shopify/polaris";
import _ from "lodash";
import { NoteMinor } from "@shopify/polaris-icons";
import ImageSectionLeft from "../../../assets/ImageSectionImageLeft.svg";
import ImageSectionRight from "../../../assets/ImageSectionImageRight.svg";
import ImageSectionImageFullWidth from "../../../assets/ImageSectionImageFullWidth.svg";
import ImageSectionImageText from "../../../assets/ImageSectionImageText.svg";
import eventBus from "../../../common/EventBus";
import { SelectedPosition } from "../../../types-interfaces/YourAccount";

function DropZoneComponent(props: any) {
    let [file, setFile] = useState<any>();
    const { handleUpdatedData, allData } = props;
    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFile((file: any) => acceptedFiles[0]),
        []
    );

    useEffect(() => {
        if (file) {
            handleUpdatedData({
                ...allData,
                image_section: {
                    ...allData?.image_section,
                    image_file: file,
                    image_url: URL.createObjectURL(file)
                }
            });
        }
    }, [file]);
    useEffect(() => {
        eventBus.on("BAH:discardCurrentForm", (data: any) => {
            setFile(null);
        });
        return () => {
            eventBus.remove("BAH:discardCurrentForm");
        };
    }, []);
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

    const fileUpload = !file && <DropZone.FileUpload />;
    const uploadedFile = file && (
        <Stack>
            <img
                height={100}
                alt={file.name}
                src={
                    validImageTypes.includes(file.type)
                        ? (window.URL.createObjectURL(
                              file
                          ) as unknown as string)
                        : (NoteMinor as unknown as string)
                }
            />
            <div>
                {file.name} <Caption>{file.size} bytes</Caption>
            </div>
        </Stack>
    );

    return (
        <DropZone
            type={"image"}
            accept="image/*"
            allowMultiple={false}
            variableHeight={true}
            onDrop={handleDropZoneDrop}
        >
            {uploadedFile}
            {fileUpload}
        </DropZone>
    );
}

function ImageSection(props: any) {
    const { data, updateData } = props;
    console.log(props);
    return (
        <div
            onClick={(e: any) => {
                e.stopPropagation();
            }}
        >
            <Stack distribution={"fill"}>
                <Stack vertical alignment={"center"}>
                    <img
                        src={ImageSectionLeft}
                        alt={"previe left section"}
                        onClick={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.LEFT
                                }
                            });
                        }}
                    />
                    <RadioButton
                        label="Image on left"
                        checked={
                            data?.image_section.image_position ===
                            SelectedPosition.LEFT
                        }
                        name="image-left"
                        onChange={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.LEFT
                                }
                            });
                        }}
                    />
                </Stack>
                <Stack vertical alignment={"center"}>
                    <img
                        src={ImageSectionRight}
                        alt={"section preview left"}
                        onClick={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.RIGHT
                                }
                            });
                        }}
                    />
                    <RadioButton
                        label="Image on right"
                        name="image-right"
                        checked={
                            data?.image_section.image_position ===
                            SelectedPosition.RIGHT
                        }
                        onChange={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.RIGHT
                                }
                            });
                        }}
                    />
                </Stack>
            </Stack>
            <div style={{ marginTop: "25px" }}></div>
            <Stack distribution={"fill"}>
                <Stack vertical alignment={"center"}>
                    <img
                        src={ImageSectionImageText}
                        alt={"section preview text"}
                        onClick={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.TEXT
                                }
                            });
                        }}
                    />
                    <RadioButton
                        label="Text"
                        checked={
                            data?.image_section.image_position ===
                            SelectedPosition.TEXT
                        }
                        name="image-text"
                        onChange={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.TEXT
                                }
                            });
                        }}
                    />
                </Stack>
                <Stack vertical alignment={"center"}>
                    <img
                        src={ImageSectionImageFullWidth}
                        alt={"section preview full-width"}
                        onClick={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.FULLWIDTH
                                }
                            });
                        }}
                    />
                    <RadioButton
                        label="Image full width"
                        name="full-width"
                        checked={
                            data?.image_section.image_position ===
                            SelectedPosition.FULLWIDTH
                        }
                        onChange={() => {
                            updateData({
                                ...data,
                                image_section: {
                                    ...data?.image_section,
                                    image_position: SelectedPosition.FULLWIDTH
                                }
                            });
                        }}
                    />
                </Stack>
            </Stack>
            <div style={{ marginTop: "30px" }}></div>
            <div style={{ height: "150px", width: "100%" }}>
                <DropZoneComponent
                    handleUpdatedData={updateData}
                    allData={data}
                />
            </div>

            <div style={{ marginTop: "50px" }}></div>
            <TextField
                label="Heading"
                value={data?.image_section.heading}
                onChange={(val) => {
                    updateData({
                        ...data,
                        image_section: {
                            ...data?.image_section,
                            heading: val
                        }
                    });
                }}
            />
            <div style={{ marginTop: "30px" }}></div>
            <TextField
                label="Subheading"
                value={data?.image_section.sub_heading}
                onChange={(val) => {
                    updateData({
                        ...data,
                        image_section: {
                            ...data?.image_section,
                            sub_heading: val
                        }
                    });
                }}
            />
            <div style={{ marginTop: "30px" }}></div>
            <TextField
                label="Button Text"
                value={data?.image_section.button_text}
                onChange={(val) => {
                    updateData({
                        ...data,
                        image_section: {
                            ...data?.image_section,
                            button_text: val
                        }
                    });
                }}
            />
            <div style={{ marginTop: "30px" }}></div>
            <TextField
                label="Button Link"
                value={data?.image_section.button_link}
                onChange={(val) => {
                    updateData({
                        ...data,
                        image_section: {
                            ...data?.image_section,
                            button_link: val
                        }
                    });
                }}
            />
            <div style={{ marginTop: "45px" }}></div>
        </div>
    );
}

export default ImageSection;
