import React, { useEffect } from "react";

import { Cookies } from "react-cookie";
import { handleShopifyAuth } from "../../API";
import { Spinner } from "@shopify/polaris";

import { useHistory } from "react-router-dom";
import LoadingAnimation from "../../components/StaticComponents/LoadingAnimation";
function Index(props: any) {
    const history = useHistory();
    useEffect(() => {
        const Cookie = new Cookies();
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get("code");
        // const hmacQueryParams = urlParams.get('hmac');
        const shopParma = urlParams.get("shop");
        // const stateQueryParams = urlParams.get('state');
        const authType = props.match.params.auth_type;
        const fetchData = async () => {
            if (authType === "shopify") {
                const result = await handleShopifyAuth(codeParam, shopParma);
                if (result) {
                    Cookie.set("beam-cookie", result.accessToken, {
                        path: "/"
                    });
                    history.push("/");
                } else {
                    history.push("/error/auth");
                }
            } else {
                alert("invalid url.");
            }
        };
        fetchData();
        return () => {};
    }, []);
    return (
        <div style={{ textAlign: "center" }}>
            <LoadingAnimation />
        </div>
    );
}

export default Index;
