import {
    SkeletonThumbnail,
    Button,
    SkeletonDisplayText,
    SkeletonBodyText
} from "@shopify/polaris";

import "./orders.scss";
function OrdersPreview(props: any) {
    const parentClass =
        "skeleton__preview orders_preview orders_preview-" + props.device;
    return (
        <div className={parentClass}>
            <div className="orders_preview-header">
                <div className="orders_preview-header__left">
                    <SkeletonDisplayText />
                </div>
                <div className="orders_preview-header__right">
                    <div className="orders_preview-mobile__button">
                        <SkeletonDisplayText />
                    </div>
                    <div className="orders_preview-header__right-item">
                        <SkeletonBodyText lines={1} />
                        <SkeletonDisplayText />
                    </div>
                    <div className="orders_preview-header__right-item">
                        <SkeletonBodyText lines={1} />
                        <SkeletonDisplayText />
                    </div>
                    <div className="orders_preview-header__right-item">
                        <SkeletonBodyText lines={1} />
                        <SkeletonDisplayText />
                    </div>
                </div>
            </div>
            <div className="orders_preview-left">
                <SkeletonThumbnail size="medium" />
                <SkeletonThumbnail size="medium" />
                <SkeletonThumbnail size="medium" />
            </div>
            <div className=" orders_preview-right">
                <SkeletonDisplayText />
                <SkeletonDisplayText />
                <SkeletonDisplayText />
            </div>
            <div className="skeleton__preview-footer orders_preview-footer">
                <SkeletonDisplayText />
                <Button primary>{props.button}</Button>
            </div>
        </div>
    );
}

export default OrdersPreview;
