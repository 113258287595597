import axios from 'axios';
import {getJwtToken} from './common'
import { UserDetails } from '../types-interfaces'
const SERVER_URL = process.env.REACT_APP_API_URL;
//test
console.log(SERVER_URL)
export const getShopifyRedirectURLDashboardUser = async (shop_name: string) => {
  try {
    const result = await axios.get(`${SERVER_URL}/user/signin/shopify?shop_name=${shop_name}`);
    
    if (result) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const getShopifyRedirectURLForCustomerAccounts = async (token: string) => {
  try {
    const result = await axios.get(`${SERVER_URL}/customer/redirect/shopify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (result) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};
export const handleShopifyAuth = async (authcode: string | null, shop_name: string | null) => {
  try {
    const result = await axios.post(`${SERVER_URL}/user/signin/shopify`, {
      authCode: `${authcode}`,
      userShopName: `${shop_name}`,
    });

    if (result) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const handleFacebookAuth = async (authcode: string | null, state: string | null) => {
  try {
    const result = await axios.post(`${SERVER_URL}/customer/signin/facebook`, {
      authCode: `${authcode}`,
      sessionId: `${state}`,
    });

    if (result) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const handleGoogleAuth = async (authcode: string | null, state: string | null) => {
  try {
    const result = await axios.post(`${SERVER_URL}/customer/signin/google`, {
      authCode: `${authcode}`,
      sessionId: `${state}`,
    });

    if (result) {
      return result.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};


export const verifyToken = async (): Promise<UserDetails | boolean> => {
  try {
   const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/user/verify`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return false;
  } catch (err) {
    return false;
  }
}
