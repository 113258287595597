import React, { useState, useEffect, useContext, createContext } from "react";
import { Layout, Card, TextField, Button } from "@shopify/polaris";
import { SwitchOffIcon, SwitchOnIcon } from "../../common/icons";
import YourOrdersPreview from "./Previews/index";
import eventBus from "../../common/EventBus";

function YourOrders(props: any) {
    return (
        <div>
            <Layout>
                <Layout.Section oneHalf>
                    <div>
                        <Card>
                            <div
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: 25,
                                    padding: 25
                                }}
                            >
                                <div
                                    onClick={() => {
                                        props.changeSwitch();
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}
                                    >
                                        {props &&
                                        props.data &&
                                        props.data.additionalLinkEnabled ? (
                                            <>
                                                <SwitchOnIcon />
                                            </>
                                        ) : (
                                            <>
                                                <SwitchOffIcon />
                                            </>
                                        )}
                                        <p
                                            style={{
                                                marginLeft: "15px",
                                                fontWeight: 400,
                                                fontSize: "14px",
                                                color: "#202223",
                                                lineHeight: "20px"
                                            }}
                                        >
                                            {" "}
                                            Addtional Link
                                        </p>
                                    </div>
                                </div>
                                <div style={{ marginTop: "35px" }}>
                                    <TextField
                                        label="Link Title"
                                        placeholder="NEED HELP ?"
                                        value={props.data.linkTitle}
                                        onChange={(val) => {
                                            props.changeLinkTitle(val);
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                    <TextField
                                        label="Link"
                                        placeholder="https://"
                                        value={props.data.link}
                                        onChange={(val) => {
                                            props.changeLink(val);
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "135px" }}>
                                    <Button
                                        loading={props.isDataSubmitting}
                                        primary
                                        onClick={() => {
                                            eventBus.dispatch(
                                                "BAH:saveYourOrdersForm",
                                                {
                                                    message:
                                                        "Save button clicked"
                                                }
                                            );
                                        }}
                                    >
                                        Save
                                    </Button>{" "}
                                    <Button
                                        disabled={props.isDataSubmitting}
                                        onClick={() => {
                                            eventBus.dispatch(
                                                "BAH:discardYourOrdersForm",
                                                {
                                                    message:
                                                        "discard button clicked"
                                                }
                                            );
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <YourOrdersPreview button={props.data.linkTitle} />
                </Layout.Section>
            </Layout>
        </div>
    );
}

export default YourOrders;
