import React from 'react'
// Make sure the shape of the default value passed to
// createContext matches the shape that the consumers expect!

type ContextProps = { 
    didValueChange: boolean;
    updateValueChange: any;
    dataSubmitting: boolean;
    updateDataSubmitting: any;
};

export const GlobalContext = React.createContext<Partial<ContextProps>>({});;