import { Layout } from "@shopify/polaris";
import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { EditorState } from "draft-js";
import DraggableCard from "../../components/Accounts/DraggableCard";
import PromoCodeAccountForm from "./PromoCode/index";
import ImageSection from "./ImageSection/index";
import LeaveReview from "./LeaveReview/Index";
import FeaturedItems from "./FeaturedItems/Index";
import SubscribeToNewsletterSection from "./SubscribeToNewsLetter/index";
import PreviewSection from "./Previews/Index";

import eventBus from "../../common/EventBus";

function YourAccount(props: any) {
    const [openCard, setOpenCard] = useState("promo-section");
    const [initialLoading, setInitialLoading] = useState(true);
    const [items, setItems] = useState(props.orderOfSection);
    const [formData, setFormData] = useState<any>(props.mainData);
    function updateFormData(data: any) {
        setFormData(data);
    }

    useEffect(() => {
        setInitialLoading(false);
    }, []);

    useEffect(() => {
        if (!initialLoading) {
            props.setFinalData(formData);
        }
    }, [formData]);
    useEffect(() => {
        eventBus.on("BAH:discardCurrentForm", (data: any) => {
            setFormData(props.initialData);
        });
        return () => {
            eventBus.remove("BAH:discardCurrentForm");
        };
    }, []);
    useEffect(() => {
        if (!initialLoading) {
            props.changeOrder(items);
        }
    }, [items]);
    useEffect(() => {
        let tempData = {
            promo_section: false,
            image_section: false,
            leave_review_section: false,
            newsletter_section: false,
            featured_items_section: false
        };
        if (formData && formData.promo_section) {
            if (
                formData.promo_section.selected_id &&
                formData.promo_section.selected_id.length > 0
            ) {
                tempData.promo_section = true;
            }
        }
        if (formData && formData.image_section) {
            if (formData.image_section.image_file) {
                tempData.image_section = true;
            }
            if (
                formData.image_section.image_url &&
                formData.image_section.image_url !== "null"
            ) {
                tempData.image_section = true;
            }
        }
        if (formData && formData.newsletter_section) {
            if (formData.newsletter_section.image_file) {
                tempData.newsletter_section = true;
            }
            if (
                formData.newsletter_section.image_url &&
                formData.newsletter_section.image_url !== "null"
            ) {
                tempData.newsletter_section = true;
            }
        }
        if (formData && formData.leave_review) {
            if (formData.leave_review.isEnabled) {
                tempData.leave_review_section = true;
            }
        }
        if (formData && formData.featured_items) {
            if (formData.featured_items.isEnabled) {
                tempData.featured_items_section = true;
            }
        }
        props.setIsFormComplete({
            ...tempData
        });
    }, [formData]);

    const getComponent = (componentType: any, data: any, dragHandle: any) => {
        if (componentType == "promo_section") {
            return (
                <DraggableCard
                    heading="Promo Section"
                    type="promo-section"
                    subHeading="Select promocode & add to layout"
                    dragHandle={dragHandle}
                    openCard={openCard}
                    handleCardOpen={setOpenCard}
                    isFormCompelted={props.isFormComplete.promo_section}
                    isEnabled={formData?.promo_section?.isEnabled}
                    changeEnableDisable={() => {
                        updateFormData({
                            ...formData,
                            promo_section: {
                                ...formData.promo_section,
                                isEnabled: !formData.promo_section.isEnabled
                            }
                        });
                    }}
                    CollapisbleComponent={
                        <PromoCodeAccountForm
                            data={formData}
                            updateData={updateFormData}
                        />
                    }
                />
            );
        } else if (componentType == "image_section") {
            return (
                <DraggableCard
                    CollapisbleComponent={
                        <ImageSection
                            data={formData}
                            updateData={updateFormData}
                        />
                    }
                    isFormCompelted={props.isFormComplete.image_section}
                    type="image-section"
                    heading="Image Section"
                    isEnabled={formData?.image_section?.isEnabled}
                    changeEnableDisable={() => {
                        updateFormData({
                            ...formData,
                            image_section: {
                                ...formData.image_section,
                                isEnabled: !formData.image_section.isEnabled
                            }
                        });
                    }}
                    dragHandle={dragHandle}
                    openCard={openCard}
                    handleCardOpen={setOpenCard}
                    subHeading="Select promocode & add to layout"
                />
            );
        } else if (componentType == "leave_review") {
            return (
                <DraggableCard
                    heading="Leave a Review"
                    type="leave-review-section"
                    openCard={openCard}
                    isFormCompelted={props.isFormComplete.leave_review_section}
                    handleCardOpen={setOpenCard}
                    isEnabled={formData?.leave_review?.isEnabled}
                    changeEnableDisable={() => {
                        updateFormData({
                            ...formData,
                            leave_review: {
                                ...formData.leave_review,
                                isEnabled: !formData.leave_review.isEnabled
                            }
                        });
                    }}
                    dragHandle={dragHandle}
                    CollapisbleComponent={
                        <LeaveReview
                            data={formData}
                            updateData={updateFormData}
                        />
                    }
                    subHeading="Select promocode & add to layout"
                />
            );
        } else if (componentType == "newsletter_section") {
            return (
                <DraggableCard
                    heading="Subscribe to Newsletter"
                    subHeading="Select promocode & add to layout"
                    type="newsletter-section"
                    dragHandle={dragHandle}
                    isFormCompelted={props.isFormComplete.newsletter_section}
                    isEnabled={formData?.newsletter_section?.isEnabled}
                    openCard={openCard}
                    changeEnableDisable={() => {
                        updateFormData({
                            ...formData,
                            newsletter_section: {
                                ...formData.newsletter_section,
                                isEnabled:
                                    !formData.newsletter_section.isEnabled
                            }
                        });
                    }}
                    handleCardOpen={setOpenCard}
                    CollapisbleComponent={
                        <SubscribeToNewsletterSection
                            data={formData}
                            updateData={updateFormData}
                        />
                    }
                />
            );
        } else if (componentType == "featured_items") {
            return (
                <DraggableCard
                    CollapisbleComponent={
                        <FeaturedItems
                            data={formData}
                            updateData={updateFormData}
                        />
                    }
                    type="featured-items-section"
                    heading="Featured Items"
                    isFormCompelted={
                        props.isFormComplete.featured_items_section
                    }
                    isEnabled={formData?.featured_items?.isEnabled}
                    dragHandle={dragHandle}
                    openCard={openCard}
                    changeEnableDisable={() => {
                        updateFormData({
                            ...formData,
                            featured_items: {
                                ...formData.featured_items,
                                isEnabled: !formData.featured_items.isEnabled
                            }
                        });
                    }}
                    handleCardOpen={setOpenCard}
                    subHeading="Select promocode & add to layout"
                />
            );
        }
    };

    // a little function to help us with reordering the result
    const reorder = (list: any, startIndex: any, endIndex: any): any[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const itemsL = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(itemsL);
    };

    return (
        <div>
            <Layout>
                <Layout.Section oneHalf>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided: any, snapshot: any) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {items.map((item: any, index: any) => (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(provided: any, snapshot: any) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    {getComponent(
                                                        item.id,
                                                        {},
                                                        provided.dragHandleProps
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <PreviewSection data={formData} openCard={openCard} />
                </Layout.Section>
            </Layout>
        </div>
    );
}

export default YourAccount;
