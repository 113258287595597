import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { Banner } from "@shopify/polaris";
import * as _ from "lodash";
import MainForm from "./components/MainForm";
import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import { getPromoPageData, savePromoPageData } from "../../API";
import { GlobalContext } from "../../Contexts/GlobalContext";
import eventBus from "../../common/EventBus";
import LoadingAnimation from "../../components/StaticComponents/LoadingAnimation";

export enum SelectedTypes {
    AMOUNT_DISCOUNT = "amount_discount",
    PERCENTAGE_OFF = "percentage_off",
    FREE_SHIPPING = "free_shipping"
}
export enum TimeType {
    AM = "AM",
    PM = "PM"
}
export enum SelectedLayoutTypes {
    IMAGE_TEXT = "image_text",
    ONLY_TEXT = "only_text"
}
export enum RedemptionStyleTypes {
    FIXED_AMOUNT = "fixed_amount",
    INC_POINTS = "inc_points"
}
export enum AppliesToTypes {
    ENTIRE_ORDER = "ENTIRE_ORDER",
    SPECIFIC_COLLECTION = "SPECIFIC_COLLECTIONS"
}
export enum MinRequirementsTypes {
    NONE = "none",
    MIN_PURCHASE_POINTS = "min_purchase_points"
}
export interface FreeShippingTypes {
    reward_points_cost: string;
    max_shipping_amount_set: boolean;
    reward_discount_value: string;
}
export interface AmountDiscountTypes {
    redemption_style: RedemptionStyleTypes;
    reward_points_cost: string;
    reward_discount_value: string;
    applies_to: AppliesToTypes;
    min_requirements: MinRequirementsTypes;
}
export interface PercentageOffTypes {
    reward_points_cost: string;
    reward_discount_value: string;
    applies_to: AppliesToTypes;
    min_requirements: MinRequirementsTypes;
}
export interface ImageDataTypes {
    image_url: string;
    image_file: Blob | null;
}
export interface PromoTypes {
    is_expanded: boolean;
    selected_type: SelectedTypes;
    discount_code: string;
    discount_code_prefix: string;
    amount_discount: AmountDiscountTypes;
    percentage_off: PercentageOffTypes;
    free_shipping: FreeShippingTypes;
    discount_code_prefix_enabled: boolean;
    section_title: string;
    selected_layout: SelectedLayoutTypes;
    images_data: ImageDataTypes;
    heading: string;
    sub_heading: string;
    is_limited_time: boolean;
    selected_start_time: {
        date: number;
        month: number;
        year: number;
        time: string;
        type: TimeType;
    };
    selected_end_time: {
        date: number;
        month: number;
        year: number;
        time: string;
        type: TimeType;
    };
}
export interface PromoFormDataTypes {
    isEnabled: boolean;
    promos: PromoTypes[];
}
function PromoCodes() {
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const history = useHistory();
    const [formData, setFormData] = useState<PromoFormDataTypes>({
        isEnabled: false,
        promos: []
    });
    const [initialData, setInitialData] = useState<PromoFormDataTypes>({
        isEnabled: false,
        promos: []
    });
    const [submitForm, setSubmitForm] = useState(false);
    const [discardForm, setDiscardForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const handleDiscard = async () => {
        console.log("discard promo page");
        console.log(initialData);
        setDiscardForm(true);
    };
    const saveData = async () => {
        console.log("Calling save data....");
        setSubmitForm(false);
        updateDataSubmitting(true);
        const result = await savePromoPageData(formData);
        if (result) {
            updateDataSubmitting(false);
            const tempData = {
                ...formData
            };
            setInitialData({
                ...tempData
            });
            setFormData({
                ...tempData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
    };
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log("mounted event");
            eventBus.on("BAH:discardPromoCodesForm", (data: any) => {
                handleDiscard();
            });
        }
        return () => {
            console.log(
                "unmounted event listener: discard current form in promo cards"
            );
            eventBus.remove("BAH:discardPromoCodesForm");

            mounted = false;
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:savePromoCodesForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            console.log("unmounted event");
            eventBus.remove("BAH:savePromoCodesForm");
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted && submitForm) {
            console.log("save data");
            saveData();
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);

    useEffect(() => {
        let mounted = true;

        if (mounted && discardForm) {
            setFormData(initialData);
            // const contentBlock = htmlToDraft(initialData.empty_state_text);
            // if (contentBlock) {
            //     const contentState = ContentState.createFromBlockArray(
            //         contentBlock.contentBlocks
            //     );
            //     const editorState = EditorState.createWithContent(contentState);
            //     setEditorState(editorState);
            // }
            setDiscardForm(false);
        }
        return () => {
            mounted = false;
        };
    }, [discardForm]);
    useEffect(() => {
        console.log("initial data use effect running");
        let mounted = true;
        if (mounted) {
            if (!dataLoading) {
                console.log("initial data updated");
                console.log(initialData);
                console.log(`isEqual-iD = ${_.isEqual(initialData, formData)}`);
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [initialData]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log(`formData updated`);
            console.log(formData);
            if (!dataLoading) {
                console.log(`isEqualfD = ${_.isEqual(initialData, formData)}`);
                console.log("form data");
                console.log(initialData);
                console.log(formData);
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [formData]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getPromoPageData();
                if (result) {
                    console.log(result);
                    setInitialData({ ...result });
                    setFormData({ ...result });
                    setDataLoading(false);
                } else {
                    setDataLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    const removePromo = (index: number) => {
        let tempCollection = formData.promos;
        tempCollection.splice(index, 1);
        setFormData({ ...formData, promos: [...tempCollection] });
    };
    const addNewPromo = () => {
        let promoObject = {
            is_expanded: true,
            discount_code: "",
            discount_code_prefix: "",
            selected_type: SelectedTypes.AMOUNT_DISCOUNT,
            amount_discount: {
                redemption_style: RedemptionStyleTypes.FIXED_AMOUNT,
                reward_points_cost: `0`,
                reward_discount_value: `0`,
                applies_to: AppliesToTypes.ENTIRE_ORDER,
                min_requirements: MinRequirementsTypes.NONE
            },
            percentage_off: {
                reward_points_cost: `0`,
                reward_discount_value: `0`,
                applies_to: AppliesToTypes.ENTIRE_ORDER,
                min_requirements: MinRequirementsTypes.NONE
            },
            free_shipping: {
                reward_points_cost: `0`,
                max_shipping_amount_set: false,
                reward_discount_value: `0`
            },
            discount_code_prefix_enabled: false,
            section_title: "",
            selected_layout: SelectedLayoutTypes.IMAGE_TEXT,
            images_data: {
                image_url: "",
                image_file: null
            },
            heading: "",
            sub_heading: "",
            is_limited_time: false,
            selected_start_time: {
                date: new Date().getDate(),
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                time: "12",
                type: TimeType.AM
            },
            selected_end_time: {
                date: new Date().getDate(),
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                time: "12",
                type: TimeType.AM
            },
            uniqueCode: `${Math.floor(Date.now() / 1000)}-new-code-${Math.floor(
                Math.random() * 1000 + 1
            )}`
        };
        setFormData({
            ...formData,
            promos: [...formData.promos, promoObject]
        });
    };
    return (
        <>
            <PageWrapper
                title="Promo Codes"
                subtitle="Promo Codes"
                separator={true}
                showSwitchButton={true}
                isDisabled={!didValueChange}
                id="promo-codes-page"
                loading={dataLoading}
                SwitchVal={formData.isEnabled}
                onChangeSwitchVal={() => {
                    setFormData({
                        ...formData,
                        isEnabled: !formData.isEnabled
                    });
                }}
            >
                {/* <Settings componentChanged={componentChanged} /> */}
                {showSuccessMessage && (
                    <Banner
                        title="Saved your account data"
                        status="success"
                        action={{
                            content: "Go To Dashboard",
                            onAction: () => {
                                history.push("/");
                            }
                        }}
                        onDismiss={() => {
                            setShowSuccessMessage(false);
                        }}
                    />
                )}
                {showErrorMessage && (
                    <Banner
                        title="Something went wrong while saving."
                        status="critical"
                        action={{
                            content: "Go To Dashboard",
                            onAction: () => {
                                history.push("/");
                            }
                        }}
                        onDismiss={() => {
                            setShowErrorMessage(false);
                        }}
                    />
                )}

                <div style={{ marginBottom: "10px" }}></div>

                <MainForm
                    data={formData}
                    addNewPromo={addNewPromo}
                    removePromo={removePromo}
                    savePromo={() => {}}
                    setData={(dataObj: PromoFormDataTypes) => {
                        setFormData({ ...dataObj });
                    }}
                />
            </PageWrapper>
        </>
    );
}

export default PromoCodes;
