import React, { useState } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link as ReactRouterLink
} from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import logo from "./logo.svg";
import ShopifyRedirect from "./pages/Login/redirect-page";
import LoginViaShopifyRedirect from "./pages/Login/LoginViaShopifyApp";
import Login from "./pages/Login/Login";
import SocialLoginRedirect from "./pages/SocialLoginRedirect";
import PrivateRoutesWithLayout from "./common/PrivateRoutesWithLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import DashboardPage from "./pages/Dashboard";
import DesignPage from "./pages/Design/Index";
import BillingPage from "./pages/Settings/Billing/index";
import LangPage from "./pages/Settings/Language/Index";
import YourAccountPage from "./pages/YourAccount/index";
import YourOrdersPage from "./pages/YourOrders/index";
import GiftCardsPage from "./pages/GiftCards/index";
import PromoCodesPage from "./pages/PromoCodes/index";
import WishListPage from "./pages/WishList/index";
import SettingsPage from "./pages/Settings/index";
import GeneralSettingsPage from "./pages/Settings/General/Index";
import NotificationsSettingsPage from "./pages/Settings/Notification/Index";
import RecommendedItems from "./pages/RecommendedItems/index";
import AuthErrorPage from "./pages/Error";
import PageNotFoundComponent from "./pages/Error/404";
import { UserContext } from "./Contexts/AppUserContext";

import { GlobalContext } from "./Contexts/GlobalContext";

function Link(props: any) {
    // Use an regular a tag for external and download links
    if (isOutboundLink(props.url) || props.download) {
        return (
            <a href={props.url} {...props}>
                {props.children}
            </a>
        );
    }

    return (
        <ReactRouterLink to={props.url} {...props}>
            {props.children}
        </ReactRouterLink>
    );
}

function isOutboundLink(url: string) {
    return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

function App() {
    const [didValueChange, setValueChange] = useState(false);
    const [isDataSubmitting, setIsDataSubmitting] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [user, updateUser] = React.useState({
        fullName: "",
        owner_email: "",
        email_verified: false,
        onboarding_completed: false,
        isScriptEnabled: false,
        id: ""
    });
    function updateContextUser(updatedUser: any) {
        updateUser(updatedUser);
    }
    function globalContextUser(globalVal: any) {
        setValueChange(globalVal);
    }
    function setAuthVal(val: boolean) {
        setIsAuth(val);
    }
    function setPaidVal(val: boolean) {
        setIsPaid(val);
    }
    function setDataLoading(val: boolean) {
        setIsDataSubmitting(val);
    }
    return (
        <AppProvider
            i18n={enTranslations}
            linkComponent={Link}
            theme={{
                // colors: {
                //     surface: "#111213",
                //     onSurface: "#111213",
                //     interactive: "#2e72d2",
                //     secondary: "#111213",
                //     primary: "#3b5998",
                //     critical: "#d82c0d",
                //     warning: "#ffc453",
                //     highlight: "#5bcdda",
                //     success: "#008060",
                //     decorative: "#ffc96b"
                // },
                logo: {
                    width: 50,
                    topBarSource: logo,
                    contextualSaveBarSource: logo,
                    url: "crucialcommerceapps.com/",
                    accessibilityLabel: "Crucial Commerce"
                }
            }}
        >
            <GlobalContext.Provider
                value={{
                    didValueChange: didValueChange,
                    updateValueChange: globalContextUser,
                    dataSubmitting: isDataSubmitting,
                    updateDataSubmitting: setIsDataSubmitting
                }}
            >
                <UserContext.Provider
                    value={{
                        user,
                        updateContextUser,
                        isAuthenticated: isAuth,
                        setIsAuthenticated: setAuthVal,
                        isPaidUser: isPaid,
                        setIsPaidUser: setPaidVal
                    }}
                >
                    <Router>
                        <Switch>
                            <Route
                                path="/customer/auth/account/redirect/:auth_type"
                                component={SocialLoginRedirect}
                            />
                            <Route
                                path="/auth/account/redirect/:auth_type"
                                component={ShopifyRedirect}
                            />
                            <Route
                                path="/auth/account/app/redirect/:auth_type"
                                component={LoginViaShopifyRedirect}
                            />
                            <Route
                                key={`login-page`}
                                exact
                                path="/login"
                                component={Login}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard`}
                                exact
                                path="/"
                                layout={DashboardLayout}
                                component={DashboardPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard-your-account`}
                                exact
                                path="/pages/account"
                                layout={DashboardLayout}
                                component={YourAccountPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard-your-orders`}
                                exact
                                path="/pages/orders"
                                layout={DashboardLayout}
                                component={YourOrdersPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard-gift-cards`}
                                exact
                                path="/pages/gift-cards"
                                layout={DashboardLayout}
                                component={GiftCardsPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard-gift-cards`}
                                exact
                                path="/pages/promo-codes"
                                layout={DashboardLayout}
                                component={PromoCodesPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard-gift-cards`}
                                exact
                                path="/pages/wishlist"
                                layout={DashboardLayout}
                                component={WishListPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`dashboard-gift-cards`}
                                exact
                                path="/pages/recommended-items"
                                layout={DashboardLayout}
                                component={RecommendedItems}
                            />
                            <PrivateRoutesWithLayout
                                // key={`design`}
                                exact
                                path="/design"
                                layout={DashboardLayout}
                                component={DesignPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`settings-page`}
                                exact
                                path="/settings"
                                layout={DashboardLayout}
                                component={SettingsPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`billing-page`}
                                exact
                                path="/settings/billing"
                                layout={DashboardLayout}
                                component={BillingPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`billing-page`}
                                exact
                                path="/settings/general"
                                layout={DashboardLayout}
                                component={GeneralSettingsPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`billing-page`}
                                exact
                                path="/settings/notification"
                                layout={DashboardLayout}
                                component={NotificationsSettingsPage}
                            />
                            <PrivateRoutesWithLayout
                                // key={`lang-settings-page`}
                                exact
                                path="/settings/lang"
                                layout={DashboardLayout}
                                component={LangPage}
                            />

                            <Route
                                // key={"/error-page-auth"}
                                path={"/error/auth"}
                                exact
                                component={AuthErrorPage}
                            />
                            <Route
                                // key={"/error-page-404"}
                                component={PageNotFoundComponent}
                            />
                        </Switch>
                    </Router>
                </UserContext.Provider>
            </GlobalContext.Provider>
        </AppProvider>
    );
}

export default App;
