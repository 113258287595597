import './image_section.scss';
import {
  SkeletonBodyText,
  SkeletonDisplayText,
  Button,
  SkeletonThumbnail,
} from '@shopify/polaris';

import PlaceholderForSection from '../../../../assets/placeholderImageForSection.svg';

function ImageSection(props: any) {
  const heading = (
    <h2 className="skeleton__preview-heading  image-section__preview-heading">
      {props.heading ? (
        <span>{props.heading.map((item:any) => item)}</span>
      ) : (
        <SkeletonDisplayText />
      )}
    </h2>
  );

  const subheading = (
    <div className="skeleton__preview-subheading image-section__preview-subheading">
      {props.subheading ? (
        <span>{props.subheading.map((item:any) => item)}</span>
      ) : (
        <SkeletonBodyText lines={2} />
      )}
    </div>
  );

  const button =  (
    <Button>
      {props.button && props.button.length > 0 ? props.button : `Learn more`}
    </Button>
  ) 

  const imageWrapper = props.src ? (
    <img src={props.src} alt="" />
  ) : (
    
    <img src={PlaceholderForSection} alt="" style={{height:"200px"}} />
  );

  const parentClass =
    'skeleton__preview image-section__preview layout-' +
    props.layout +
    ' skeleton__preview-' +
    props.device;

  return (
    <div className={parentClass}>
      {props.layout === 'none' ? (
        ''
      ) : (
        <div className="skeleton__preview-image image-section__preview-image">
          {imageWrapper}
        </div>
      )}
      <div className="skeleton__preview-content image-section__preview-content">
        {heading}
        {subheading}
        {button}
      </div>
    </div>
  );
}

export default ImageSection;
