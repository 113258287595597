import {
    DisplayText,
    TextStyle,
    Card,
    RangeSlider,
    Stack,
    RadioButton,
    Heading,
    Icon,
    ResourceList,
    ButtonGroup,
    Form,
    TextContainer,
    Button,
    Popover,
    FormLayout,
    Select,
    TextField,
    Spinner,
    Modal
} from "@shopify/polaris";
import React, { useCallback, useState, useRef } from "react";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import { PlusMinor, ImagesMajor } from "@shopify/polaris-icons";

const PopOverCollectionSelection = (props: any) => {
    const CollectionList = props.data.map((name: any) => ({
        name
    }));

    const [popoverActive, setPopoverActive] = useState(true);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );
    const handleResourceListItemClick = (val: any) => {
        const findElem = props.data.find((elem: any) => elem[`id`] == val);
        if (findElem) {
            props.onCollectionSelection({
                id: findElem[`id`],
                title: findElem[`title`],
                handle: findElem[`handle`]
            });
        }

        setPopoverActive(false);
    };
    function renderItem(props: any) {
        let data = props.name;
        console.log(data);

        return (
            <ResourceList.Item
                id={data.id}
                media={
                    data && data.image && data.image.src ? (
                        <img
                            src={data.image.src}
                            color="base"
                            alt={data.image.alt}
                            height={25}
                        />
                    ) : (
                        <Icon source={ImagesMajor} color="base" />
                    )
                }
                onClick={handleResourceListItemClick}
            >
                {data.title}
            </ResourceList.Item>
        );
    }

    const activatorLink = (
        <div
            style={{ width: "100%", height: "100%" }}
            onClick={togglePopoverActive}
        >
            <TextField
                label={<TextStyle variation="strong">Link</TextStyle>}
                onChange={() => {}}
                value={props.selectedCollection.title}
                placeholder="Collection Title"
                clearButton
                onClearButtonClick={() => {
                    props.onCollectionSelection({
                        id: ``,
                        title: ``,
                        handle: ``
                    });
                }}
            />
        </div>
    );

    return (
        <div>
            <Popover
                active={popoverActive}
                activator={activatorLink}
                onClose={togglePopoverActive}
                fullWidth={true}
                fullHeight={true}
            >
                <div>
                    <div className="popoverHead">
                        <div className="btnContain">
                            <div onClick={togglePopoverActive}>{"<"} Back</div>
                        </div>
                        <div className="resultBox">
                            <TextStyle variation="subdued">
                                {props.data.length} results
                            </TextStyle>
                        </div>
                    </div>
                    {props.collectionLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <Spinner size={"large"} />
                        </div>
                    ) : (
                        // <p>Load more...</p>
                        <>
                            <ResourceList
                                items={CollectionList}
                                renderItem={renderItem}
                            />
                        </>
                    )}
                </div>
            </Popover>
        </div>
    );
};

export default PopOverCollectionSelection;
