import React, { useEffect } from "react";

import { Cookies } from "react-cookie";
import { handleShopifyAuth } from "../../API";
import { Spinner } from "@shopify/polaris";
//
import {
    getShopifyRedirectURLDashboardUser,
    verifyToken
} from "../../API/index";
import LoadingAnimation from "../../components/StaticComponents/LoadingAnimation";
import { useHistory } from "react-router-dom";
function Index(props: any) {
    const handleLogin = async (shop: string) => {
        const getURL = await getShopifyRedirectURLDashboardUser(shop);
        if (getURL) {
            window.location.href = `${getURL}`;
        } else {
        }
    };
    const history = useHistory();
    useEffect(() => {
        const Cookie = new Cookies();
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get("code");
        // const hmacQueryParams = urlParams.get('hmac');
        const shopParam = urlParams.get("shop") ?? "";
        // const stateQueryParams = urlParams.get('state');
        const authType = props.match.params.auth_type;
        const fetchData = async () => {
            if (authType === "shopify") {
                if (shopParam && shopParam.length > 0) {
                    const result = await handleLogin(shopParam);
                } else {
                    history.push("/error/auth");
                }
            } else {
                history.push("/error/auth");
            }
        };
        fetchData();
        return () => {};
    }, []);
    return (
        <div style={{ textAlign: "center" }}>
            <LoadingAnimation />
        </div>
    );
}

export default Index;
