import {
    Icon,
    Button,
    SkeletonThumbnail,
    SkeletonDisplayText,
    SkeletonBodyText
} from "@shopify/polaris";

import { HeartMajor } from "@shopify/polaris-icons";
import "./wishlistGrid.scss";
function WishlistGrid(props: any) {
    const parentClass =
        "skeleton__preview wishlist-grid_preview wishlist-grid_preview-style-" +
        props.position +
        " wishlist-grid_preview-" +
        props.device;
    const productsToShow = [1, 2, 3];

    return (
        <div className={parentClass}>
            {productsToShow.map((index) => (
                <div key={index} className="wishlist-grid_preview-item">
                    <div className="skeleton__preview-image wishlist-grid_preview-image">
                        <SkeletonThumbnail size="medium" />
                        <div
                            className="wishlist-grid_preview-button"
                            style={{
                                fill: props.color,
                                backgroundColor: props.backgroundColor
                            }}
                        >
                            <Icon source={HeartMajor} color={props.color} />
                        </div>
                    </div>
                    <div
                        style={
                            {
                                "--p-action-primary": props.btnColor
                            } as React.CSSProperties
                        }
                        className="skeleton__preview-content wishlist-grid_preview-content"
                    >
                        <h2 className="skeleton__preview-heading  wishlist-grid_preview-heading">
                            PRODUCT TITLE
                        </h2>
                        <div className="skeleton__preview-subheading wishlist-grid_preview-subheading">
                            $9,99
                        </div>
                        <Button primary>Add To Cart</Button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default WishlistGrid;
