import {
    Thumbnail,
    SkeletonThumbnail,
    SkeletonDisplayText,
    SkeletonBodyText
} from "@shopify/polaris";
import { HeartMajor } from "@shopify/polaris-icons";
import React from "react";
import "./wishlistHeader.scss";
function WishlistHeader(props: any) {
    const parentClass =
        "skeleton__preview wishlist-header_preview skeleton__preview-" +
        props.device;
    let button;
    if (props.type === "floating") {
        button = (
            <div
                style={
                    {
                        "--p-icon-subdued": props.color,
                        "--p-surface": props.backgroundColor
                    } as React.CSSProperties
                }
                className="wishlist-header_preview-button wishlist-button__floating"
            >
                <Thumbnail source={HeartMajor} alt="Heart Icon" />
            </div>
        );
    } else if (props.type === "menu_item") {
        button = (
            <div
                className="wishlist-header_preview-button wishlist-button__menu"
                style={{ color: props.backgroundColor }}
            >
                WISHLIST{" "}
                {props.count ? (
                    <span className="wishlist-button__count">
                        {"(" + props.count + ")"}
                    </span>
                ) : (
                    ""
                )}
            </div>
        );
    } else {
        button = (
            <div
                className="wishlist-header_preview-button wishlist-button__icon"
                style={
                    {
                        "--p-icon-subdued": props.backgroundColor,
                        color: props.backgroundColor
                    } as React.CSSProperties
                }
            >
                <Thumbnail source={HeartMajor} alt="Heart Icon" />
                {props.count ? (
                    <span className="wishlist-button__count">
                        {"(" + props.count + ")"}
                    </span>
                ) : (
                    ""
                )}
            </div>
        );
    }
    return (
        <div className={parentClass}>
            {props.type === "floating" ? button : ""}
            <div className="wishlist-header__preview__header">
                <div className="wishlist-header__preview__logo">
                    <SkeletonDisplayText size="large" />
                </div>
                <div className="wishlist-header__preview__nav">
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                    {props.type === "menu_item" ? button : ""}
                </div>
                <div className="wishlist-header__preview__icons">
                    <SkeletonThumbnail size="small" />
                    <SkeletonThumbnail size="small" />
                    {props.type === "icon" ? button : ""}
                </div>
            </div>
            <div className="wishlist-header__main-section">
                <SkeletonThumbnail />
            </div>
        </div>
    );
}

export default WishlistHeader;
