import React, { useState, useCallback, useEffect } from "react";
import {
    Card,
    Heading,
    TextField,
    TextStyle,
    RadioButton,
    Stack,
    Thumbnail,
    Caption,
    Banner,
    List,
    Button,
    FormLayout,
    Select,
    Checkbox
} from "@shopify/polaris";
import { SwitchOffIcon, SwitchOnIcon } from "../../../common/icons";
import ImageAndTextIcon from "../../../assets/ImageAndTextCommonForm.svg";
import TextOnlyCommonFormIcon from "../../../assets/TextOnlyCommonForm.svg";
import { convertFromRaw, ContentState, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import TextEditorMinimal from "../../../components/Common/TextEditorMinimal/TextEditorMinimal";
import eventBus from "../../../common/EventBus";
import DropZoneComponent from "./DropZoneComponent";
import "../Promo.scss";
import { PromoFormDataTypes, PromoTypes, SelectedLayoutTypes } from "..";

interface PropsType {
    IndexVal: number;
    data: PromoTypes;
    onDataChange: (index: number, val: PromoTypes) => void;
    savePromo: any;
    deletePromo: any;
}
const TimeDropdown = [
    "12",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11"
];
const CommonForm = (props: PropsType) => {
    const { data, onDataChange, IndexVal } = props;

    const handleUpdatedData = (fieldName: string, val: any) => {
        onDataChange(IndexVal, {
            ...props.data,
            [fieldName]: val
        });
    };
    const handleUpdatedStartTime = (fieldName: string, val: any) => {
        onDataChange(IndexVal, {
            ...props.data,
            selected_start_time: {
                ...props.data.selected_start_time,
                [fieldName]: val
            }
        });
    };
    const handleUpdatedEndTime = (fieldName: string, val: any) => {
        onDataChange(IndexVal, {
            ...props.data,
            selected_end_time: {
                ...props.data.selected_end_time,
                [fieldName]: val
            }
        });
    };

    const [headingEditorState, setHeadingEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    );
    const [subHeadingEditorState, setSubHeadingEditorState] =
        useState<EditorState>(EditorState.createEmpty());
    // useEffect(() => {
    //     const contentBlock = htmlToDraft(data.featured_items.editorState);
    //     if (contentBlock) {
    //         const contentState = ContentState.createFromBlockArray(
    //             contentBlock.contentBlocks
    //         );
    //         const editorState = EditorState.createWithContent(contentState);
    //         setEditorState(editorState);
    //     }
    // }, []);
    // const toHTMLString = stateToHTML(editorState.getCurrentContent());
    // useEffect(() => {
    //     // setFormData({
    //     //     ...formData,
    //     //     empty_state_text: toHTMLString
    //     // });
    // }, [editorState]);

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className="subtitleTextStyle">
                <TextStyle variation="strong">Discount Code</TextStyle>

                {data.discount_code_prefix_enabled ? (
                    <TextField
                        label="Prefix To Add"
                        value={data.discount_code}
                        prefix={data.discount_code_prefix}
                        onChange={(val: any) => {
                            handleUpdatedData(`discount_code`, val);
                        }}
                    />
                ) : (
                    <TextField
                        label="Prefix To Add"
                        value={data.discount_code}
                        onChange={(val: any) => {
                            handleUpdatedData(`discount_code`, val);
                        }}
                    />
                )}
            </div>
            <FormLayout>
                <Stack vertical>
                    <Checkbox
                        label="Add a prefix to discount code"
                        checked={data.discount_code_prefix_enabled}
                        onChange={(val: any) => {
                            handleUpdatedData(
                                `discount_code_prefix_enabled`,
                                val
                            );
                        }}
                    />
                    {data.discount_code_prefix_enabled && (
                        <TextField
                            label="Prefix Text"
                            value={data.discount_code_prefix}
                            onChange={(val: any) => {
                                handleUpdatedData(`discount_code_prefix`, val);
                            }}
                        />
                    )}
                </Stack>
            </FormLayout>
            <div>
                <div className="head">
                    <p style={{ fontSize: "14px", lineHeight: "20px" }}>
                        Section Title
                    </p>
                </div>
                <div>
                    <TextField
                        label=""
                        placeholder=" My First Promocode"
                        value={data.section_title}
                        onChange={(val: any) => {
                            handleUpdatedData(`section_title`, val);
                        }}
                    />
                </div>
                <div>
                    <TextStyle variation="subdued">
                        This is only for internal use, to indentify your
                        sections.
                    </TextStyle>
                </div>
            </div>

            <div>
                <div className="head">
                    <Heading element="h6">Select Layout</Heading>
                </div>

                <Stack distribution={"fill"}>
                    <Stack vertical alignment={"center"}>
                        <div
                            style={{ textAlign: "center" }}
                            onClick={() => {
                                handleUpdatedData(
                                    `selected_layout`,
                                    SelectedLayoutTypes.IMAGE_TEXT
                                );
                            }}
                        >
                            <img
                                src={ImageAndTextIcon}
                                alt={"Text Plus Plus  Only"}
                            />
                            <div>
                                <RadioButton
                                    label="Image & Text"
                                    checked={
                                        data.selected_layout ===
                                        SelectedLayoutTypes.IMAGE_TEXT
                                    }
                                    name="image-text-only"
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack vertical alignment={"center"}>
                        <div
                            style={{ textAlign: "center" }}
                            onClick={() => {
                                handleUpdatedData(
                                    `selected_layout`,
                                    SelectedLayoutTypes.ONLY_TEXT
                                );
                            }}
                        >
                            <img
                                src={TextOnlyCommonFormIcon}
                                alt={"Text Only"}
                            />
                            <div>
                                <RadioButton
                                    label="Text"
                                    name="text-only"
                                    checked={
                                        data.selected_layout ===
                                        SelectedLayoutTypes.ONLY_TEXT
                                    }
                                />
                            </div>
                        </div>
                    </Stack>
                </Stack>
            </div>

            <div className="dropBox">
                <Stack vertical>
                    {/* {errorMessage} */}
                    <DropZoneComponent
                        data={props.data.images_data}
                        handleUpdatedData={(val: any) => {
                            onDataChange(IndexVal, {
                                ...props.data,
                                images_data: {
                                    image_file: val,
                                    image_url: ""
                                }
                            });
                        }}
                    />
                </Stack>
            </div>

            <div>
                <div className="head">
                    <p style={{ fontSize: "14px", lineHeight: "20px" }}>
                        Heading
                    </p>
                </div>
                {headingEditorState ? (
                    <TextEditorMinimal
                        editorState={headingEditorState}
                        onEditorStateChange={(val: any) => {
                            setHeadingEditorState(val);
                        }}
                    />
                ) : (
                    <></>
                )}
            </div>

            <div>
                <div className="head">
                    <p style={{ fontSize: "14px", lineHeight: "20px" }}>
                        Subheading
                    </p>
                </div>
                {subHeadingEditorState ? (
                    <TextEditorMinimal
                        editorState={subHeadingEditorState}
                        onEditorStateChange={(val: any) => {
                            setSubHeadingEditorState(val);
                        }}
                    />
                ) : (
                    <></>
                )}
            </div>

            <div>
                <div className="limitedTimeHead">
                    <div
                        style={{ marginRight: "15px" }}
                        onClick={() => {
                            handleUpdatedData(
                                `is_limited_time`,
                                !props.data.is_limited_time
                            );
                        }}
                    >
                        {!props.data.is_limited_time ? (
                            <SwitchOffIcon />
                        ) : (
                            <SwitchOnIcon />
                        )}
                    </div>

                    <div>
                        <Heading element="h6">Limited Time</Heading>
                    </div>
                </div>
            </div>
            <div>
                <div className="head">
                    <TextStyle variation="strong">Select Start Time</TextStyle>
                </div>
                <div className="timeTextContainer">
                    <div className="timeText">
                        <TextField
                            label=""
                            type="number"
                            placeholder="Date"
                            min={1}
                            max={31}
                            value={`${props.data.selected_start_time.date}`}
                            onChange={(val: any) => {
                                handleUpdatedStartTime(`date`, val);
                            }}
                        />
                    </div>
                    &nbsp;
                    <div className="timeText">
                        <TextField
                            label=""
                            type="number"
                            placeholder="Month"
                            min={1}
                            max={12}
                            value={`${props.data.selected_start_time.month}`}
                            onChange={(val: any) => {
                                handleUpdatedStartTime(`month`, val);
                            }}
                        />
                    </div>
                    &nbsp;
                    <div className="timeText">
                        <TextField
                            label=""
                            type="number"
                            placeholder="Year"
                            value={`${props.data.selected_start_time.year}`}
                            onChange={(val: any) => {
                                handleUpdatedStartTime(`year`, val);
                            }}
                        />
                    </div>
                </div>
                <div className="timeSelectionContainer">
                    <div className="timeTextTime">
                        <Select
                            label=""
                            options={TimeDropdown}
                            onChange={(val: any) => {
                                handleUpdatedStartTime(`time`, val);
                            }}
                            value={`${props.data.selected_start_time.time}`}
                        />
                    </div>
                    <div
                        className="timeTextTime"
                        style={{ marginLeft: "25px" }}
                    >
                        <Select
                            label=""
                            options={["AM", "PM"]}
                            onChange={(val: any) => {
                                handleUpdatedStartTime(`type`, val);
                            }}
                            value={`${props.data.selected_start_time.type}`}
                        />
                    </div>
                </div>
            </div>

            <div>
                <div className="head">
                    <TextStyle variation="strong">Select End Time</TextStyle>
                </div>
                <div className="timeTextContainer">
                    <div className="timeText">
                        <TextField
                            label=""
                            type="number"
                            placeholder="Date"
                            min={1}
                            max={31}
                            value={`${props.data.selected_end_time.date}`}
                            onChange={(val: any) => {
                                handleUpdatedEndTime(`date`, val);
                            }}
                        />
                    </div>
                    &nbsp;
                    <div className="timeText">
                        <TextField
                            label=""
                            type="number"
                            placeholder="Month"
                            min={1}
                            max={12}
                            value={`${props.data.selected_end_time.month}`}
                            onChange={(val: any) => {
                                handleUpdatedEndTime(`month`, val);
                            }}
                        />
                    </div>
                    &nbsp;
                    <div className="timeText">
                        <TextField
                            label=""
                            type="number"
                            placeholder="Year"
                            value={`${props.data.selected_end_time.year}`}
                            onChange={(val: any) => {
                                handleUpdatedEndTime(`year`, val);
                            }}
                        />
                    </div>
                </div>
                <div className="timeSelectionContainer">
                    <div className="timeTextTime">
                        <Select
                            label=""
                            options={TimeDropdown}
                            onChange={(val: any) => {
                                handleUpdatedEndTime(`time`, val);
                            }}
                            value={`${props.data.selected_end_time.time}`}
                        />
                    </div>
                    <div
                        className="timeTextTime"
                        style={{ marginLeft: "25px" }}
                    >
                        <Select
                            label=""
                            options={["AM", "PM"]}
                            onChange={(val: any) => {
                                handleUpdatedEndTime(`type`, val);
                            }}
                            value={`${props.data.selected_end_time.type}`}
                        />
                    </div>
                </div>
            </div>

            <div className="buttonBox">
                {/* <div>
                    <Button
                        size="medium"
                        onClick={() => {
                            props.savePromo(IndexVal);
                        }}
                    >
                        Save
                    </Button>{" "}
                </div> */}

                <div className="redBtn">
                    <Button
                        size="medium"
                        monochrome
                        outline
                        onClick={() => {
                            props.deletePromo(IndexVal);
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CommonForm;
