import { useEffect, useState } from "react";
import {
    SkeletonBodyText,
    SkeletonThumbnail,
    SkeletonDisplayText
} from "@shopify/polaris";
import parseStringToHtml from "html-react-parser";
import "./featuredItems.scss";
function FeaturedItemsSection(props: any) {
    const itemsKey = [1, 2, 3, 4];

    const parentClass =
        "featured-items__preview  skeleton__preview skeleton__preview-" +
        props.device;

    const headingDiv = (
        <h2 className="skeleton__preview-heading  featured-items__preview-heading">
            {props.heading && props.heading.length > 0 ? (
                <>{parseStringToHtml(props.heading)}</>
            ) : (
                <SkeletonDisplayText />
            )}
        </h2>
    );

    return (
        <div className={parentClass}>
            {headingDiv}
            {itemsKey.map((index) => (
                <div key={index} className="featured-items__preview-item">
                    <SkeletonThumbnail />
                    <SkeletonBodyText />
                </div>
            ))}
        </div>
    );
}

export default FeaturedItemsSection;
