import { Icon, Thumbnail, Card, Button } from "@shopify/polaris";
const contactUs = () => {
}
function Contact() {
    return (
        <div className="billing-contact">
            <Card>
                <div className="billing-contact__info">
                    <h3>Larger Bussiness?</h3>
                    <Button onClick={() => contactUs()} size="large">Let's Talk</Button>
                </div>
            </Card>
        </div>
    )
}

export default Contact;
