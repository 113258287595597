import React, {useState, useCallback, useEffect} from 'react'
import {Button, Card,TextField, Heading} from '@shopify/polaris';
import {useHistory} from 'react-router-dom'
import {getShopifyRedirectURLDashboardUser,verifyToken} from '../../API/index'
import bottomLeftSVG from '../../assets/bottomLeft.svg';
import bottomRightSVG from '../../assets/bottomRight.svg';
import companyLogoSVG from '../../assets/companyLogo.svg';
function Login() {
  const history = useHistory();
  const [formValue, setFormValue] = useState({
    store_url: "",
  });

  useEffect(() => {
    const getVerifyToken = async () => {
      const getVerifiedUser = await verifyToken();
      if(getVerifiedUser){
        history.push("/")
      }
    }
    getVerifyToken()
    return () => {
      
    }
  }, [])

  const handleChange = (useCallback((newValue) => setFormValue({...formValue,store_url: newValue}), []));
  const handleLogin = async () => {
    const getURL = await getShopifyRedirectURLDashboardUser(formValue.store_url);
    if(getURL){
      window.location.href = `${getURL}`
    }
    else {

    }
  }
  return (
    <>
      <img src={bottomRightSVG} style={{
       backgroundPosition:"bottom right", 
       position:"absolute",
       backgroundRepeat: `no-repeat`,
       right: 0,
       bottom: 0,
       marginRight: 0,
       marginBottom: 0,
       zIndex: 5000
       }} />
     <img src={bottomLeftSVG} style={{
       backgroundPosition:"bottom left", 
       position:"absolute",
       backgroundRepeat: `no-repeat`,
       left: 0,
       bottom: 0,
       marginRight: 0,
       marginBottom: 0,
       zIndex: 5000
       }} />
    <div style={{textAlign:"center"}}>
     
     
       <div style={{ 
         display:"flex", justifyContent:"center",alignItems:'center'}}>
      <div style={{marginTop:"150px"}}>
      <Card>
        <div style={{height:"500px", width:"600px"}}>
        <img src={companyLogoSVG} height={80} width={80} style={{marginTop:"48px"}} />
        <div style={{marginTop:"32px"}}></div>
        <Heading element="h1">Log In To Your Account</Heading>
        <p style={{color:"#6D7175", marginTop:"10px"}}>Access CC Admin Dashboard</p>
        
        <div style={{paddingLeft:"118px", paddingRight:"118px", marginTop:"50px"}}>
          <TextField
        label="Store URL"
        value={formValue.store_url}
        onChange={handleChange}
        prefix={"https://"}
        suffix={".myshopify.com"}
      />
      <div style={{marginTop:"44px"}}></div>
      <Button primary onClick={handleLogin}>Login</Button>
        </div>
        </div>
      </Card>
      </div>
       </div>
    </div>
    </>
  )
}

export default Login
