import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Page, Button, Banner } from "@shopify/polaris";
import * as _ from "lodash";
import YourOrders from "./YourOrders";
import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import { getYourOrderPageData, saveYourOrderPageData } from "../../API/index";
import { GlobalContext } from "../../Contexts/GlobalContext";
import LoadingAnimation from "../../components/StaticComponents/LoadingAnimation";
import eventBus from "../../common/EventBus";

function YourOrdersPage(props: any) {
    const history = useHistory();
    const [formData, setFormData] = useState<any>({
        additionalLinkEnabled: false,
        linkTitle: "NEED HELP ?",
        isEnabled: false,
        link: ""
    });
    const [initialData, setInitialData] = useState<any>({
        additionalLinkEnabled: false,
        isEnabled: false,
        linkTitle: "NEED HELP ?",
        link: ""
    });
    const [submitForm, setSubmitForm] = useState(false);
    const [discardForm, setDiscardForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const changeSwitch = () => {
        setFormData({
            ...formData,
            additionalLinkEnabled: !formData.additionalLinkEnabled
        });
    };

    const changeLinkTitle = (data: string) => {
        setFormData({
            ...formData,
            linkTitle: data
        });
    };
    const changeLink = (data: string) => {
        setFormData({
            ...formData,
            link: data
        });
    };
    const handleDiscard = async () => {
        console.log("discard from your orders.");
        console.log(initialData);
        setDiscardForm(true);
    };
    const saveData = async () => {
        console.log("Calling save data....");
        setSubmitForm(false);
        updateDataSubmitting(true);
        const result = await saveYourOrderPageData({
            isEnabled: formData.isEnabled,
            additionalLinkEnabled: formData.additionalLinkEnabled,
            link_title: formData.linkTitle,
            link_url: formData.link
        });
        if (result) {
            updateDataSubmitting(false);
            setInitialData({
                ...formData
            });
            setFormData({
                ...formData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
    };
    useEffect(() => {
        let mounted = true;

        if (mounted && discardForm) {
            setFormData(initialData);
            setDiscardForm(false);
        }
        return () => {
            mounted = false;
        };
    }, [discardForm]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getYourOrderPageData();
                if (result) {
                    setInitialData({
                        isEnabled: result.isEnabled,
                        additionalLinkEnabled: result.additionalLinkEnabled,
                        linkTitle: result.link_title,
                        link: result.link_url
                    });
                    setFormData({
                        isEnabled: result.isEnabled,
                        additionalLinkEnabled: result.additionalLinkEnabled,
                        linkTitle: result.link_title,
                        link: result.link_url
                    });
                    setInitialLoading(false);
                } else {
                    setInitialLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    useEffect(() => {
        eventBus.on("BAH:discardYourOrdersForm", (data: any) => {
            handleDiscard();
        });
        return () => {
            console.log(
                "unmounted event listener: discard current form in your orders"
            );
            eventBus.remove("BAH:discardYourOrdersForm");
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:saveYourOrdersForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            console.log("unmounted event");
            eventBus.remove("BAH:saveYourOrdersForm");
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted && submitForm) {
            saveData();
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (!initialLoading) {
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [formData, initialData]);
    return (
        <PageWrapper
            title="Your Orders"
            subtitle="Edit order card options."
            separator={true}
            loading={initialLoading}
            showSwitchButton={true}
            SwitchVal={formData.isEnabled}
            onChangeSwitchVal={() => {
                setFormData({
                    ...formData,
                    isEnabled: !formData.isEnabled
                });
            }}
            isDisabled={!didValueChange}
            id="your-account-page"
        >
            {initialLoading ? (
                <>
                    <LoadingAnimation
                        accessibilityLabel="loading account page data"
                        size="large"
                    />
                </>
            ) : (
                <>
                    {showSuccessMessage && (
                        <Banner
                            title="Saved your account data"
                            status="success"
                            action={{
                                content: "Go To Dashboard",
                                onAction: () => {
                                    history.push("/");
                                }
                            }}
                            onDismiss={() => {
                                setShowSuccessMessage(false);
                            }}
                        />
                    )}
                    {showErrorMessage && (
                        <Banner
                            title="Something went wrong while saving."
                            status="critical"
                            action={{
                                content: "Go To Dashboard",
                                onAction: () => {
                                    history.push("/");
                                }
                            }}
                            onDismiss={() => {
                                setShowErrorMessage(false);
                            }}
                        />
                    )}

                    <YourOrders
                        data={formData}
                        changeSwitch={changeSwitch}
                        changeLink={changeLink}
                        changeLinkTitle={changeLinkTitle}
                        onSave={saveData}
                        isDataSubmitting={dataSubmitting}
                    />
                </>
            )}
        </PageWrapper>
    );
}

export default YourOrdersPage;
