import { useState, useContext, useEffect } from "react";

import ImageSection from "./ImageSection/index";
import Newsletter from "./Newsletter/index";
import PromoSection from "./PromoSection/index";
import FeaturedItems from "./FeaturedItems/index";
import ReviewSection from "./ReviewSection/index";

import { OptionList, Card, Thumbnail } from "@shopify/polaris";

import { MobileMajor, TabletMajor, DesktopMajor } from "@shopify/polaris-icons";

import { SelectedPosition } from "../../../types-interfaces/YourAccount";
import PlaceholderYourAccountImage from "../../../assets/placeholderImageForYourAccount.svg";
import "./skeleton.scss";

function Settings(props: any) {
    const { data, openCard } = props;
    const [previewIsOverFlowing, setPreviewOverFlow] = useState(false);
    const mobileLabel = <Thumbnail source={MobileMajor} alt="Mobile" />;
    const tabletLabel = <Thumbnail source={TabletMajor} alt="Tablet" />;
    const desktopLabel = <Thumbnail source={DesktopMajor} alt="Desktop" />;
    const [device, setDevice] = useState(["desktop"]);
    useEffect(() => {
        setInterval(() => {
            const getElement = document.getElementById(
                "checkOverFlowForPreview"
            );
            if (getElement) {
                const getOverFlow =
                    getElement.scrollHeight > window.screen.height;

                setPreviewOverFlow(getOverFlow);
            }
        }, 300);
    }, []);
    const previewComponentByType = (type: string) => {
        const getPosition = (val: string) => {
            if (val == SelectedPosition.RIGHT) {
                return "right";
            }
            if (val == SelectedPosition.LEFT) {
                return "left";
            }
            if (val == SelectedPosition.FULLWIDTH) {
                return "full";
            }
            if (val == SelectedPosition.TEXT) {
                return "none";
            }
        };
        if (openCard == "image-section") {
            return (
                <ImageSection
                    heading={[data.image_section.heading]} //leave empty to show placeholder
                    subheading={[data.image_section.sub_heading]} //leave empty to show placeholder
                    button={data.image_section.button_text} //leave empty to show placeholder
                    src={data.image_section.image_url} //leave empty to show placeholder
                    layout={
                        data?.image_section
                            ? getPosition(data?.image_section.image_position)
                            : "none"
                    } //left,right,none,full
                    device={device}
                />
            );
        } else if (openCard == "newsletter-section") {
            return (
                <Newsletter
                    heading={[data.newsletter_section.heading]} //leave empty to show placeholder
                    subheading={[data.newsletter_section.sub_heading]} //leave empty to show placeholder
                    button={data.newsletter_section.button_text} //leave empty to show placeholder
                    src={data.newsletter_section.image_url} //leave empty to show placeholder
                    layout={
                        data?.newsletter_section
                            ? getPosition(
                                  data?.newsletter_section.image_position
                              )
                            : "none"
                    } //left,right,none,full
                    device={device}
                />
            );
        } else if (openCard == "promo-section") {
            return (
                <PromoSection
                    heading={[]} //leave empty to show placeholder
                    subheading={[]} //leave empty to show placeholder
                    src={data?.promo_section.selected_code_image} //leave empty to show placeholder
                    layout={"image"} //image,none
                    device={device}
                />
            );
        } else if (openCard == "featured-items-section") {
            return (
                <FeaturedItems
                    heading={data?.featured_items.heading} //leave empty to show placeholder
                    device={device}
                />
            );
        } else if (openCard == "leave-review-section") {
            return (
                <ReviewSection
                    heading={[
                        <b>LEAVE A REVIEW AND GET DISCOUNT</b>,
                        <p> ON YOUR NEXT ORDER</p>
                    ]} //leave empty to show placeholder
                    device={device}
                />
            );
        } else {
            return (
                <>
                    <img
                        src={PlaceholderYourAccountImage}
                        alt="temp-your-account-placeholder"
                        width="100%"
                    />
                </>
            );
        }
    };
    return (
        <div
            id="checkOverFlowForPreview"
            style={{
                paddingTop: "10px",
                position: previewIsOverFlowing ? "unset" : "fixed",
                zIndex: 100,
                width: "40vw",
                height: "auto"
            }}
        >
            <div className="skeleton__preview-header">
                <Card>
                    <OptionList
                        onChange={(val) => {
                            setDevice(val);
                        }}
                        options={[
                            { value: "mobile", label: mobileLabel },
                            { value: "tablet", label: tabletLabel },
                            { value: "desktop", label: desktopLabel }
                        ]}
                        selected={device}
                    />
                </Card>
            </div>

            <div style={{ marginTop: "25px" }}></div>
            {openCard && previewComponentByType(data?.openCard)}
        </div>
    );
}

export default Settings;
