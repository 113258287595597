import React, { useState, useEffect } from "react";
import { Card, Spinner, Icon } from "@shopify/polaris";
import CardWithGraph from "./CardWithGraph";
import { SettingsMajor } from "@shopify/polaris-icons";
import {
    getDashboardDataForCustomers,
    getDashboardDataForRevenue
} from "../../API/index";
function AnalyticsCards(props: any) {
    const [loadingCustomersData, setLoadingCustomersData] = useState(true);
    const [loadingRevenueData, setLoadingRevenueData] = useState(true);
    const [customersData, setCustomersData] = useState<any>({
        total: 0,
        sinceLast: 0,
        type: "positive"
    });
    const [revenueData, setRevenueData] = useState<any>({
        total: 0,
        sinceLast: 0,
        type: "negative"
    });
    useEffect(() => {
        const fetchData = async () => {
            const dataForRevenue = await getDashboardDataForRevenue();

            if (dataForRevenue) {
                setRevenueData(dataForRevenue);
                setLoadingRevenueData(false);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const dataForCustomers = await getDashboardDataForCustomers();

            if (dataForCustomers) {
                setCustomersData(dataForCustomers);
                setLoadingCustomersData(false);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <div style={{ display: "flex", marginTop: "50px" }}>
                <div style={{ flexGrow: 1 }}>
                    <p
                        style={{
                            fontWeight: 600,
                            fontStyle: "normal",
                            fontSize: "20px",
                            lineHeight: "28px"
                        }}
                    >
                        What’s happening right now in your account
                    </p>
                </div>
                <div style={{ cursor: "pointer" }}>
                    <Icon source={SettingsMajor} color="base" />
                </div>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
                <div style={{ width: "50%" }}>
                    <CardWithGraph
                        title={"Revenue Created"}
                        data={revenueData}
                        loading={loadingRevenueData}
                    />
                </div>
                <div style={{ width: "50%", marginLeft: "5px" }}>
                    <CardWithGraph
                        title={"Total Customers"}
                        data={customersData}
                        loading={loadingCustomersData}
                    />
                </div>
            </div>
        </>
    );
}

export default AnalyticsCards;
