import { Button, Card, DisplayText, Icon } from '@shopify/polaris'
import React from 'react'
import shopifyIcon from '../../assets/shopifyIcon.svg'

const goldIcon = () =>
(<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M5 12L1.376 9C0.691999 8.453 0.991999 7.353 1.86 7.229L7 7L9.195 1.497C9.348 1.192 9.659 1 10 1C10.341 1 10.652 1.193 10.805 1.497L13 7L18.14 7.229C19.008 7.353 19.308 8.453 18.624 9L15 12L15.989 17.857C16.103 18.656 15.273 19.254 14.552 18.893L10 16L5.448 18.892C4.726 19.253 3.897 18.655 4.011 17.856L5 12Z" fill="#FFC453"/>
</svg>
)
const ReviewPage = () => {
    return (
        <div style={{ marginTop:"50px"}}>

            <Card>
                <div  style={{  padding : 80, display : 'flex',
                                flexDirection : 'column', justifyContent : 'center', alignItems : 'center'}}>
                <div>
                    <img src={shopifyIcon} />
                </div>
                <div style={{display: 'flex', flexDirection : 'row', marginTop:"30px"}}>
                    <Icon source={goldIcon}  />
                    <Icon source={goldIcon}  />
                    <Icon source={goldIcon}  />
                    <Icon source={goldIcon}  />
                    <Icon source={goldIcon}  />
                </div>

                <div style={{ marginTop : "15px"}}>
                   <p style={{fontSize: "16px",fontWeight: 600, lineHeight:"24px"}}>
                   Would you consider leaving us a review ?
                   </p>
                </div>

                <div style={{  marginTop : "15px"}}>
                    <p style={{color: "#5C5F62", fontSize: "14px",fontWeight: "normal",textAlign:"center"}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                </div>
                <div style={{marginTop : "25px", flexDirection : 'row', display : 'flex'}}>
                    <Button>
                        Leave a review
                    </Button>
                </div>
                </div>
            </Card>
            
        </div>
    )
}

export default ReviewPage
