import axios from "axios";
import { getJwtToken } from "./common";
const SERVER_URL = process.env.REACT_APP_API_URL;
//test
console.log(SERVER_URL);

export const enableScriptForStore = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.post(
            `${SERVER_URL}/user/script/enable`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const disableScriptForStore = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.post(
            `${SERVER_URL}/user/script/disable`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const checkScriptEnableStatus = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.get(`${SERVER_URL}/user/can-enable-script`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            cancelToken: source.token
        });
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const getGeneralSettings = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.get(`${SERVER_URL}/user/settings/general`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            cancelToken: source.token
        });
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const saveGeneralSettings = async (
    settings_data: any,
    multipass_token: string
) => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.post(
            `${SERVER_URL}/user/settings/general`,
            {
                settings: settings_data,
                multipass_token: multipass_token
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const getEmailNotificationSettings = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.get(`${SERVER_URL}/user/settings/email`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            cancelToken: source.token
        });
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const saveEmailNotificationSettings = async (settings_data: any) => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.post(
            `${SERVER_URL}/user/settings/email`,
            {
                settings: settings_data
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const getDashboardDataForRevenue = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.get(
            `${SERVER_URL}/user/dashboard/data/revenue`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};
export const getDashboardDataForCustomers = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.get(
            `${SERVER_URL}/user/dashboard/data/customers`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const getColorSettings = async () => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.get(`${SERVER_URL}/user/settings/color`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            cancelToken: source.token
        });
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const saveColorSettings = async (
    settings_data: any,
    custom_enabled: boolean
) => {
    try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        const token = await getJwtToken();
        const result = await axios.post(
            `${SERVER_URL}/user/settings/color`,
            {
                settings: settings_data,
                customColorEnabled: custom_enabled
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                cancelToken: source.token
            }
        );
        if (result.status === 201 || result.status === 200) {
            return result.data;
        }
        return null;
    } catch (err) {
        return null;
    }
};
