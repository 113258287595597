import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Icon, Thumbnail, Card } from "@shopify/polaris";
import { StarFilledMinor, ProfileMajor } from "@shopify/polaris-icons";
import { useState } from "react";

function Reviews() {
  let [reviews, setReviews] = useState([
    {
      heading: "Awesome Application for our store!",
      review:
        "Id nisi bibendum aliquet felis condimentum. Morbi quis aliquam pellentesque elementum mattis. Amet bibendum tristique.",
      brand: {
        image: "test.png",
        alt: "image",
      },
      rating: 3,
    },
    {
      heading: "Awesome Application for our store!",
      review:
        "Id nisi bibendum aliquet felis condimentum. Morbi quis aliquam pellentesque elementum mattis. Amet bibendum tristique.",
      brand: {
        image: "test.png",
        alt: "image",
      },
      rating: 5,
    },
    {
      heading: "Awesome Application for our store!",
      review:
        "Id nisi bibendum aliquet felis condimentum. Morbi quis aliquam pellentesque elementum mattis. Amet bibendum tristique.",
      brand: {
        image: "test.png",
        alt: "image",
      },
      rating: 5,
    },
  ]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div className="billing-carousel">
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        autoPlaySpeed={1000}
        responsive={responsive}
      >
        {reviews.map((review) => (
          <Card>
            <div className="slide">
              <Thumbnail source={ProfileMajor} alt={review.brand.alt} />
              <div className="slide-rating">
                {[...Array(review.rating)].map((i) => (
                  <Icon key={i} source={StarFilledMinor} color="red" />
                ))}
              </div>

              <h4>{review.heading}</h4>
              <div className="slide-review">{review.review}</div>
            </div>
          </Card>
        ))}
      </Carousel>
    </div>
  );
}

export default Reviews;
