import "./language.scss";

import { Page } from "@shopify/polaris";
import Form from "./Form";

function Language() {
    return (
        <Page
            primaryAction={{ content: "Save", disabled: true }}
            secondaryActions={[{ content: "Cancel", disabled: true }]}
            title="Language"
            subtitle="This is the language your customers see in app."
        >
            <Form></Form>
        </Page>
    );
}

export default Language;
