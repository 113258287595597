import React, {
    useState,
    useEffect,
    useCallback,
    ReactElement,
    useContext
} from "react";
import { convertFromRaw, ContentState, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./draft-editor.scss";

function TextEditorMinimal(props: any) {
    const { editorState, onEditorStateChange } = props;
    return (
        <>
            <Editor
                editorState={editorState}
                editorClassName="demo-editor"
                toolbar={{
                    options: ["inline"],
                    inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline"]
                    }
                }}
                onEditorStateChange={onEditorStateChange}
            />
        </>
    );
}

export default TextEditorMinimal;
