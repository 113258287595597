import { useState, useEffect, useContext } from "react";
import {
    Route,
    Redirect,
    useHistory,
    RouteProps,
    useLocation
} from "react-router-dom";

import { UserDetails } from "../types-interfaces";
import { Spinner } from "@shopify/polaris";
import { verifyToken } from "../API";
import { UserContext } from "../Contexts/AppUserContext";
import LoadingAnimation from "../components/StaticComponents/LoadingAnimation";
import ErrorBoundary from "../ErrorBoundary";
const verifyTokenFunc = async (): Promise<UserDetails | boolean> => {
    const result = await verifyToken();
    return result;
};

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any;
    layout: any;
}

const PrivateRoutesWithoutLayout = ({
    component: Component,
    layout: Layout,
    ...rest
}: PrivateRouteProps) => {
    let history = useHistory();
    const location = useLocation();
    const {
        user,
        updateContextUser,
        isAuthenticated,
        setIsAuthenticated,
        isPaidUser,
        setIsPaidUser
    } = useContext(UserContext);

    useEffect(() => {
        async function getData() {
            const result = await verifyTokenFunc();

            if (result) {
                let tempUserDetailsObject = result as UserDetails;
                let isPaid = false;
                if (!tempUserDetailsObject.redirectToSubscription) {
                    isPaid = true;
                }
                setIsPaidUser(isPaid);
                setIsAuthenticated(true);
                updateContextUser(result);
            } else {
                history.push("/login");
            }
        }
        getData();
    }, []);

    if (!isAuthenticated) {
        return (
            <div style={{ textAlign: "center" }}>
                <LoadingAnimation
                    accessibilityLabel="Loading page"
                    size="large"
                />
            </div>
        );
    }
    return (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated) {
                    return (
                        <Layout>
                            <ErrorBoundary>
                                <Component {...props} />
                            </ErrorBoundary>
                        </Layout>
                    );
                } else {
                    return <Redirect to="/login" />;
                }
            }}
        />
    );
};

export default PrivateRoutesWithoutLayout;
