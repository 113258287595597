import React, { Component } from "react";
import { AppProvider, Button, DisplayText, TextStyle } from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import errorIcon from "./assets/404Icon.svg";
type MyProps = {};
type MyState = { error: any; errorInfo: any };

const ErrorPage = () => {
    const history = useHistory();
    return (
        <div
            style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "25px",
                width: "100%",
                position: "absolute"
            }}
        >
            <div style={{ width: "100%", paddingLeft: "10%" }}>
                <div style={{ fontSize: 150, fontWeight: "bold" }}>500</div>
                <div style={{ paddingTop: 100 }}>
                    <DisplayText size="large">
                        Something went wrong!
                    </DisplayText>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: 20
                    }}
                >
                    <div style={{ color: "grey" }}>
                        <DisplayText size="small">
                            {" "}
                            Contact us by email{" "}
                        </DisplayText>
                    </div>
                    &nbsp; &nbsp;
                    <div
                        style={{
                            color: "blue",
                            textDecorationLine: "underline"
                        }}
                    >
                        <DisplayText size="small">
                            support@email.com
                        </DisplayText>
                    </div>
                </div>
                <div style={{ paddingTop: 20 }}>
                    <Button
                        onClick={() => {
                            history.push("/");
                        }}
                    >
                        Back to Dashboard
                    </Button>
                </div>
            </div>
            {/* Image */}
            <div
                style={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex"
                }}
            >
                <img src={errorIcon} />
            </div>
        </div>
    );
};

export default class ErrorBoundary extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <>
                    <ErrorPage />
                </>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
