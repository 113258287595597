import { useState } from "react";

import WishlistHeader from "./WishlistHeader/index";
import WishlistGrid from "./WishlistGrid/index";
import WishlistProduct from "./WishlistProduct/index";

import { OptionList, Card, Thumbnail } from "@shopify/polaris";

import { MobileMajor, TabletMajor, DesktopMajor } from "@shopify/polaris-icons";

function Settings(props: any) {
    const [device, setDevice] = useState(["desktop"]);

    const mobileLabel = <Thumbnail source={MobileMajor} alt="Mobile" />;
    const tabletLabel = <Thumbnail source={TabletMajor} alt="Tablet" />;
    const desktopLabel = <Thumbnail source={DesktopMajor} alt="Desktop" />;

    return (
        <div>
            <div className="skeleton__preview-header">
                <Card>
                    <OptionList
                        onChange={setDevice}
                        options={[
                            { value: "mobile", label: mobileLabel },
                            { value: "tablet", label: tabletLabel },
                            { value: "desktop", label: desktopLabel }
                        ]}
                        selected={device}
                    />
                </Card>
            </div>
            <WishlistHeader
                type={props.typeOfHeader} //floating,menu_item,icon
                count={21} //leave empty to hide
                device={device}
            />

            <WishlistProduct
                style={props.typeOfStyle} //1,2,3
                color="#ffffff"
                backgroundColor="#235EAA"
                label="Add To Wishlist"
                count={26} //leave empty if disabled
                device={device}
            />
            <WishlistGrid
                position={props.placement} //top_left,bottom_left,top_right,bottom_right
                backgroundColor="#235EAA"
                color="#ffffff"
                device={device}
            />
        </div>
    );
}

export default Settings;
