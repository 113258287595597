import './newsletter.scss';
import {
  SkeletonBodyText,
  SkeletonDisplayText,
  Button,
  TextField,
  SkeletonThumbnail,
} from '@shopify/polaris';
import PlaceholderForSection from '../../../../assets/placeholderImageForSection.svg';

function Newsletter(props:any) {
  const heading = (
    <h2 className="skeleton__preview-heading  newsletter__preview-heading">
      {props.heading ? (
        <span>{props.heading.map((item:any) => item)}</span>
      ) : (
        <SkeletonDisplayText />
      )}
    </h2>
  );

  const subheading = (
    <div className="skeleton__preview-subheading  newsletter__preview-subheading">
      {props.subheading ? (
        <span>{props.subheading.map((item:any) => item)}</span>
      ) : (
        <SkeletonBodyText lines={2} />
      )}
    </div>
  );

  const imageWrapper = props.src ? (
    <img src={props.src} alt="" />
  ) : (
    <img src={PlaceholderForSection} alt="" style={{height:"200px"}} />
  );

  const parentClass =
    'skeleton__preview newsletter__preview layout-' +
    props.layout +
    ' skeleton__preview-' +
    props.device;

  return (
    <div className={parentClass}>
      {props.layout === 'none' ? (
        ''
      ) : (
        <div className="skeleton__preview-image newsletter__preview-image">
          {imageWrapper}
        </div>
      )}
      <div className="skeleton__preview-content newsletter__preview-content">
        {heading}
        {subheading}
        <div className="newsletter__preview-form">
          <TextField label="" placeholder="Enter your email here..." onChange={()=>{}} />
          <Button>{props.button && props.button.length > 0 ? props.button : `Subscribe`}</Button>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
