import React, {
    useState,
    useEffect,
    useCallback,
    ReactElement,
    useContext
} from "react";
import { convertFromRaw, ContentState, EditorState } from "draft-js";
import {
    Avatar,
    Button,
    Card,
    Heading,
    Icon,
    OptionList,
    Popover,
    RangeSlider,
    ResourceList,
    TextField,
    TextStyle,
    Spinner,
    Banner
} from "@shopify/polaris";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { ImagesMajor } from "@shopify/polaris-icons";
import TextEditorMinimal from "../../../components/Common/TextEditorMinimal/TextEditorMinimal";
import eventBus from "../../../common/EventBus";
import { getCollectionList } from "../../../API";
function Index(props: any) {
    const { data, updateData } = props;
    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    );
    const [collectionList, setCollectionList] = useState([]);
    const [collectionLoading, setCollectionLoading] = useState(true);
    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );
    useEffect(() => {
        const contentBlock = htmlToDraft(data.featured_items.heading);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
                contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, []);
    const toHTMLString = stateToHTML(editorState.getCurrentContent());
    useEffect(() => {
        eventBus.on("BAH:discardCurrentForm", (data: any) => {
            const contentBlock = htmlToDraft(props.data.featured_items.heading);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                );
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        });
        return () => {
            eventBus.remove("BAH:discardCurrentForm");
        };
    }, []);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getCollectionList();
                if (result) {
                    setCollectionList(result);
                    setCollectionLoading(false);
                } else {
                    setCollectionList([]);
                    setCollectionLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    useEffect(() => {
        updateData({
            ...data,
            featured_items: {
                ...data?.featured_items,
                heading: toHTMLString
            }
        });
    }, [editorState]);

    const handleResourceListItemClick = (val: any) => {
        const findElem = collectionList.find((elem) => elem[`id`] == val);
        if (findElem) {
            updateData({
                ...data,
                featured_items: {
                    ...data?.featured_items,
                    selected_collection: {
                        id: findElem[`id`],
                        title: findElem[`title`],
                        handle: findElem[`handle`]
                    }
                }
            });
        }

        setPopoverActive(false);
    };

    const staffList = collectionList;

    const activator = (
        <div>
            <p className="textFieldStyle">Select Collection</p>
            <div onClick={togglePopoverActive}>
                <TextField
                    placeholder="search collection or paste link here"
                    label=""
                    value={data.featured_items.selected_collection.title}
                    onClearButtonClick={() => {
                        updateData({
                            ...data,
                            featured_items: {
                                ...data?.featured_items,
                                selected_collection: {
                                    id: -1,
                                    title: "",
                                    handle: ""
                                }
                            }
                        });
                    }}
                    clearButton
                    onChange={(val: any) => {
                        updateData({
                            ...data,
                            featured_items: {
                                ...data?.featured_items,
                                selected_collection: val
                            }
                        });
                    }}
                />
            </div>
        </div>
    );

    function renderItem(data: any) {
        return (
            <ResourceList.Item
                id={data.id}
                media={
                    data && data.image && data.image.src ? (
                        <img
                            src={data.image.src}
                            color="base"
                            alt={data.image.alt}
                            height={25}
                        />
                    ) : (
                        <Icon source={ImagesMajor} color="base" />
                    )
                }
                onClick={handleResourceListItemClick}
            >
                {data.title}
            </ResourceList.Item>
        );
    }
    return (
        <div
            onClick={(e: any) => {
                e.stopPropagation();
            }}
        >
            <div>
                <Popover
                    active={popoverActive}
                    activator={activator}
                    onClose={togglePopoverActive}
                    fullWidth
                >
                    <div>
                        <div className="popoverHead">
                            <div className="btnContain">
                                <div
                                    onClick={togglePopoverActive}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                >
                                    {"<"} Back
                                </div>
                            </div>
                            <div className="resultBox">
                                <TextStyle variation="subdued">
                                    {collectionList.length} results
                                </TextStyle>
                            </div>
                        </div>

                        <div style={{ paddingTop: "15px" }}>
                            <ResourceList
                                items={staffList}
                                renderItem={renderItem}
                            />

                            <div
                                style={{
                                    textAlign: "center",
                                    color: "blue",
                                    cursor: "pointer",
                                    paddingTop: "15px"
                                }}
                            >
                                {collectionLoading ? (
                                    <Spinner size={"large"} />
                                ) : (
                                    // <p>Load more...</p>
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>
                </Popover>
            </div>

            <div style={{ marginTop: "20px" }}>
                <p
                    style={{
                        color: "#202223",
                        fontSize: "14px",
                        fontWeight: "normal",
                        lineHeight: "20px"
                    }}
                >
                    Section Title
                </p>
            </div>
            <div style={{ marginTop: "10px" }}></div>
            {editorState ? (
                <TextEditorMinimal
                    editorState={editorState}
                    onEditorStateChange={(val: any) => {
                        setEditorState(val);
                    }}
                />
            ) : (
                <></>
            )}
        </div>
    );
}

export default Index;
