import React, { useState, useCallback } from "react";
import {
    DisplayText,
    Card,
    Button,
    Stack,
    Collapsible,
    TextStyle,
    FormLayout,
    Layout,
    TextField
} from "@shopify/polaris";
import "../Promo.scss";
import { Icon } from "@shopify/polaris";
import {
    ChevronUpMinor,
    ChevronDownMinor,
    MobilePlusMajor,
    PlusMinor
} from "@shopify/polaris-icons";
import { SwitchOffIcon, SwitchOnIcon } from "../../../common/icons";
import CollapisbleCard from "./PromoCodesComponent";
import Percantage from "./PercentageOff";
import Discount from "./Discount";
import FreeShipping from "./FreeShipping";
import CommonForm from "./CommonForm";
import {
    AmountDiscountTypes,
    FreeShippingTypes,
    PercentageOffTypes,
    PromoFormDataTypes,
    PromoTypes,
    SelectedTypes
} from "..";

interface PropTypesMainForm {
    data: PromoFormDataTypes;
    addNewPromo: any;
    removePromo: any;
    setData: any;
    savePromo: any;
}
function Promocode(props: PropTypesMainForm) {
    const handleSelectedType = (selectedIndex: number, type: SelectedTypes) => {
        let tempPromos = props.data.promos;
        let tempPromo = props.data.promos[selectedIndex];
        tempPromo.selected_type = type;
        tempPromos[selectedIndex] = tempPromo;
        props.setData({
            ...props.data,
            promos: [...tempPromos]
        });
    };
    const handleDataChangeForDiscountForm = (
        selectedIndex: number,
        updatedData: AmountDiscountTypes
    ) => {
        let tempPromos = props.data.promos;
        let tempPromo = props.data.promos[selectedIndex];
        tempPromo.amount_discount = updatedData;
        tempPromos[selectedIndex] = tempPromo;
        props.setData({
            ...props.data,
            promos: [...tempPromos]
        });
    };
    const handleDataChangeForFreeShippingForm = (
        selectedIndex: number,
        updatedData: FreeShippingTypes
    ) => {
        let tempPromos = props.data.promos;
        let tempPromo = props.data.promos[selectedIndex];
        tempPromo.free_shipping = updatedData;
        tempPromos[selectedIndex] = tempPromo;
        props.setData({
            ...props.data,
            promos: [...tempPromos]
        });
    };
    const handleDataChangeForPercentageOffForm = (
        selectedIndex: number,
        updatedData: PercentageOffTypes
    ) => {
        let tempPromos = props.data.promos;
        let tempPromo = props.data.promos[selectedIndex];
        tempPromo.percentage_off = updatedData;
        tempPromos[selectedIndex] = tempPromo;
        props.setData({
            ...props.data,
            promos: [...tempPromos]
        });
    };
    const handleDataChangeForCommonForm = (
        selectedIndex: number,
        updatedData: PromoTypes
    ) => {
        let tempPromos = props.data.promos;
        let tempPromo = props.data.promos[selectedIndex];
        tempPromo = updatedData;
        tempPromos[selectedIndex] = tempPromo;
        props.setData({
            ...props.data,
            promos: [...tempPromos]
        });
    };
    const ShowButtons = (sub_props: any) => {
        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div style={{ marginBottom: "10px" }}>
                    <TextStyle variation="strong">Select Type</TextStyle>
                </div>
                <FormLayout>
                    <FormLayout.Group condensed>
                        <div
                            onClick={() => {
                                handleSelectedType(
                                    sub_props.index,
                                    SelectedTypes.AMOUNT_DISCOUNT
                                );
                            }}
                        >
                            <Button
                                primary
                                onClick={() => {
                                    handleSelectedType(
                                        sub_props.index,
                                        SelectedTypes.AMOUNT_DISCOUNT
                                    );
                                }}
                                disabled={
                                    props.data.promos[sub_props.index]
                                        .selected_type !==
                                    SelectedTypes.AMOUNT_DISCOUNT
                                }
                            >
                                Amount Discount
                            </Button>
                        </div>
                        <div
                            onClick={() => {
                                handleSelectedType(
                                    sub_props.index,
                                    SelectedTypes.PERCENTAGE_OFF
                                );
                            }}
                        >
                            <Button
                                primary
                                onClick={() => {
                                    handleSelectedType(
                                        sub_props.index,
                                        SelectedTypes.PERCENTAGE_OFF
                                    );
                                }}
                                disabled={
                                    props.data.promos[sub_props.index]
                                        .selected_type !==
                                    SelectedTypes.PERCENTAGE_OFF
                                }
                            >
                                Percantage off
                            </Button>
                        </div>
                        <div
                            onClick={() => {
                                handleSelectedType(
                                    sub_props.index,
                                    SelectedTypes.FREE_SHIPPING
                                );
                            }}
                        >
                            <Button
                                primary
                                onClick={() => {
                                    handleSelectedType(
                                        sub_props.index,
                                        SelectedTypes.FREE_SHIPPING
                                    );
                                }}
                                disabled={
                                    props.data.promos[sub_props.index]
                                        .selected_type !==
                                    SelectedTypes.FREE_SHIPPING
                                }
                            >
                                Free Shipping
                            </Button>
                        </div>
                    </FormLayout.Group>
                </FormLayout>
                {/* <Discount />, <Percantage />, <FreeShipping /> */}
                {props.data.promos[sub_props.index].selected_type ===
                    SelectedTypes.FREE_SHIPPING && (
                    <FreeShipping
                        IndexVal={sub_props.index}
                        data={props.data.promos[sub_props.index].free_shipping}
                        onDataChange={handleDataChangeForFreeShippingForm}
                    />
                )}
                {props.data.promos[sub_props.index].selected_type ===
                    SelectedTypes.PERCENTAGE_OFF && (
                    <Percantage
                        IndexVal={sub_props.index}
                        data={props.data.promos[sub_props.index].percentage_off}
                        onDataChange={handleDataChangeForPercentageOffForm}
                    />
                )}
                {props.data.promos[sub_props.index].selected_type ===
                    SelectedTypes.AMOUNT_DISCOUNT && (
                    <Discount
                        IndexVal={sub_props.index}
                        data={
                            props.data.promos[sub_props.index].amount_discount
                        }
                        onDataChange={handleDataChangeForDiscountForm}
                    />
                )}
            </div>
        );
    };
    return (
        <div>
            <Layout>
                <Layout.Section oneHalf>
                    <div className="TopEnableButton">
                        <Stack>
                            <div>
                                <Button
                                    primary
                                    icon={MobilePlusMajor}
                                    onClick={() => {
                                        props.addNewPromo();
                                    }}
                                >
                                    Create Promo Code
                                </Button>
                            </div>
                        </Stack>{" "}
                    </div>
                    <div className="CardSection">
                        {props.data.promos.map((values, index) => {
                            return (
                                <div
                                    key={`collapisble-card-promo-section-${index}`}
                                >
                                    <CollapisbleCard
                                        heading={`${
                                            values.section_title
                                                ? values.section_title
                                                : `Promo Section #${index + 1}`
                                        }`}
                                        type="promo-section"
                                        subHeading="Select promocode & add to layout"
                                        openCard={values.is_expanded}
                                        handleCardOpen={() => {
                                            let tempPromos = props.data.promos;
                                            let tempPromo =
                                                props.data.promos[index];
                                            tempPromo.is_expanded =
                                                !tempPromo.is_expanded;
                                            tempPromos[index] = tempPromo;
                                            props.setData({
                                                ...props.data,
                                                promos: [...tempPromos]
                                            });
                                        }}
                                        isEnabled={true}
                                        changeEnableDisable={() => {}}
                                        CollapisbleComponent={
                                            // <PromoCodeAccountForm
                                            //     data={formData}
                                            //     updateData={updateFormData}
                                            // />
                                            <>
                                                <div className="App">
                                                    <ShowButtons
                                                        index={index}
                                                    />
                                                    <CommonForm
                                                        IndexVal={index}
                                                        data={
                                                            props.data.promos[
                                                                index
                                                            ]
                                                        }
                                                        onDataChange={
                                                            handleDataChangeForCommonForm
                                                        }
                                                        deletePromo={
                                                            props.removePromo
                                                        }
                                                        savePromo={
                                                            props.savePromo
                                                        }
                                                    />
                                                </div>
                                            </>
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                </Layout.Section>
                <Layout.Section oneHalf></Layout.Section>
            </Layout>
        </div>
    );
}

export default Promocode;
