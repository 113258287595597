import {
    Card,
    Thumbnail,
    TextContainer,
    Heading,
    Link
} from "@shopify/polaris";
import {
    BillingStatementDollarMajor,
    GlobeMajor,
    SettingsMinor,
    NotificationMajor
} from "@shopify/polaris-icons";
function List() {
    let settings = [
        {
            heading: "General",
            subHeading: "View and update your store details",
            src: "/settings/general",
            icon: "SettingsMinor"
        },
        {
            heading: "Language",
            subHeading: "This is the language your customers see in app.",
            src: "/settings/lang",
            icon: "GlobeMajor"
        },
        {
            heading: "Billing",
            subHeading: "Manage your billing plan and information",
            src: "/settings/billing",
            icon: "BillingStatementDollarMajor"
        },
        {
            heading: "Notifications",
            subHeading: "Edit notification settings",
            src: "/settings/notification",
            icon: "NotificationMajor"
        }
    ];
    const getIcon = (icon: any) => {
        switch (icon) {
            case "BillingStatementDollarMajor":
                return BillingStatementDollarMajor;
            case "GlobeMajor":
                return GlobeMajor;
            case "NotificationMajor":
                return NotificationMajor;
            case "SettingsMinor":
                return SettingsMinor;
            default:
                return SettingsMinor;
        }
    };
    return (
        <div className="settings-list">
            <Card>
                {settings.map((item, index) => (
                    <Link
                        url={item.src}
                        removeUnderline={true}
                        monochrome={true}
                    >
                        <Thumbnail
                            source={getIcon(item.icon)}
                            alt={`list-setttings-thumbnail-${index}`}
                        />
                        <TextContainer spacing="tight">
                            <Heading>{item.heading}</Heading>
                            <p>{item.subHeading}</p>
                        </TextContainer>
                    </Link>
                ))}
            </Card>
        </div>
    );
}

export default List;
