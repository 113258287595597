import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import {
    Avatar,
    Button,
    Card,
    Heading,
    Icon,
    OptionList,
    Popover,
    RangeSlider,
    ResourceList,
    TextField,
    TextStyle,
    Spinner,
    Banner
} from "@shopify/polaris";
import { ImagesMajor } from "@shopify/polaris-icons";
import * as _ from "lodash";
import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import { convertFromRaw, ContentState, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import TextEditorMinimal from "../../components/Common/TextEditorMinimal/TextEditorMinimal";
import {
    getCollectionList,
    saveGiftCardsPageData,
    getGiftCardsPageData
} from "../../API/index";
import { GlobalContext } from "../../Contexts/GlobalContext";
import eventBus from "../../common/EventBus";
import LoadingAnimation from "../../components/StaticComponents/LoadingAnimation";
import "./index.scss";

const GiftCardsForm = (props: any) => {
    const { data } = props;
    const history = useHistory();
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const [formData, setFormData] = useState({
        subHeading: "",
        collection: { id: -1, title: "", handle: "" },
        empty_state_text: "",
        products_per_row_desktop: 4,
        products_per_row_mobile: 1,
        isEnabled: false,
        subHeadingEnabled: false
    });
    const [collectionList, setCollectionList] = useState([]);
    const [collectionLoading, setCollectionLoading] = useState(true);
    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );
    const [initialData, setInitialData] = useState<any>({
        subHeading: "",
        collection: { id: -1, title: "", handle: "" },
        empty_state_text: "",
        products_per_row_desktop: 4,
        products_per_row_mobile: 1,
        isEnabled: false,
        subHeadingEnabled: false
    });
    const [submitForm, setSubmitForm] = useState(false);
    const [discardForm, setDiscardForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const [editorState, setEditorState] = useState<EditorState>(
        EditorState.createEmpty()
    );
    const handleDiscard = async () => {
        console.log("discard gift card");
        console.log(initialData);
        setDiscardForm(true);
    };
    const saveData = async () => {
        console.log("Calling save data....");
        setSubmitForm(false);
        updateDataSubmitting(true);
        const result = await saveGiftCardsPageData({
            isEnabled: formData.isEnabled,
            isSubHeadingEnabled: formData.subHeadingEnabled,
            subheading_text: formData.subHeading,
            selected_collection: formData.collection,
            empty_state_text: formData.empty_state_text,
            products_per_row_desktop: formData.products_per_row_desktop,
            products_per_row_mobile: formData.products_per_row_mobile
        });
        if (result) {
            updateDataSubmitting(false);
            setInitialData({
                ...formData
            });
            setFormData({
                ...formData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
    };

    const toHTMLString = stateToHTML(editorState.getCurrentContent());
    useEffect(() => {
        setFormData({
            ...formData,
            empty_state_text: toHTMLString
        });
    }, [editorState]);

    useEffect(() => {
        let mounted = true;

        if (mounted && discardForm) {
            setFormData(initialData);
            const contentBlock = htmlToDraft(initialData.empty_state_text);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks
                );
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
            setDiscardForm(false);
        }
        return () => {
            mounted = false;
        };
    }, [discardForm]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getCollectionList();
                if (result) {
                    setCollectionList(result);
                    setCollectionLoading(false);
                } else {
                    setCollectionList([]);
                    setCollectionLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const getPrevResult = await getGiftCardsPageData();
                if (getPrevResult) {
                    setFormData({
                        subHeading: getPrevResult.subheading_text,
                        collection: getPrevResult.selected_collection,
                        empty_state_text: getPrevResult.empty_state_text,
                        products_per_row_desktop:
                            getPrevResult.products_per_row_desktop,
                        products_per_row_mobile:
                            getPrevResult.products_per_row_mobile,
                        isEnabled: getPrevResult.isEnabled,
                        subHeadingEnabled: getPrevResult.isSubHeadingEnabled
                    });
                    setInitialData({
                        subHeading: getPrevResult.subheading_text,
                        collection: getPrevResult.selected_collection,
                        empty_state_text: getPrevResult.empty_state_text,
                        products_per_row_desktop:
                            getPrevResult.products_per_row_desktop,
                        products_per_row_mobile:
                            getPrevResult.products_per_row_mobile,
                        isEnabled: getPrevResult.isEnabled,
                        subHeadingEnabled: getPrevResult.isSubHeadingEnabled
                    });
                    const contentBlock = htmlToDraft(
                        getPrevResult.empty_state_text
                    );
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(
                            contentBlock.contentBlocks
                        );
                        const editorState =
                            EditorState.createWithContent(contentState);
                        setEditorState(editorState);
                    }
                    setInitialLoading(false);
                } else {
                    setInitialLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    useEffect(() => {
        console.log("mounted event");
        eventBus.on("BAH:discardGiftCardsForm", (data: any) => {
            handleDiscard();
        });
        return () => {
            console.log(
                "unmounted event listener: discard current form in gift cards"
            );
            eventBus.remove("BAH:discardGiftCardsForm");
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:saveGiftCardsForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            console.log("unmounted event");
            eventBus.remove("BAH:saveGiftCardsForm");
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted && submitForm) {
            console.log("save data");
            saveData();
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (!initialLoading) {
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [formData, initialData]);

    const handleResourceListItemClick = (val: any) => {
        const findElem = collectionList.find((elem) => elem[`id`] == val);
        if (findElem) {
            setFormData({
                ...formData,
                collection: {
                    id: findElem[`id`],
                    title: findElem[`title`],
                    handle: findElem[`handle`]
                }
            });
        }

        setPopoverActive(false);
    };

    const staffList = collectionList;

    const activator = (
        <div className="componentBox">
            <p className="textFieldStyle">Select Collection</p>
            <div onClick={togglePopoverActive}>
                <TextField
                    placeholder="search collection or paste link here"
                    label=""
                    value={formData.collection.title}
                    onClearButtonClick={() => {
                        setFormData({
                            ...formData,
                            collection: {
                                id: -1,
                                title: "",
                                handle: ""
                            }
                        });
                    }}
                    clearButton
                    onChange={(val: any) => {
                        setFormData({
                            ...formData,
                            collection: val
                        });
                    }}
                />
            </div>
        </div>
    );

    function renderItem(data: any) {
        return (
            <ResourceList.Item
                id={data.id}
                media={
                    data && data.image && data.image.src ? (
                        <img
                            src={data.image.src}
                            color="base"
                            alt={data.image.alt}
                            height={25}
                        />
                    ) : (
                        <Icon source={ImagesMajor} color="base" />
                    )
                }
                onClick={handleResourceListItemClick}
            >
                {data.title}
            </ResourceList.Item>
        );
    }

    return (
        <PageWrapper
            title="Gift Cards"
            subtitle="You’re able to add subheading, choose collection and how many products will be displayed per row."
            separator={true}
            showSwitchButton={true}
            loading={initialLoading}
            isDisabled={!didValueChange}
            SwitchVal={formData.isEnabled}
            onChangeSwitchVal={() => {
                setFormData({
                    ...formData,
                    isEnabled: !formData.isEnabled
                });
            }}
            id="gift-cards-page"
        >
            {initialLoading ? (
                <>
                    <LoadingAnimation
                        accessibilityLabel="loading gift cards page data"
                        size="large"
                    />
                </>
            ) : (
                <>
                    {showSuccessMessage && (
                        <Banner
                            title="Saved your gift cards data"
                            status="success"
                            action={{
                                content: "Go To Dashboard",
                                onAction: () => {
                                    history.push("/");
                                }
                            }}
                            onDismiss={() => {
                                setShowSuccessMessage(false);
                            }}
                        />
                    )}
                    {showErrorMessage && (
                        <Banner
                            title="Something went wrong while saving."
                            status="critical"
                            action={{
                                content: "Go To Dashboard",
                                onAction: () => {
                                    history.push("/");
                                }
                            }}
                            onDismiss={() => {
                                setShowErrorMessage(false);
                            }}
                        />
                    )}

                    <div>
                        <div className="cardContain">
                            <Card>
                                <div className="title">
                                    <div className="switchGiftcards">
                                        {!formData.subHeadingEnabled ? (
                                            <div
                                                onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        subHeadingEnabled:
                                                            !formData.subHeadingEnabled
                                                    });
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <svg
                                                    width="30"
                                                    height="14"
                                                    viewBox="0 0 30 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        y="3"
                                                        width="30"
                                                        height="8"
                                                        rx="4"
                                                        fill="#D9DBDD"
                                                    />
                                                    <circle
                                                        cx="7"
                                                        cy="7"
                                                        r="7"
                                                        fill="#5C5F62"
                                                    />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setFormData({
                                                        ...formData,
                                                        subHeadingEnabled:
                                                            !formData.subHeadingEnabled
                                                    });
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <svg
                                                    width="30"
                                                    height="14"
                                                    viewBox="0 0 30 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <rect
                                                        y="3"
                                                        width="30"
                                                        height="8"
                                                        rx="4"
                                                        fill="#D9DBDD"
                                                    />
                                                    <circle
                                                        cx="23"
                                                        cy="7"
                                                        r="7"
                                                        fill="#2C6ECB"
                                                    />
                                                </svg>
                                            </div>
                                        )}
                                    </div>

                                    <div className="switch">
                                        <p className="textFieldStyle">
                                            Subheading
                                        </p>
                                    </div>
                                </div>

                                {/* <img src={SwitchOff} alt="off" /> */}

                                <div className="componentBox">
                                    <p className="textFieldStyle">Subheading</p>
                                    <TextField
                                        placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet leo nec nulla sodales rhoncus."
                                        multiline={1}
                                        label=""
                                        value={formData.subHeading}
                                        onChange={(val: any) => {
                                            setFormData({
                                                ...formData,
                                                subHeading: val
                                            });
                                        }}
                                        helpText={
                                            <span>
                                                Use {"<b>"} Your Text {"</b>"}{" "}
                                                text to bold your text click?
                                                right in top right to learn
                                                more.
                                            </span>
                                        }
                                    />
                                </div>
                                <div>
                                    <Popover
                                        active={popoverActive}
                                        activator={activator}
                                        onClose={togglePopoverActive}
                                        fullWidth
                                    >
                                        <div>
                                            <div className="popoverHead">
                                                <div className="btnContain">
                                                    <div
                                                        onClick={
                                                            togglePopoverActive
                                                        }
                                                        style={{
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                        {"<"} Back
                                                    </div>
                                                </div>
                                                <div className="resultBox">
                                                    <TextStyle variation="subdued">
                                                        {collectionList.length}{" "}
                                                        results
                                                    </TextStyle>
                                                </div>
                                            </div>

                                            <div style={{ paddingTop: "15px" }}>
                                                <ResourceList
                                                    items={staffList}
                                                    renderItem={renderItem}
                                                />

                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        color: "blue",
                                                        cursor: "pointer",
                                                        paddingTop: "15px"
                                                    }}
                                                >
                                                    {collectionLoading ? (
                                                        <Spinner
                                                            size={"large"}
                                                        />
                                                    ) : (
                                                        // <p>Load more...</p>
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>

                                <div className="componentBox">
                                    <p className="textFieldStyle">
                                        Empty State Text
                                    </p>
                                    <div>
                                        {editorState ? (
                                            <TextEditorMinimal
                                                editorState={editorState}
                                                onEditorStateChange={(
                                                    val: any
                                                ) => {
                                                    setEditorState(val);
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>

                                    <div>
                                        <TextStyle variation="subdued">
                                            This text will appear if your gift
                                            card collection will be empty.{" "}
                                        </TextStyle>
                                    </div>
                                </div>

                                <div className="progessBar">
                                    <p className="textFieldStyle">
                                        Products per row on desktop
                                    </p>
                                    <div className="componentBoxSlider">
                                        <RangeSlider
                                            label=""
                                            min={1}
                                            max={8}
                                            prefix={<p>1</p>}
                                            suffix={
                                                <p>
                                                    {
                                                        formData.products_per_row_desktop
                                                    }
                                                </p>
                                            }
                                            value={
                                                formData.products_per_row_desktop
                                            }
                                            onChange={(val: number) => {
                                                setFormData({
                                                    ...formData,
                                                    products_per_row_desktop:
                                                        val
                                                });
                                            }}
                                            output
                                        />
                                    </div>
                                </div>

                                <div className="progessBar">
                                    <p className="textFieldStyle">
                                        Products per row on mobile
                                    </p>
                                    <div className="componentBoxSlider">
                                        <RangeSlider
                                            label=""
                                            min={1}
                                            max={8}
                                            prefix={<p>1</p>}
                                            suffix={
                                                <p>
                                                    {
                                                        formData.products_per_row_mobile
                                                    }
                                                </p>
                                            }
                                            value={
                                                formData.products_per_row_mobile
                                            }
                                            onChange={(val: number) => {
                                                setFormData({
                                                    ...formData,
                                                    products_per_row_mobile: val
                                                });
                                            }}
                                            output
                                        />
                                    </div>
                                </div>

                                <div className="buttonBox">
                                    <div>
                                        <Button
                                            size="medium"
                                            primary
                                            loading={dataSubmitting}
                                            onClick={() => {
                                                setSubmitForm(true);
                                            }}
                                        >
                                            Save
                                        </Button>{" "}
                                    </div>
                                    <div style={{ marginLeft: "10px" }}>
                                        <Button
                                            size="medium"
                                            disabled={dataSubmitting}
                                            onClick={() => {
                                                setFormData(initialData);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </>
            )}
        </PageWrapper>
    );
};

export default GiftCardsForm;
