import React, { useState, useEffect } from "react";
import { Card, Spinner } from "@shopify/polaris";
import { QuestionMarkMinor } from "@shopify/polaris-icons";
import { Line } from "react-chartjs-2";
import { ChartConfiguration, ChartOptions } from "chart.js";
const month: any[] = [];

month[0] = "January";
month[1] = "February";
month[2] = "March";
month[3] = "April";
month[4] = "May";
month[5] = "June";
month[6] = "July";
month[7] = "August";
month[8] = "September";
month[9] = "October";
month[10] = "November";
month[11] = "December";
function CardWithGraph(props: any) {
    const [lineData, setLineData] = useState({
        labels: [
            `${month[new Date().getMonth()]}`,
            `${
                month[
                    new Date(
                        new Date().setMonth(new Date().getMonth() + 1)
                    ).getMonth()
                ]
            }`,
            `${
                month[
                    new Date(
                        new Date().setMonth(new Date().getMonth() + 2)
                    ).getMonth()
                ]
            }`
        ],
        datasets: [
            {
                label: "# of Votes",
                data: [12, 19, 3, 5, 2, 3],
                fill: false,
                backgroundColor: "#1B8060",
                borderColor: "#1B8060"
            }
        ]
    });
    const [options, setOptions] = useState<ChartOptions>({
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false
                },
                ticks: {
                    display: false
                }
            }
        }
    });
    if (props.loading) {
        return (
            <>
                <Card>
                    <div style={{ padding: "20px" }}>
                        <div style={{ textAlign: "center" }}>
                            <Spinner />
                        </div>
                    </div>
                </Card>
            </>
        );
    }
    return (
        <>
            <Card>
                <div style={{ padding: "20px" }}>
                    <div style={{ display: "flex" }}>
                        <p
                            style={{
                                color: "#6D7175",
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "20px"
                            }}
                        >
                            {props.title}
                        </p>
                        <div
                            style={{
                                marginLeft: "8px",
                                marginTop: "2px",
                                fill: "#6D7175"
                            }}
                        >
                            {" "}
                            <QuestionMarkMinor
                                height={14}
                                color="gray"
                                style={{ color: "$6D7175" }}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: "10px" }}></div>
                    <Line data={lineData} options={options} />
                    <div style={{ padding: "5px" }}>
                        <p
                            style={{
                                fontSize: "28px",
                                fontWeight: 600,
                                lineHeight: "32px"
                            }}
                        >
                            {props.data.total}
                        </p>
                        <p>
                            <span
                                style={{
                                    color:
                                        props.data.type === "positive"
                                            ? "#95BF47"
                                            : "#D82C0D"
                                }}
                            >
                                +{props.data.sinceLast}
                            </span>{" "}
                            today
                        </p>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default CardWithGraph;
