import { Select, Card } from "@shopify/polaris";
import { useState, useCallback } from "react";
function Form() {
    const [language, setLanguage] = useState("english");

    const handleSelectChange = useCallback((value) => setLanguage(value), []);

    const options = [{ label: "English", value: "english" }];

    return (
        <div className="langauge-form">
            <Card>
                <Select
                    label="Language"
                    options={options}
                    onChange={handleSelectChange}
                    value={language}
                />
            </Card>
        </div>
    );
}

export default Form;
