export const SwitchOffIcon = () => {
    return (
        <div style={{ cursor: "pointer" }}>
            <svg
                width="30"
                height="14"
                viewBox="0 0 30 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect y="3" width="30" height="8" rx="4" fill="#D9DBDD" />
                <circle cx="7" cy="7" r="7" fill="#5C5F62" />
            </svg>
        </div>
    );
};

export const SwitchOnIcon = () => {
    return (
        <div style={{ cursor: "pointer" }}>
            <svg
                width="30"
                height="14"
                viewBox="0 0 30 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect y="3" width="30" height="8" rx="4" fill="#D9DBDD" />
                <circle cx="23" cy="7" r="7" fill="#2C6ECB" />
            </svg>
        </div>
    );
};
