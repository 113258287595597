import React, { useState, useCallback } from "react";
import {
    Button,
    Stack,
    TextStyle,
    FormLayout,
    TextField,
    RadioButton,
    Checkbox
} from "@shopify/polaris";
import "../Promo.scss";
import { AppliesToTypes, MinRequirementsTypes, PercentageOffTypes } from "..";

interface PropsType {
    IndexVal: number;
    data: PercentageOffTypes;
    onDataChange: (index: number, val: PercentageOffTypes) => void;
}
function Percantage(props: PropsType) {
    const { data, onDataChange, IndexVal } = props;
    const handleUpdatedData = (fieldName: string, val: any) => {
        onDataChange(IndexVal, {
            ...props.data,
            [fieldName]: val
        });
    };
    return (
        <>
            <div className="redemptionStyle">
                <TextStyle variation="strong">Reward value</TextStyle>
            </div>
            <FormLayout>
                <FormLayout.Group condensed>
                    <TextField
                        type="number"
                        label="Point Cost"
                        min={0}
                        value={`${data.reward_points_cost}`}
                        onChange={(val: any) => {
                            handleUpdatedData(`reward_points_cost`, val);
                        }}
                    />
                    <TextField
                        type="number"
                        label="Discount value"
                        min={0}
                        max={100}
                        value={`${data.reward_discount_value}`}
                        onChange={(val: any) => {
                            handleUpdatedData(`reward_discount_value`, val);
                        }}
                    />
                </FormLayout.Group>
            </FormLayout>

            <div className="subtitleTextStyle">
                <TextStyle variation="strong">Applies to</TextStyle>
            </div>
            <FormLayout>
                <Stack vertical>
                    <div
                        onClick={() => {
                            handleUpdatedData(
                                `applies_to`,
                                AppliesToTypes.ENTIRE_ORDER
                            );
                        }}
                    >
                        <RadioButton
                            label="Entire order"
                            checked={
                                data.applies_to === AppliesToTypes.ENTIRE_ORDER
                            }
                            id="disabled"
                            name="accounts"
                        />
                    </div>
                    <div
                        onClick={() => {
                            handleUpdatedData(
                                `applies_to`,
                                AppliesToTypes.SPECIFIC_COLLECTION
                            );
                        }}
                    >
                        <RadioButton
                            label="Specific Collection"
                            id="optional"
                            name="accounts"
                            checked={
                                data.applies_to ===
                                AppliesToTypes.SPECIFIC_COLLECTION
                            }
                        />
                    </div>
                </Stack>
            </FormLayout>
            <div className="subtitleTextStyle">
                <TextStyle variation="strong">Minimum Requirement</TextStyle>
            </div>
            <FormLayout>
                <Stack vertical>
                    <div
                        onClick={() => {
                            handleUpdatedData(
                                `min_requirements`,
                                MinRequirementsTypes.NONE
                            );
                        }}
                    >
                        <RadioButton
                            label="none"
                            checked={
                                data.min_requirements ===
                                MinRequirementsTypes.NONE
                            }
                            id="disabled1"
                            name="accounts1"
                        />
                    </div>
                    <div
                        onClick={() => {
                            handleUpdatedData(
                                `min_requirements`,
                                MinRequirementsTypes.MIN_PURCHASE_POINTS
                            );
                        }}
                    >
                        {" "}
                        <RadioButton
                            label="minimum purchase amount"
                            id="optional1"
                            name="accounts1"
                            checked={
                                data.min_requirements ===
                                MinRequirementsTypes.MIN_PURCHASE_POINTS
                            }
                        />
                    </div>
                </Stack>
            </FormLayout>
        </>
    );
}

export default Percantage;
