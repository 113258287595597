import { SkeletonThumbnail,  Card, SkeletonBodyText, TextContainer, SkeletonDisplayText } from "@shopify/polaris";
import React from "react";


function Preview(props: any) {
    const colors = props.colors
    function hslToHex(color: any) {
        let h = color.hue
        let s = color.saturation * 100
        let l = color.brightness * 100
        s /= 100;
        l /= 100;
        let C = (1 - Math.abs(2 * l - 1)) * s;
        let hue = h / 60;
        let X = C * (1 - Math.abs(hue % 2 - 1));
        let r = 0;
        let g = 0;
        let b = 0;

        if (hue >= 0 && hue < 1) {
            r = C;
            g = X;
        } else if (hue >= 1 && hue < 2) {
            r = X;
            g = C;
        } else if (hue >= 2 && hue < 3) {
            g = C;
            b = X;
        } else if (hue >= 3 && hue < 4) {
            g = X;
            b = C;
        } else if (hue >= 4 && hue < 5) {
            r = X;
            b = C;
        } else {
            r = C;
            b = X;
        }
        let m = l - C / 2;
        r += m;
        g += m;
        b += m;
        r *= 255.0;
        g *= 255.0;
        b *= 255.0;
        return `rgb(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)})`;

    }
   
    return (
        <div className="settings-skeleton" style={{ '--p-surface': hslToHex(colors[0].value) } as React.CSSProperties} >
            <Card sectioned >
                <div className="settings-skeleton__header">
                    <div className="skeleton-header__logo"  >
                        <SkeletonDisplayText size="large" />
                    </div>
                    <div className="skeleton-header__nav"
                    style={{ '--p-surface-neutral': hslToHex(colors[5].value) } as React.CSSProperties}
                    >
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                        <SkeletonBodyText lines={1} />
                    </div>
                    <div className="skeleton-header__icons"
                    style={{ '--p-surface-neutral': hslToHex(colors[6].value) }as React.CSSProperties}>
                        <SkeletonThumbnail size="small" />
                        <SkeletonThumbnail size="small" />
                    </div>
                </div>
                <div className="settings-skeleton__sidebar"
                style={{ '--p-surface-neutral': hslToHex(colors[5].value) } as React.CSSProperties}>
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                    <SkeletonBodyText lines={1} />
                </div>
                <div className="settings-skeleton__main">
                    <div color='Primary' className="settings-skeleton__main-section section-hero" style={{ '--p-surface': hslToHex(colors[1].value) } as React.CSSProperties}>
                        <SkeletonThumbnail size="small" />
                    </div>
                    <div className="settings-skeleton__main-section section-featured__collection" style={{ backgroundColor: hslToHex(colors[1].value) }}>
                        <div className="section-featured__collection-left">
                            <div className="settings-skeleton__heading"
                                style={{ '--p-surface-neutral': hslToHex(colors[2].value) } as React.CSSProperties}>
                                <SkeletonDisplayText size="small" />
                            </div>
                            <div className="settings-skeleton__text"
                                style={{ '--p-surface-neutral': hslToHex(colors[4].value) } as React.CSSProperties}>
                                <SkeletonBodyText lines={3} />
                            </div>
                            <div className="settings-skeleton__button"
                                style={{ '--p-surface-neutral': hslToHex(colors[6].value) } as React.CSSProperties}>
                                <SkeletonDisplayText size="medium" />
                            </div>
                        </div>
                        <div className="section-featured__collection-right">
                            <SkeletonThumbnail size="small" />
                        </div>
                    </div>
                    <div className="settings-skeleton__main-section section-newsletter" style={{ backgroundColor: hslToHex(colors[1].value) }}>
                        <div className="section-newsletter-left">
                            <SkeletonThumbnail size="small" />
                        </div>
                        <div className="section-newsletter-right">
                            <div className="settings-skeleton__heading"
                                style={{ '--p-surface-neutral': hslToHex(colors[2].value) } as React.CSSProperties}>
                                <SkeletonDisplayText size="small" />
                            </div>
                            <div className="settings-skeleton__text"
                                style={{ '--p-surface-neutral': hslToHex(colors[4].value) } as React.CSSProperties}>
                                <SkeletonBodyText lines={3} />
                            </div>
                            <div className="settings-skeleton__button"
                                style={{ '--p-surface-neutral': hslToHex(colors[6].value) } as React.CSSProperties}>
                                <SkeletonDisplayText size="medium" />
                            </div>
                        </div>
                    </div>
                </div>
            </Card >
        </div >
    );
}

export default Preview;
