import { useContext, useState, useEffect } from "react";
import {
    Button,
    Card,
    Layout,
    SkeletonBodyText,
    SkeletonPage,
    TextContainer,
    Stack,
    SkeletonDisplayText,
    SkeletonThumbnail
} from "@shopify/polaris";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import SwitchOnOffButtonCustom from "./Switch";
import "./PageWrapper.scss";

interface PropTypes {
    id: string;
    isDisabled?: boolean;
    handleSave?: any;
    handleCancel?: any;
    separator?: boolean;
    showSwitchButton?: boolean;
    title: string;
    subtitle: string;
    loading?: boolean;
    SwitchVal?: any;
    onChangeSwitchVal?: any;
    children?: any;
    showPrimaryButtons?: any;
}
function PageWrapper(props: PropTypes) {
    const {
        updateDataSubmitting,
        updateValueChange,
        dataSubmitting,
        didValueChange
    } = useContext(GlobalContext);
    let primaryButton = (
        <div>
            <Button
                primary
                size="large"
                disabled={props.isDisabled}
                onClick={props.handleSave}
                loading={dataSubmitting}
            >
                Save
            </Button>
        </div>
    );
    let secondaryButton = (
        <div className="page-footer-button__element">
            <Button
                size="large"
                destructive={true}
                disabled={props.isDisabled || dataSubmitting}
                onClick={props.handleCancel}
            >
                Cancel
            </Button>
        </div>
    );
    let separator = null;
    if (props.separator === true) {
        separator = <hr className="page-separator" />;
    }
    let switchCustomComponent = null;
    if (props.showSwitchButton === true) {
        switchCustomComponent = (
            <SwitchOnOffButtonCustom
                val={props.SwitchVal}
                onChange={props.onChangeSwitchVal}
            />
        );
    }

    return (
        <>
            {props.loading ? (
                <div className="page-wrapper">
                    <div className="page-header">
                        <div className="page-header__left">
                            <h1 className="page-header__title">
                                {props.title}
                            </h1>
                            <p className="page-header__subtitle">
                                {props.subtitle}
                            </p>
                        </div>
                    </div>
                    {separator}
                    <Layout>
                        <Layout.Section oneHalf>
                            <Card sectioned>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card>
                            <Card sectioned>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card>
                            <Card sectioned>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card>
                            <Card sectioned>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card>
                            <Card sectioned>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card>
                        </Layout.Section>
                        <Layout.Section oneHalf>
                            <Card>
                                <Card.Section>
                                    <TextContainer>
                                        <SkeletonBodyText lines={1} />
                                    </TextContainer>
                                </Card.Section>
                            </Card>
                            <Card subdued>
                                <Card.Section>
                                    <TextContainer>
                                        <SkeletonDisplayText size="small" />
                                        <SkeletonBodyText lines={10} />
                                    </TextContainer>
                                </Card.Section>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </div>
            ) : (
                <div className="page-wrapper">
                    <div className="page-header">
                        <div className="page-header__left">
                            <h1 className="page-header__title">
                                {props.title}
                            </h1>
                            <p className="page-header__subtitle">
                                {props.subtitle}
                            </p>
                        </div>
                    </div>

                    {switchCustomComponent}
                    {separator}
                    <div className="page-content" id={props.id}>
                        {props.children}
                    </div>
                    <div className="page-footer-button">
                        {props.showPrimaryButtons ? (
                            <>
                                {primaryButton}
                                {secondaryButton}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default PageWrapper;
