import { DisplayText, Button, Collapsible, Icon } from "@shopify/polaris";
import React, { useState, useCallback } from "react";
import faqIcon from "../../assets/FAQIcon.svg";
import { ChevronDownMinor } from "@shopify/polaris-icons";

const CommonQue = () => {
    const [open, setOpen] = useState(true);

    const handleToggle = useCallback(() => setOpen((open) => !open), []);

    const [open1, setOpen1] = useState(false);

    const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);

    const [open2, setOpen2] = useState(false);

    const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);

    const [open3, setOpen3] = useState(false);

    const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);

    return (
        <div
            style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                paddingTop: "25px",
                paddingBottom: "75px",
                position: "absolute"
            }}
        >
            <div>
                <div style={{ paddingTop: 50 }}>
                    <DisplayText size="large">
                        Most Common Questions & Answers{" "}
                    </DisplayText>
                </div>
                <div
                    style={{
                        paddingTop: 40,
                        display: "flex",
                        flexDirection: "row",
                        textTransform: "none"
                    }}
                >
                    <Button
                        plain
                        monochrome
                        onClick={handleToggle}
                        ariaExpanded={open}
                        ariaControls="basic-collapsible"
                    >
                        How to get started?
                    </Button>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Collapsible
                        open={open}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out"
                        }}
                        expandOnPrint
                    >
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                    </Collapsible>
                </div>
                <div
                    style={{ textDecorationLine: "underline", paddingTop: 30 }}
                >
                    <Button
                        plain
                        monochrome
                        onClick={handleToggle1}
                        ariaExpanded={open1}
                        ariaControls="basic-collapsible"
                    >
                        What is "App Settings" in Setting menu ?
                    </Button>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 10
                    }}
                >
                    <Collapsible
                        open={open1}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out"
                        }}
                        expandOnPrint
                    >
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                    </Collapsible>
                </div>

                <div
                    style={{ textDecorationLine: "underline", paddingTop: 20 }}
                >
                    <Button
                        plain
                        monochrome
                        onClick={handleToggle2}
                        ariaExpanded={open2}
                        ariaControls="basic-collapsible"
                    >
                        How can I use "Multi-language" option ?
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 10
                    }}
                >
                    <Collapsible
                        open={open2}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out"
                        }}
                        expandOnPrint
                    >
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                    </Collapsible>
                </div>

                <div
                    style={{ textDecorationLine: "underline", paddingTop: 20 }}
                >
                    <Button
                        plain
                        monochrome
                        onClick={handleToggle3}
                        ariaExpanded={open3}
                        ariaControls="basic-collapsible"
                    >
                        How to setup credit Earning Rules?
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: 10
                    }}
                >
                    <Collapsible
                        open={open3}
                        id="basic-collapsible"
                        transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out"
                        }}
                        expandOnPrint
                    >
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                        <DisplayText size="small">
                            Lorem ip some text
                        </DisplayText>
                    </Collapsible>
                </div>
                <div style={{ paddingTop: 30 }}>
                    <Button> Need help?</Button>
                </div>
            </div>
            <div style={{ marginTop: "50px" }}>
                <img src={faqIcon} alt="faq icon" />
            </div>
        </div>
    );
};

export default CommonQue;
