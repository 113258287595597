import React from "react";
import Lottie from "react-lottie";
import animationData from "./loadingDots.json";

function LoadingAnimation(props: any) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData
    };
    return (
        <>
            <Lottie
                options={defaultOptions}
                height={150}
                isStopped={false}
                isPaused={false}
            />
        </>
    );
}

export default LoadingAnimation;
