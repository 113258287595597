
import {  Cookies } from 'react-cookie';
import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_API_URL;
export const getJwtToken = async () => {
  const Cookie = new Cookies();
  return Cookie.get('beam-cookie');
}
// 

export const getAllPromoCodes = async () => {
  try {
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/full-promo-codes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getCollectionList = async () => {
  try {
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/collection/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
