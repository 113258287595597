import React, { useState, useCallback, FunctionComponent } from "react";
import {
    Button,
    Stack,
    TextStyle,
    FormLayout,
    TextField,
    RadioButton,
    Checkbox
} from "@shopify/polaris";
import "../Promo.scss";
import {
    AmountDiscountTypes,
    AppliesToTypes,
    MinRequirementsTypes,
    RedemptionStyleTypes
} from "..";

interface PropsType {
    IndexVal: number;
    data: AmountDiscountTypes;
    onDataChange: (index: number, val: AmountDiscountTypes) => void;
}
const Discount: FunctionComponent<any> = (props: PropsType) => {
    const { data, onDataChange, IndexVal } = props;

    const handleUpdatedData = (fieldName: string, val: any) => {
        onDataChange(IndexVal, {
            ...props.data,
            [fieldName]: val
        });
    };
    return (
        <>
            <div className="redemptionStyle">
                <TextStyle variation="strong">
                    Points redemption style
                </TextStyle>
            </div>
            <FormLayout>
                <Stack vertical>
                    <div
                        onClick={(val: any) => {
                            handleUpdatedData(
                                `redemption_style`,
                                RedemptionStyleTypes.FIXED_AMOUNT
                            );
                        }}
                    >
                        <RadioButton
                            label="Fixed amount of points"
                            checked={
                                data.redemption_style ===
                                RedemptionStyleTypes.FIXED_AMOUNT
                            }
                            id="disabled2"
                            name="PointsRedemption"
                        />
                    </div>
                    <div
                        onClick={(val: any) => {
                            handleUpdatedData(
                                `redemption_style`,
                                RedemptionStyleTypes.INC_POINTS
                            );
                        }}
                    >
                        <RadioButton
                            label="Increments of points"
                            id="optional"
                            name="PointsRedemption"
                            checked={
                                data.redemption_style ===
                                RedemptionStyleTypes.INC_POINTS
                            }
                        />
                    </div>
                </Stack>
            </FormLayout>

            <div className="Rewardvalue">
                <TextStyle variation="strong">Reward value</TextStyle>
            </div>
            <FormLayout>
                <FormLayout.Group condensed>
                    <TextField
                        type="number"
                        label="Points cost"
                        id="pointCost"
                        min={0}
                        value={`${data.reward_points_cost}`}
                        suffix={"points"}
                        onChange={(val: any) => {
                            handleUpdatedData(`reward_points_cost`, val);
                        }}
                    />
                    <TextField
                        type="number"
                        label="Discount value"
                        name="DiscountValue"
                        min={0}
                        max={100}
                        value={`${data.reward_discount_value}`}
                        suffix={"%off"}
                        onChange={(val: any) => {
                            handleUpdatedData(`reward_discount_value`, val);
                        }}
                    />
                </FormLayout.Group>
            </FormLayout>

            <div className="subtitleTextStyle">
                <TextStyle variation="strong">Applies to</TextStyle>
            </div>
            <FormLayout>
                <Stack vertical>
                    <div
                        onClick={(val: any) => {
                            handleUpdatedData(
                                `applies_to`,
                                AppliesToTypes.ENTIRE_ORDER
                            );
                        }}
                    >
                        <RadioButton
                            label="Entire order"
                            checked={
                                data.applies_to === AppliesToTypes.ENTIRE_ORDER
                            }
                            id="disabled"
                            name="Applies"
                        />
                    </div>
                    <div
                        onClick={(val: any) => {
                            handleUpdatedData(
                                `applies_to`,
                                AppliesToTypes.SPECIFIC_COLLECTION
                            );
                        }}
                    >
                        <RadioButton
                            label="Specific collections"
                            id="optional"
                            name="Applies"
                            checked={
                                data.applies_to ===
                                AppliesToTypes.SPECIFIC_COLLECTION
                            }
                        />
                    </div>
                </Stack>
            </FormLayout>
            <div className="subtitleTextStyle">
                <TextStyle variation="strong">Minimum Requirement</TextStyle>
            </div>
            <FormLayout>
                <Stack vertical>
                    <div
                        onClick={(val: any) => {
                            handleUpdatedData(
                                `min_requirements`,
                                MinRequirementsTypes.NONE
                            );
                        }}
                    >
                        <RadioButton
                            label="None"
                            checked={
                                data.min_requirements ===
                                MinRequirementsTypes.NONE
                            }
                            id="disabled1"
                            name="accounts1"
                        />
                    </div>
                    <div
                        onClick={(val: any) => {
                            handleUpdatedData(
                                `min_requirements`,
                                MinRequirementsTypes.MIN_PURCHASE_POINTS
                            );
                        }}
                    >
                        <RadioButton
                            label="minimum purchase amount"
                            id="optional1"
                            name="accounts1"
                            checked={
                                data.min_requirements ===
                                MinRequirementsTypes.MIN_PURCHASE_POINTS
                            }
                        />
                    </div>
                </Stack>
            </FormLayout>
        </>
    );
};

export default Discount;
