import React, {
    useCallback,
    useRef,
    useState,
    useContext,
    useEffect,
    ContextType
} from "react";
import {
    AppProvider,
    Avatar,
    ActionList,
    Card,
    TextField,
    TextContainer,
    ContextualSaveBar,
    FormLayout,
    Modal,
    Frame,
    Layout,
    Loading,
    Navigation,
    Page,
    SkeletonBodyText,
    SkeletonDisplayText,
    SkeletonPage,
    Toast,
    Icon,
    Button,
    VisuallyHidden,
    TopBar
} from "@shopify/polaris";
import { useLocation, useHistory } from "react-router-dom";
import {
    PageMajor,
    SettingsMajor,
    AnalyticsMajor,
    QuestionMarkMajor,
    HomeMajor,
    IllustrationMajor
} from "@shopify/polaris-icons";
import eventBus from "../../common/EventBus";
import { UserContext } from "../../Contexts/AppUserContext";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { UserDetails } from "../../types-interfaces";

export default function FrameExample(props: any) {
    const { user, updateContextUser, isPaidUser } = useContext(UserContext);
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const location = useLocation();
    const history = useHistory();
    const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);
    const [openConfirmationDiscard, setOpenConfirmationDiscard] =
        useState(false);
    const [openPageSubMenu, setOpenPageSubMenu] = useState(false);
    const [userMenuActive, setUserMenuActive] = useState(false);
    const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
    const [userDetails, setUserDetails] = useState<UserDetails>();

    useEffect(() => {
        if (user) {
            setUserDetails(user);
        }
        return () => {};
    }, []);

    /**
     * For Subscription Start
     */
    // useEffect(() => {
    //     if (!isPaidUser) {
    //         history.push("/settings/billing");
    //     }
    //     return () => {};
    // }, [isPaidUser, location.pathname]);
    /**
     * For Subscription End
     */
    const toggleUserMenuActive = useCallback(
        () => setUserMenuActive((userMenuActive) => !userMenuActive),
        []
    );
    const toggleMobileNavigationActive = useCallback(
        () =>
            setMobileNavigationActive(
                (mobileNavigationActive) => !mobileNavigationActive
            ),
        []
    );

    const eventDispatcher = (type: string) => {
        let currentLocationPathname = location.pathname;

        if (currentLocationPathname.startsWith("/pages/account")) {
            if (type === "save") {
                eventBus.dispatch("BAH:saveCurrentForm", {
                    message: "discard button clicked"
                });
            }
            if (type === "discard") {
                eventBus.dispatch("BAH:discardCurrentForm", {
                    message: "discard button clicked"
                });
            }
        }
        if (currentLocationPathname.startsWith("/pages/orders")) {
            if (type === "save") {
                eventBus.dispatch("BAH:saveYourOrdersForm", {
                    message: "discard button clicked"
                });
            }
            if (type === "discard") {
                eventBus.dispatch("BAH:discardYourOrdersForm", {
                    message: "discard button clicked"
                });
            }
        }
        if (currentLocationPathname.startsWith("/pages/gift-cards")) {
            if (type === "save") {
                eventBus.dispatch("BAH:saveGiftCardsForm", {
                    message: "discard button clicked"
                });
            }
            if (type === "discard") {
                eventBus.dispatch("BAH:discardGiftCardsForm", {
                    message: "discard button clicked"
                });
            }
        }
        if (currentLocationPathname.startsWith("/pages/promo-codes")) {
            if (type == "save") {
                eventBus.dispatch("BAH:savePromoCodesForm", {
                    message: "discard button clicked"
                });
            }
            if (type == "discard") {
                eventBus.dispatch("BAH:discardPromoCodesForm", {
                    message: "discard button clicked"
                });
            }
        }
        if (currentLocationPathname.startsWith("/pages/recommended-items")) {
            if (type == "save") {
                eventBus.dispatch("BAH:saveRecommendedForm", {
                    message: "discard button clicked"
                });
            }
            if (type == "discard") {
                eventBus.dispatch("BAH:discardRecommendedForm", {
                    message: "discard button clicked"
                });
            }
        }
        if (currentLocationPathname.startsWith("/design")) {
            if (type == "save") {
                eventBus.dispatch("BAH:saveDesignSettingsForm", {
                    message: "save button clicked"
                });
            }
            if (type == "discard") {
                eventBus.dispatch("BAH:discardDesignSettingsForm", {
                    message: "discard button clicked"
                });
            }
        }
        if (currentLocationPathname.startsWith("/pages/wishlist")) {
            if (type == "save") {
                eventBus.dispatch("BAH:saveWishlistForm", {
                    message: "discard button clicked"
                });
            }
            if (type == "discard") {
                eventBus.dispatch("BAH:discardWishlistForm", {
                    message: "discard button clicked"
                });
            }
        }
    };

    const userMenuActions = [
        {
            items: [
                {
                    content: "Settings",
                    onAction: () => {
                        history.push("/settings");
                    }
                },
                {
                    content: "Logout",
                    onAction: () => {
                        alert("will logout");
                    }
                }
            ]
        }
    ];

    const toggleIsSecondaryMenuOpen = useCallback(
        () =>
            setIsSecondaryMenuOpen(
                (isSecondaryMenuOpen) => !isSecondaryMenuOpen
            ),
        []
    );

    const userMenuMarkup = (
        <TopBar.UserMenu
            actions={userMenuActions}
            name={user ? user?.fullName : `NA`}
            detail={user ? user?.owner_email : `NA`}
            initials={user ? user?.fullName?.charAt(0).toUpperCase() : `X`}
            open={userMenuActive}
            onToggle={toggleUserMenuActive}
        />
    );

    const secondaryMenuMarkup = (
        <TopBar.Menu
            activatorContent={
                <div style={{ width: "150px" }}>
                    <div
                        style={{
                            display: "inline-flex",
                            cursor: "pointer",
                            marginTop: "2px"
                        }}
                        onClick={(e) => {}}
                    >
                        <>
                            <div style={{ display: "inline-flex" }}>
                                <Icon
                                    source={QuestionMarkMajor}
                                    color="subdued"
                                />
                            </div>
                            <p
                                style={{
                                    marginLeft: "8px",
                                    color: "#5C5F62"
                                }}
                            >
                                Need Help ?
                            </p>
                        </>
                    </div>
                </div>
            }
            open={isSecondaryMenuOpen}
            onOpen={toggleIsSecondaryMenuOpen}
            onClose={toggleIsSecondaryMenuOpen}
            actions={[
                {
                    items: [
                        {
                            content: "FAQ",
                            onAction: () => {
                                alert("will redirect to faq");
                            }
                        },
                        {
                            content: "Live Support",
                            onAction: () => {
                                alert("will open live support");
                            }
                        }
                    ]
                }
            ]}
        />
    );

    const topBarMarkup = (
        <TopBar
            showNavigationToggle
            userMenu={userMenuMarkup}
            onNavigationToggle={toggleMobileNavigationActive}
            secondaryMenu={secondaryMenuMarkup}
        />
    );
    const itemsArr: any[] = [
        {
            label: "Pages",
            icon: PageMajor,
            url: "#",
            selected: openPageSubMenu || location.pathname.startsWith("/pages"),
            onClick: () => {
                setOpenPageSubMenu((val) => !val);
            },
            subNavigationItems: [
                {
                    url: "/pages/account",
                    label: "Your Account",
                    selected: location.pathname.startsWith("/pages/account"),
                    onClick: () => {
                        history.push("/pages/account");
                    }
                },
                {
                    url: "/pages/orders",
                    label: "Your Orders",
                    selected: location.pathname.startsWith("/pages/orders"),
                    onClick: () => {
                        history.push("/pages/orders");
                    }
                },
                {
                    url: "/pages/gift-cards",
                    label: "Gift Cards",
                    selected: location.pathname.startsWith("/pages/gift-cards"),
                    onClick: () => {
                        history.push("/pages/gift-cards");
                    }
                },
                {
                    url: "/pages/promo-codes",
                    label: "Promo Codes",
                    selected:
                        location.pathname.startsWith("/pages/promo-codes"),
                    onClick: () => {
                        history.push("/pages/promo-codes");
                    }
                },
                {
                    url: "/pages/recommended-items",
                    label: "Recommended Items",
                    selected: location.pathname.startsWith(
                        "/pages/recommended-items"
                    ),
                    onClick: () => {
                        history.push("/pages/recommended-items");
                    }
                },
                {
                    url: "/pages/wishlist",
                    label: "Wishlist",
                    selected: location.pathname.startsWith("/pages/wishlist"),
                    onClick: () => {
                        history.push("/pages/wishlist");
                    }
                }
            ]
        }
    ];
    const navigationMarkup = (
        <Navigation location="/">
            <Navigation.Section
                title=""
                items={[
                    {
                        label: "Home",
                        url: "/",
                        icon: HomeMajor,
                        selected: location.pathname == "/" && !openPageSubMenu,
                        onClick: () => {
                            setOpenPageSubMenu(false);
                            history.push("/");
                        }
                    },
                    ...itemsArr,
                    {
                        label: "Design",
                        url: "/design",
                        icon: IllustrationMajor,
                        selected:
                            location.pathname.startsWith("/design") &&
                            !openPageSubMenu,
                        onClick: () => {
                            setOpenPageSubMenu(false);
                            history.push("/design");
                        }
                    }
                ]}
            />
            <div
                className="settings-nav-item"
                style={{ bottom: 0, position: "absolute" }}
            >
                <Navigation.Section
                    title=""
                    items={[
                        {
                            label: "Settings",
                            url: "/settings",
                            icon: SettingsMajor,
                            selected:
                                location.pathname.startsWith("/settings") &&
                                !openPageSubMenu,
                            onClick: () => {
                                setOpenPageSubMenu(false);
                                history.push("/settings");
                            }
                        }
                    ]}
                />
            </div>
        </Navigation>
    );

    return (
        <div style={{ height: "500px" }}>
            <Frame
                topBar={topBarMarkup}
                navigation={navigationMarkup}
                showMobileNavigation={mobileNavigationActive}
            >
                <Modal
                    open={openConfirmationDiscard}
                    onClose={() => {
                        setOpenConfirmationDiscard(false);
                    }}
                    title="Discard all unsaved changes"
                    primaryAction={{
                        content: "Discard Changes",

                        destructive: true,
                        onAction: () => {
                            eventDispatcher(`discard`);

                            setOpenConfirmationDiscard(false);
                        }
                    }}
                    secondaryActions={[
                        {
                            content: "Continue Editing",
                            onAction: () => {
                                setOpenConfirmationDiscard(false);
                            }
                        }
                    ]}
                >
                    <Modal.Section>
                        <p>
                            If you discard changes, you’ll delete any edits you
                            made since you last saved.
                        </p>
                    </Modal.Section>
                </Modal>
                {didValueChange && (
                    <ContextualSaveBar
                        message="Unsaved changes"
                        saveAction={{
                            onAction: () => {
                                console.log(
                                    "Save changes : clicked from header"
                                );
                                // eslint-disable-next-line no-restricted-globals
                                eventDispatcher(`save`);
                            },
                            loading: dataSubmitting,
                            disabled: false
                        }}
                        discardAction={{
                            onAction: () => {
                                console.log(
                                    "Discard changes : clicked from header"
                                );

                                setOpenConfirmationDiscard(true);
                            },
                            disabled: dataSubmitting
                        }}
                    />
                )}
                {props.children}
            </Frame>
        </div>
    );
}
