import {
    Card,
    Heading,
    RadioButton,
    Button,
    TextStyle,
    Select,
    TextField,
    Form,
    Layout,
    HSBColor,
    Banner,
    RGBColor
} from "@shopify/polaris";
import React, { useState, useCallback, useContext, useEffect } from "react";
import WishlistPreview from "./Preview/Index";
import ColorOptions from "../../../components/Common/ColorOptions/ColorOptions";
import { SwitchOffIcon, SwitchOnIcon } from "../../../common/icons";
import "../wishlist.scss";

enum EnumHeaderPlacement {
    BOTTOM_RIGHT = "bottom-right",
    BOTTOM_LEFT = "bottom-left",
    TOP_LEFT = "top-left",
    TOP_RIGHT = "top-right"
}

interface FormDataType {
    header_appearance: {
        selected_style: string;
        choose_placement_point: EnumHeaderPlacement;
        wishlist_module_name: string;
        display_number_of_items: boolean;
        floating_button_color: {
            hsb: HSBColor;
            rgb: RGBColor;
            hex: string;
        };
    };
    button_options: {
        selected_style: string;
        label_before_adding: string;
        label_after_adding: string;
        bottom_color: {
            hsb: HSBColor;
            rgb: RGBColor;
            hex: string;
        };
        text_color: {
            hsb: HSBColor;
            rgb: RGBColor;
            hex: string;
        };
    };
    product_options: {
        placement_pos: string;
        label_before_adding: string;
        label_after_adding: string;
        bottom_color: {
            hsb: HSBColor;
            rgb: RGBColor;
            hex: string;
        };
    };
}
interface PropTypes {
    onDataChange: (val: FormDataType) => void;
    onChangeColor: (updatedColor: any, type: string, index: number) => void;
    colorDataForHeader: any;
    colorDataForButtonOptionBG: any;
    colorDataForButtonOptionText: any;
    colorDataForProductCard: any;
    isDataSubmitting: boolean | undefined;
    isDisabled: boolean;
    data: FormDataType;
}
const MenuPage = (props: PropTypes) => {
    const options = [
        { label: "Bottom Right", value: "bottom-right" },
        { label: "Bottom Left", value: "bottom-left" },
        { label: "Top Left", value: "top-left" },
        { label: "Top Right", value: "top-right" }
    ];

    const [buttonStyle, setButtonStyle] = useState(1);
    useEffect(() => {
        console.log(props);
    }, [props]);
    useEffect(() => {
        if (props.data.button_options.selected_style === "dg_1") {
            setButtonStyle(1);
        } else if (props.data.button_options.selected_style === "dg_2") {
            setButtonStyle(2);
        } else if (props.data.button_options.selected_style === "dg_3") {
            setButtonStyle(3);
        }
    }, [props]);
    const getSelectedDiv = (type: any) => {
        if (type === "floating") {
            return <>{floating_action_button()}</>;
        } else if (type === "menu") {
            return <>{add_as_menu()}</>;
        } else {
            return <>{add_toHeader_menu()}</>;
        }
    };

    // components

    function add_toHeader_menu() {
        return (
            <>
                <div>
                    <ColorOptions
                        handler={(val: any) => {
                            props.onChangeColor(val, "header", 0);
                        }}
                        data={props.colorDataForHeader}
                    />
                </div>
            </>
        );
    }
    function add_as_menu() {
        return (
            <>
                <Banner
                    title="Please folllow the steps given bellow."
                    status="warning"
                >
                    <div>
                        <ol>
                            <li>
                                {" "}
                                Open the main navigation on your shopify site. -{" "}
                                <a>Open</a>
                            </li>
                            <li>
                                {" "}
                                Select your main menu and click on "Add menu
                                item" to add a new menu item.{" "}
                            </li>
                            <li> Choose a name for the Wishlist module.</li>
                            <li>
                                {" "}
                                In the text box for Link, paste the following
                                text : <b>#swym-wishlist.</b>
                            </li>
                            <li>Click on "Save Menu".</li>
                        </ol>
                    </div>
                </Banner>
            </>
        );
    }
    function floating_action_button() {
        return (
            <>
                <div className="timeTextTime">
                    <Select
                        label="Choose a placement point for the button on your site"
                        options={options}
                        onChange={(val: EnumHeaderPlacement) => {
                            props.onDataChange({
                                ...props.data,
                                header_appearance: {
                                    ...props.data.header_appearance,
                                    choose_placement_point: val
                                }
                            });
                        }}
                        value={
                            props.data.header_appearance.choose_placement_point
                        }
                    />
                </div>
                <div className="timeTextTime">
                    <TextField
                        label="What do you want to call your Wishlist module?"
                        placeholder="My Wishlist"
                        onChange={(val: string) => {
                            props.onDataChange({
                                ...props.data,
                                header_appearance: {
                                    ...props.data.header_appearance,
                                    wishlist_module_name: val
                                }
                            });
                        }}
                        value={
                            props.data.header_appearance.wishlist_module_name
                        }
                    />
                </div>
                <div>
                    <ColorOptions
                        handler={(val: any) => {
                            props.onChangeColor(val, "header", 0);
                        }}
                        data={props.colorDataForHeader}
                    />
                </div>
            </>
        );
    }
    return (
        <>
            <Layout>
                <Layout.Section oneHalf>
                    <div>
                        <Card>
                            <div className="cardContainer">
                                <div>
                                    <Heading>HEADER APPEARANCE</Heading>
                                </div>

                                <div style={{ marginTop: "25px" }}>
                                    <Heading element="h6">Select Style</Heading>
                                </div>
                                <div className="menuRadio">
                                    <RadioButton
                                        label="Floating Button"
                                        id="floatingbutton"
                                        name="floating_button"
                                        onChange={() => {
                                            props.onDataChange({
                                                ...props.data,
                                                header_appearance: {
                                                    ...props.data
                                                        .header_appearance,
                                                    selected_style: "floating"
                                                }
                                            });
                                        }}
                                        checked={
                                            props.data.header_appearance
                                                .selected_style === "floating"
                                        }
                                    />
                                    <RadioButton
                                        label="As a menu item"
                                        id="menuitem"
                                        name="as_menu"
                                        onChange={() => {
                                            props.onDataChange({
                                                ...props.data,
                                                header_appearance: {
                                                    ...props.data
                                                        .header_appearance,
                                                    selected_style: "menu"
                                                }
                                            });
                                        }}
                                        checked={
                                            props.data.header_appearance
                                                .selected_style === "menu"
                                        }
                                    />
                                    <RadioButton
                                        label="Add to your header menu"
                                        id="headermenu"
                                        name="header_icon"
                                        onChange={() => {
                                            props.onDataChange({
                                                ...props.data,
                                                header_appearance: {
                                                    ...props.data
                                                        .header_appearance,
                                                    selected_style:
                                                        "header_icon"
                                                }
                                            });
                                        }}
                                        checked={
                                            props.data.header_appearance
                                                .selected_style ===
                                            "header_icon"
                                        }
                                    />
                                </div>

                                {/* Components for Radio Button */}
                                <div className="menuItems">
                                    {getSelectedDiv(
                                        props.data.header_appearance
                                            .selected_style
                                    )}
                                </div>
                                <div
                                    className="title"
                                    style={{
                                        paddingLeft: "0px"
                                    }}
                                >
                                    <div>
                                        {!props.data.header_appearance
                                            .display_number_of_items ? (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // handleButtonActivate();
                                                    props.onDataChange({
                                                        ...props.data,
                                                        header_appearance: {
                                                            ...props.data
                                                                .header_appearance,
                                                            display_number_of_items:
                                                                true
                                                        }
                                                    });
                                                }}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}
                                            >
                                                <SwitchOffIcon />

                                                <p
                                                    style={{
                                                        marginLeft: "15px"
                                                    }}
                                                >
                                                    {" "}
                                                    Display number of items in
                                                    the icon
                                                </p>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // handleButtonActivate();
                                                    props.onDataChange({
                                                        ...props.data,
                                                        header_appearance: {
                                                            ...props.data
                                                                .header_appearance,
                                                            display_number_of_items:
                                                                false
                                                        }
                                                    });
                                                }}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row"
                                                }}
                                            >
                                                <SwitchOnIcon />

                                                <p
                                                    style={{
                                                        marginLeft: "15px"
                                                    }}
                                                >
                                                    {" "}
                                                    Display number of items in
                                                    the icon
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div>
                                    <div className="topBorderLine" />
                                    <div className="">
                                        <Heading>BUTTON OPTIONS</Heading>
                                    </div>
                                </div>
                                <div style={{ marginTop: "25px" }}>
                                    <Heading element="h6">Select Style</Heading>
                                </div>

                                <div className="mainRadioBox">
                                    <div className="midRadioBox">
                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    button_options: {
                                                        ...props.data
                                                            .button_options,
                                                        selected_style: "dg_1"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label=" "
                                                value=""
                                                checked={
                                                    props.data.button_options
                                                        .selected_style ===
                                                    "dg_1"
                                                }
                                                id="AddToWishListBtn"
                                                name="wishlist"
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: `${props.data.button_options.bottom_color.hex}`,
                                                    color: `${props.data.button_options.text_color.hex}`,
                                                    paddingLeft: "8px",
                                                    paddingRight: "8px",
                                                    borderRadius: "5px",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <p>♡ ADD TO WISHLIST</p>
                                            </div>
                                        </div>

                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    button_options: {
                                                        ...props.data
                                                            .button_options,
                                                        selected_style: "dg_2"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label=" "
                                                value=""
                                                id="AddToWishListHeartBtn"
                                                checked={
                                                    props.data.button_options
                                                        .selected_style ===
                                                    "dg_2"
                                                }
                                                name="wishlist"
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: `${props.data.button_options.bottom_color.hex}`,
                                                    color: `${props.data.button_options.text_color.hex}`,
                                                    paddingLeft: "8px",
                                                    paddingRight: "8px",
                                                    borderRadius: "5px",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <p>ADD TO WISHLIST</p>
                                            </div>
                                        </div>

                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    button_options: {
                                                        ...props.data
                                                            .button_options,
                                                        selected_style: "dg_3"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label=" "
                                                value=""
                                                checked={
                                                    props.data.button_options
                                                        .selected_style ===
                                                    "dg_3"
                                                }
                                                id="heartBtn"
                                                name="wishlist"
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: `${props.data.button_options.bottom_color.hex}`,
                                                    color: `${props.data.button_options.text_color.hex}`,
                                                    paddingLeft: "8px",
                                                    paddingRight: "8px",
                                                    borderRadius: "5px",
                                                    paddingTop: "1px",
                                                    paddingBottom: "1px",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <p>♡</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="head">
                                        <TextStyle variation="strong">
                                            Label Before Adding to Wishlist
                                        </TextStyle>
                                    </div>

                                    <div className="timeText">
                                        <TextField
                                            label=""
                                            id="AddWishlist"
                                            value={
                                                props.data.button_options
                                                    .label_before_adding
                                            }
                                            name="AddWishlist"
                                            placeholder="Add to Wishlist"
                                            onChange={(val: string) => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    button_options: {
                                                        ...props.data
                                                            .button_options,
                                                        label_before_adding: val
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="head">
                                        <TextStyle variation="strong">
                                            After Adding to Wishlist
                                        </TextStyle>
                                    </div>

                                    <div className="timeText">
                                        <TextField
                                            label=""
                                            id="AddedWishlist"
                                            name="AddedWishlist"
                                            value={
                                                props.data.button_options
                                                    .label_after_adding
                                            }
                                            placeholder="Added to Wishlist"
                                            onChange={(val: string) => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    button_options: {
                                                        ...props.data
                                                            .button_options,
                                                        label_after_adding: val
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="dropBox">
                                    <ColorOptions
                                        handler={(val: any) => {
                                            props.onChangeColor(
                                                val,
                                                "button_option_color_bg",
                                                0
                                            );
                                        }}
                                        data={props.colorDataForButtonOptionBG}
                                    />
                                    <ColorOptions
                                        handler={(val: any) => {
                                            props.onChangeColor(
                                                val,
                                                "button_option_text_color",
                                                0
                                            );
                                        }}
                                        data={
                                            props.colorDataForButtonOptionText
                                        }
                                    />

                                    <div className="head">
                                        <div className="switch">
                                            <Button onClick={() => {}} plain>
                                                {/* <img 
                                            src={ switchOn } 
                                            alt="on" 
                                            height="10"
                                            onClick={handleChange} /> */}
                                            </Button>
                                        </div>

                                        {/* <div className="">
                                            <p>
                                                {" "}
                                                Display count of how many users
                                                have added this item to their
                                                Wishlist.
                                            </p>
                                        </div> */}
                                        <div className="topBorderLine" />
                                    </div>
                                </div>

                                <div>
                                    <Heading element="h6">
                                        Label on product card
                                    </Heading>
                                </div>

                                <div className="mainRadioBox">
                                    <div className="midRadioBox">
                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    product_options: {
                                                        ...props.data
                                                            .product_options,
                                                        placement_pos:
                                                            "top-left"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label="Top Left"
                                                checked={
                                                    props.data.product_options
                                                        .placement_pos ===
                                                    "top-left"
                                                }
                                                id="Top-Left"
                                                name="setCardLabel"
                                            />
                                        </div>
                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    product_options: {
                                                        ...props.data
                                                            .product_options,
                                                        placement_pos:
                                                            "top-right"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label="Top Right"
                                                checked={
                                                    props.data.product_options
                                                        .placement_pos ===
                                                    "top-right"
                                                }
                                                id="Top-Right"
                                                name="setCardLabel"
                                            />
                                        </div>
                                    </div>
                                    <div className="midRadioBox">
                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    product_options: {
                                                        ...props.data
                                                            .product_options,
                                                        placement_pos:
                                                            "bottom-left"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label="Bottom Left"
                                                checked={
                                                    props.data.product_options
                                                        .placement_pos ===
                                                    "bottom-left"
                                                }
                                                id="Bottom-Left"
                                                name="setCardLabel"
                                                onChange={() => {}}
                                            />
                                        </div>
                                        <div
                                            className="RadioButtonUnFix"
                                            onClick={() => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    product_options: {
                                                        ...props.data
                                                            .product_options,
                                                        placement_pos:
                                                            "bottom-right"
                                                    }
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label="Bottom Right"
                                                checked={
                                                    props.data.product_options
                                                        .placement_pos ===
                                                    "bottom-right"
                                                }
                                                id="Bottom-Right"
                                                name="setCardLabel"
                                                onChange={() => {}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="head">
                                        <TextStyle variation="strong">
                                            Label Before Adding to Cart
                                        </TextStyle>
                                    </div>

                                    <div className="timeText">
                                        <TextField
                                            label=""
                                            id="AddCart"
                                            name="AddCart"
                                            placeholder="Add to Cart"
                                            value={
                                                props.data.product_options
                                                    .label_before_adding
                                            }
                                            onChange={(val: string) => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    product_options: {
                                                        ...props.data
                                                            .product_options,
                                                        label_before_adding: val
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="head">
                                        <TextStyle variation="strong">
                                            After Adding to Cart
                                        </TextStyle>
                                    </div>

                                    <div className="timeText">
                                        <TextField
                                            label=""
                                            id="AddedCart"
                                            name="AddedCart"
                                            value={
                                                props.data.product_options
                                                    .label_after_adding
                                            }
                                            onChange={(val: string) => {
                                                props.onDataChange({
                                                    ...props.data,
                                                    product_options: {
                                                        ...props.data
                                                            .product_options,
                                                        label_after_adding: val
                                                    }
                                                });
                                            }}
                                            placeholder="Added to Cart"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <ColorOptions
                                        handler={(val: any) => {
                                            props.onChangeColor(
                                                val,
                                                "product",
                                                0
                                            );
                                        }}
                                        data={props.colorDataForProductCard}
                                    />
                                </div>

                                <div className="buttonBox">
                                    <div>
                                        <Button
                                            size="medium"
                                            primary
                                            loading={props.isDataSubmitting}
                                            disabled={props.isDisabled}
                                        >
                                            Save
                                        </Button>{" "}
                                    </div>

                                    {/* <div className="redBtn">
                                        <Button
                                            size="medium"
                                            monochrome
                                            outline
                                        >
                                            Delete
                                        </Button>
                                    </div> */}
                                </div>
                            </div>
                        </Card>
                    </div>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <WishlistPreview
                        placement={"top_left"}
                        typeOfStyle={buttonStyle}
                        typeOfHeader={`floating`}
                    />
                </Layout.Section>
            </Layout>
        </>
    );
};

export default MenuPage;
