import { useState } from "react";

import FeaturedItemsPreview from "./FeaturedItems";

import { OptionList, Card, Thumbnail } from "@shopify/polaris";

import { MobileMajor, TabletMajor, DesktopMajor } from "@shopify/polaris-icons";

import "./skeleton.scss";

function MainPreview(props: any) {
    const [device, setDevice] = useState(["desktop"]);

    const mobileLabel = <Thumbnail source={MobileMajor} alt="Mobile" />;
    const tabletLabel = <Thumbnail source={TabletMajor} alt="Tablet" />;
    const desktopLabel = <Thumbnail source={DesktopMajor} alt="Desktop" />;

    return (
        <div>
            <div className="skeleton__preview-header">
                <Card>
                    <OptionList
                        onChange={(val: any) => {
                            setDevice(val);
                        }}
                        options={[
                            { value: "mobile", label: mobileLabel },
                            { value: "tablet", label: tabletLabel },
                            { value: "desktop", label: desktopLabel }
                        ]}
                        selected={device}
                    />
                </Card>
            </div>

            <FeaturedItemsPreview
                //leave empty to show placeholder
                device={device}
            />
        </div>
    );
}

export default MainPreview;
