import "./reviewSection.scss";
import {
    SkeletonBodyText,
    SkeletonThumbnail,
    SkeletonDisplayText,
    Button
} from "@shopify/polaris";

function ReviewSection(props: any) {
    const itemsKey = [1, 2, 3, 4];
    const parentClass =
        "review-section__preview  skeleton__preview skeleton__preview-" +
        props.device;

    const heading = (
        <h2 className="skeleton__preview-heading  review-section__preview-heading">
            {props.heading ? (
                <span>{props.heading.map((item: any) => item)}</span>
            ) : (
                <SkeletonDisplayText />
            )}
        </h2>
    );

    return (
        <div className={parentClass}>
            {heading}
            {itemsKey.map((index) => (
                <div key={index} className="review-section__preview-item">
                    <SkeletonThumbnail />
                    <SkeletonBodyText />
                    <Button primary>Leave a Review</Button>
                </div>
            ))}
        </div>
    );
}

export default ReviewSection;
