import React, { useContext } from "react";
import PromoCodeAutocomplete from "../../../components/Accounts/PromoCodeAutocomplete";

function PromoCodeIndex(props: any) {
    const { data, updateData } = props;
    const handleClearData = () => {
        updateData({
            ...data,
            promo_section: {
                ...data?.promo_section,
                selected_id: ``,
                selected_value: ``,
                selected_code: "",
                selected_code_image: ""
            }
        });
    };
    return (
        <>
            <PromoCodeAutocomplete
                promoSectionData={data.promo_section}
                fieldName={"Promocode"}
                updateData={updateData}
                allData={props.data}
                handleClearData={handleClearData}
            />
        </>
    );
}

export default PromoCodeIndex;
