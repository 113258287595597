import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Page, Button, Spinner, Banner } from "@shopify/polaris";
import _ from "lodash";
import YourAccount from "./YourAccount";
import {
    singleImageUpload,
    saveYourAccountPageData,
    getYourAccountPageData,
    getPresignURLToUpload,
    getAllPromoCodes
} from "../../API/index";
import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import eventBus from "../../common/EventBus";
import { SelectedPosition } from "../../types-interfaces/YourAccount";
import imageCompression from "browser-image-compression";
import LoadingAnimation from "../../components/StaticComponents/LoadingAnimation";
import { GlobalContext } from "../../Contexts/GlobalContext";
function YourAccountPage() {
    const history = useHistory();
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const [isFormComplete, setIsFormComplete] = useState<any>({
        promo_section: false,
        image_section: false,
        leave_review_section: false,
        newsletter_section: false,
        featured_items_section: false
    });
    const [finalFormData, setFinalFormData] = useState<any>({
        isEnabled: false,
        image_section: {
            image_position: SelectedPosition.RIGHT,
            image_file: null,
            image_url: null,
            heading: "Offer Description.",
            sub_heading: "Don't miss out!",
            button_text: "LEARN MORE",
            button_link: "",
            isEnabled: false
        },
        promo_section: {
            selected_id: "",
            selected_value: "",
            selected_code: "",
            selected_code_image: "",
            codes_list: [],
            isEnabled: false
        },
        newsletter_section: {
            image_position: SelectedPosition.LEFT,
            image_file: null,
            image_url: null,
            heading: "SUBSCRIBE TO NEWSLETTER",
            sub_heading:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec mus malesuada purus dui pellentesque lectus.",
            button_text: "SUBSCRIBE",
            isEnabled: false
        },
        leave_review: {
            value: "",
            heading: "",
            isEnabled: false
        },
        featured_items: {
            selected_collection: {
                id: -1,
                title: "",
                handle: ""
            },
            output: [],
            heading: "<p><strong>STAFF PICKS</strong></p>",
            isEnabled: false
        },
        openCard: ""
    });
    const [submitForm, setSubmitForm] = useState(false);
    const [initialData, setInitialData] = useState<any>({
        isEnabled: false,
        image_section: {
            image_position: SelectedPosition.RIGHT,
            image_file: null,
            image_url: null,
            heading: "Offer Description.",
            sub_heading: "Don't miss out!",
            button_text: "LEARN MORE",
            button_link: "",
            isEnabled: false
        },
        promo_section: {
            selected_id: "",
            selected_value: "",
            selected_code: "",
            selected_code_image: "",
            codes_list: [],
            isEnabled: false
        },
        newsletter_section: {
            image_position: SelectedPosition.LEFT,
            image_file: null,
            image_url: null,
            heading: "SUBSCRIBE TO NEWSLETTER",
            sub_heading:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec mus malesuada purus dui pellentesque lectus.",
            button_text: "SUBSCRIBE",
            isEnabled: false
        },
        leave_review: {
            value: "",
            heading: "",
            isEnabled: false
        },
        featured_items: {
            selected_collection: {
                id: -1,
                title: "",
                handle: ""
            },
            output: [],
            heading: "<p><strong>STAFF PICKS</strong></p>",
            isEnabled: false
        },
        openCard: ""
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [orderOfSection, setOrderOfSections] = useState<any>([
        {
            title: "Promo Section",
            id: "promo_section"
        },
        {
            title: "Image Section",
            id: "image_section"
        },
        {
            title: "Leave a Review",
            id: "leave_review"
        },
        {
            title: "Subscribe to Newsletter",
            id: "newsletter_section"
        },
        {
            title: "Featured Items",
            id: "featured_items"
        }
    ]);
    const [initialLoading, setInitialLoading] = useState(true);

    const handleSave = async () => {
        updateDataSubmitting(true);
        console.log("saving data");
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        let image_section_url = `${finalFormData.image_section.image_url}`;
        let newsletter_section_url = `${finalFormData.newsletter_section.image_url}`;

        if (
            finalFormData &&
            finalFormData.image_section &&
            finalFormData.image_section.image_file
        ) {
            console.log("uploading image for image section");
            const compressedFileForImageSection = await imageCompression(
                finalFormData.image_section.image_file,
                options
            );
            const getS3URLForImageSection =
                (await getPresignURLToUpload(
                    compressedFileForImageSection.name,
                    compressedFileForImageSection.type
                )) ?? "";
            const uploadImageForImageSection = await await singleImageUpload(
                getS3URLForImageSection.uploadURL,
                compressedFileForImageSection
            );
            image_section_url = getS3URLForImageSection.downloadURL;
            console.log(uploadImageForImageSection);
        }
        if (
            finalFormData &&
            finalFormData.newsletter_section &&
            finalFormData.newsletter_section.image_file
        ) {
            console.log("uploading image for news section");
            const compressedFileForNewsLetter = await imageCompression(
                finalFormData.newsletter_section.image_file,
                options
            );
            const getS3URLForNewsLetterSection =
                (await getPresignURLToUpload(
                    compressedFileForNewsLetter.name,
                    compressedFileForNewsLetter.type
                )) ?? "";
            const uploadImageForNewsLetterSection =
                await await singleImageUpload(
                    getS3URLForNewsLetterSection.uploadURL,
                    compressedFileForNewsLetter
                );
            console.log(uploadImageForNewsLetterSection);
            newsletter_section_url = getS3URLForNewsLetterSection.downloadURL;
        }

        const finalFormDataFormatted = {
            ...finalFormData,
            image_section: {
                ...finalFormData.image_section,
                image_url: image_section_url
            },
            newsletter_section: {
                ...finalFormData.newsletter_section,
                image_url: newsletter_section_url
            },
            section_order: [...orderOfSection]
        };
        console.log("final form data");
        console.log(finalFormDataFormatted);

        const result = await saveYourAccountPageData(finalFormDataFormatted);
        console.log(result);
        if (result) {
            updateDataSubmitting(false);
            setInitialData({
                ...finalFormData
            });
            setFinalFormData({
                ...finalFormData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
        setSubmitForm(false);
    };

    const setDefaultValues = () => {
        setInitialData({
            isEnabled: false,
            image_section: {
                image_position: SelectedPosition.RIGHT,
                image_file: null,
                image_url: null,
                heading: "Offer Description.",
                sub_heading: "Don't miss out!",
                button_text: "LEARN MORE",
                button_link: "",
                isEnabled: false
            },
            promo_section: {
                selected_id: "",
                selected_value: "",
                selected_code: "",
                selected_code_image: "",
                codes_list: [],
                isEnabled: false
            },
            newsletter_section: {
                image_position: SelectedPosition.LEFT,
                image_file: null,
                image_url: null,
                heading: "SUBSCRIBE TO NEWSLETTER",
                sub_heading:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec mus malesuada purus dui pellentesque lectus.",
                button_text: "SUBSCRIBE",
                isEnabled: false
            },
            leave_review: {
                value: "",
                heading: "",
                isEnabled: false
            },
            featured_items: {
                selected_collection: {
                    id: -1,
                    title: "",
                    handle: ""
                },
                output: [],
                heading: "<p><strong>STAFF PICKS</strong></p>",
                isEnabled: false
            },
            openCard: ""
        });
        setFinalFormData({
            isEnabled: false,
            image_section: {
                image_position: SelectedPosition.RIGHT,
                image_file: null,
                image_url: null,
                heading: "Offer Description.",
                sub_heading: "Don't miss out!",
                button_text: "LEARN MORE",
                button_link: "",
                isEnabled: false
            },
            promo_section: {
                selected_id: "",
                selected_value: "",
                selected_code: "",
                selected_code_image: "",
                codes_list: [],
                isEnabled: false
            },
            newsletter_section: {
                image_position: SelectedPosition.LEFT,
                image_file: null,
                image_url: null,
                heading: "SUBSCRIBE TO NEWSLETTER",
                sub_heading:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nec mus malesuada purus dui pellentesque lectus.",
                button_text: "SUBSCRIBE",
                isEnabled: false
            },
            leave_review: {
                value: "",
                heading: "",
                isEnabled: false
            },
            featured_items: {
                selected_collection: {
                    id: -1,
                    title: "",
                    handle: ""
                },
                output: [],
                heading: "<p><strong>STAFF PICKS</strong></p>",
                isEnabled: false
            },
            openCard: ""
        });

        setInitialLoading(false);
    };
    const setValuesFromData = (data: any) => {
        setInitialData({
            ...data
        });
        setFinalFormData({
            ...data
        });
        console.log(data.section_order);
        setOrderOfSections([...data.section_order]);
        setInitialLoading(false);
    };
    const handleCancel = () => {
        eventBus.dispatch("BAH:discardCurrentForm", {
            message: "discard button clicked"
        });
        console.log("dispatched cancel buttom");
    };
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:saveCurrentForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            eventBus.remove("BAH:saveCurrentForm");
            console.log("UNMOUNTED COMPONENT EVENT LISTENER");
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (submitForm) {
                handleSave();
            }
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);
    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const result = await getYourAccountPageData();
            const fetchPromoCodes = await getAllPromoCodes();
            if (result) {
                const formattedObject = {
                    ...result,
                    promo_section: {
                        ...result.promo_section,
                        codes_list: fetchPromoCodes
                    }
                };
                setValuesFromData({ ...formattedObject });
            } else {
                console.log(`error`);
                setDefaultValues();
            }
        };
        if (mounted) {
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (!initialLoading) {
                updateValueChange(!_.isEqual(initialData, finalFormData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [finalFormData, initialData]);

    return (
        <>
            <PageWrapper
                title="Your Account"
                subtitle="You’re able to add & reorder sections in a way you like here."
                // separator={true}
                id="your-account-page"
                isDisabled={!didValueChange}
                loading={initialLoading}
                showPrimaryButtons={true}
                handleSave={handleSave}
                handleCancel={handleCancel}
                showSwitchButton={true}
                SwitchVal={finalFormData.isEnabled}
                onChangeSwitchVal={() => {
                    setFinalFormData({
                        ...finalFormData,
                        isEnabled: !finalFormData.isEnabled
                    });
                }}
            >
                {initialLoading ? (
                    <>
                        <LoadingAnimation
                            accessibilityLabel="loading account page data"
                            size="large"
                        />
                    </>
                ) : (
                    <>
                        {showSuccessMessage && (
                            <Banner
                                title="Saved your account data"
                                status="success"
                                action={{
                                    content: "Go To Dashboard",
                                    onAction: () => {
                                        history.push("/");
                                    }
                                }}
                                onDismiss={() => {
                                    setShowSuccessMessage(false);
                                }}
                            />
                        )}
                        {showErrorMessage && (
                            <Banner
                                title="Something went wrong while saving."
                                status="critical"
                                action={{
                                    content: "Go To Dashboard",
                                    onAction: () => {
                                        history.push("/");
                                    }
                                }}
                                onDismiss={() => {
                                    setShowErrorMessage(false);
                                }}
                            />
                        )}

                        <YourAccount
                            setFinalData={setFinalFormData}
                            orderOfSection={orderOfSection}
                            mainData={finalFormData}
                            initialData={initialData}
                            changeOrder={setOrderOfSections}
                            isFormComplete={isFormComplete}
                            setIsFormComplete={setIsFormComplete}
                        />
                    </>
                )}
            </PageWrapper>
        </>
    );
}

export default YourAccountPage;
