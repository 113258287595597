import React, { useState, useEffect, useRef } from "react";
import { TextField, Icon, Stack, Card, Button } from "@shopify/polaris";
import {
    AddMajor,
    CirclePlusMajor,
    ArrowRightMinor
} from "@shopify/polaris-icons";
import { PromoCodeProps } from "../../types-interfaces/index";

const LeftArrowIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.707 9.29301L12.707 4.29301C12.316 3.90201 11.684 3.90201 11.293 4.29301C10.902 4.68401 10.902 5.31601 11.293 5.70701L14.586 9.00001H3C2.447 9.00001 2 9.44801 2 10C2 10.552 2.447 11 3 11H14.586L11.293 14.293C10.902 14.684 10.902 15.316 11.293 15.707C11.488 15.902 11.744 16 12 16C12.256 16 12.512 15.902 12.707 15.707L17.707 10.707C18.098 10.316 18.098 9.68401 17.707 9.29301Z"
            fill="#868686"
        />
    </svg>
);
function PromoAutoCompleteTextResult(props: any) {
    const [hoverTrack, setHoverTrack] = useState(false);
    return (
        <div
            style={{
                backgroundColor: hoverTrack
                    ? "rgba(56, 57, 58, 0.05)"
                    : "transparent"
            }}
            onMouseEnter={() => {
                setHoverTrack(true);
            }}
            onMouseLeave={() => {
                setHoverTrack(false);
            }}
            onClick={() => {
                props.handleClick();
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "25px",
                    marginBottom: "25px"
                }}
            >
                <div style={{ paddingLeft: 25 }}>
                    <Icon source={LeftArrowIcon} />
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        flexDirection: "column",
                        paddingLeft: 15
                    }}
                >
                    <div
                        style={{
                            color: "#222B36",
                            fontSize: "14px",
                            lineHeight: "24px",
                            fontStyle: "normal",
                            fontWeight: 500
                        }}
                    >
                        {props.title}
                    </div>
                    <div
                        style={{
                            color: "#A7AAAD",
                            fontSize: "14px",
                            lineHeight: "20px"
                        }}
                    >
                        {props.sub_title}
                    </div>
                </div>
            </div>
        </div>
    );
}
function PromoCodeAutocomplete(props: PromoCodeProps) {
    let inputRef = useRef<HTMLDivElement>(null);
    const [isSelectDropdownActive, setIsSelectDropdownActive] = useState(false);
    const LinkIcon = () => (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 6.414L9.707 11.707C9.316 12.098 8.684 12.098 8.293 11.707C7.902 11.316 7.902 10.684 8.293 10.293L13.586 5H11C10.447 5 10 4.552 10 4C10 3.448 10.447 3 11 3H15H17V9C17 9.552 16.553 10 16 10C15.447 10 15 9.552 15 9V6.414ZM8 4C8 4.552 7.553 5 7 5H5V15H15V13C15 12.448 15.447 12 16 12C16.553 12 17 12.448 17 13V15C17 16.104 16.104 17 15 17H5C3.896 17 3 16.104 3 15V5C3 3.895 3.896 3 5 3H7C7.553 3 8 3.448 8 4Z"
                fill="#235EAA"
            />
        </svg>
    );
    useEffect(() => {
        document.addEventListener("click", handleOuterClick);
    }, []);
    const handleOuterClick = (e: any) => {
        if (inputRef && inputRef.current) {
            if (!inputRef.current.contains(e.target)) {
                setIsSelectDropdownActive(false);
            }
        }
    };
    const [hoverTrack, setHoverTrack] = useState(false);
    const heightForSearch =
        props?.promoSectionData?.codes_list &&
        props.promoSectionData.codes_list.length > 0
            ? "250px"
            : "150px";
    return (
        <div
            onClick={(e: any) => {
                e.stopPropagation();
            }}
            ref={inputRef}
        >
            <div
                style={{ position: "relative" }}
                onClick={() => {
                    setIsSelectDropdownActive(true);
                }}
            >
                <TextField
                    label={`${props?.fieldName}`}
                    onChange={props?.updateData}
                    value={props?.promoSectionData?.selected_value}
                    placeholder={"Select Promocode"}
                    clearButton
                    onClearButtonClick={props?.handleClearData}
                />
            </div>
            {isSelectDropdownActive ? (
                <div style={{ padding: "5px" }}>
                    <div></div>
                    <Card>
                        <div
                            style={{
                                height: heightForSearch,
                                paddingTop: "20px",
                                backgroundColor: "white",
                                borderRadius: "4px",
                                boxShadow:
                                    "0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.1)",
                                overflowY: "auto",
                                position: "absolute",
                                width: "95%",
                                zIndex: 9999
                            }}
                        >
                            <div
                                style={{
                                    width: "100%",
                                    zIndex: 9999,
                                    display: "flex",
                                    flexDirection: "row",
                                    backgroundColor: hoverTrack
                                        ? "rgba(56, 57, 58, 0.05)"
                                        : "white"
                                }}
                                onMouseEnter={() => {
                                    setHoverTrack(true);
                                }}
                                onMouseLeave={() => {
                                    setHoverTrack(false);
                                }}
                            >
                                {/* <Button primary icon={CirclePlusMajor} size="large" fullWidth>Add Promo code</Button> */}
                                <div style={{ marginLeft: "25px" }}>
                                    <LinkIcon />
                                </div>
                                <div
                                    style={{ marginLeft: "25px" }}
                                    onClick={() => {
                                        window.open(
                                            "/pages/promo-codes",
                                            "_blank"
                                        );
                                    }}
                                >
                                    <p
                                        style={{
                                            color: "#235EAA",
                                            fontSize: "14px",
                                            fontWeight: 500,
                                            fontStyle: "normal",
                                            lineHeight: "20px"
                                        }}
                                    >
                                        Create Promo Code
                                    </p>
                                    <p
                                        style={{
                                            color: "#A7AAAD",
                                            fontSize: "14px",
                                            fontWeight: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "20px"
                                        }}
                                    >
                                        Create promo on Promo Codes section{" "}
                                        <br />
                                        help.cacount.com/manual/promocodes
                                    </p>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: "12px",
                                    textAlign: "center"
                                }}
                            >
                                <p
                                    style={{
                                        color: "#6D7175",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        fontStyle: "normal",
                                        lineHeight: "16px",
                                        textTransform: "uppercase"
                                    }}
                                >
                                    your promocodes
                                </p>
                            </div>
                            <div
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "25px"
                                }}
                            ></div>
                            {props?.promoSectionData?.codes_list &&
                            props.promoSectionData.codes_list.length > 0 ? (
                                props.promoSectionData.codes_list.map(
                                    (elem: any, index: number) => {
                                        return (
                                            <div
                                                key={`unique-card-auto-complete-${index}`}
                                            >
                                                <PromoAutoCompleteTextResult
                                                    index={index}
                                                    title={elem.heading}
                                                    sub_title={`${elem.discount_code}: ${elem.sub_heading}`}
                                                    handleClick={() => {
                                                        props.updateData({
                                                            ...props.allData,
                                                            promo_section: {
                                                                ...props.allData
                                                                    ?.promo_section,
                                                                selected_id: `${elem.uniqueCode}`,
                                                                selected_value: `${elem.heading}`,
                                                                selected_code: `${elem.discount_code}`,
                                                                selected_code_image: `${elem.images_data.image_url}`
                                                            }
                                                        });
                                                        setIsSelectDropdownActive(
                                                            false
                                                        );
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                )
                            ) : (
                                <div style={{ textAlign: "center" }}>
                                    No Promo codes available
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}

export default PromoCodeAutocomplete;
