import axios from 'axios';
import { getJwtToken } from './common'
import {PresignedURLResponse} from './types/CustomerAccountManagerTypes';

const SERVER_URL = process.env.REACT_APP_API_URL;
console.log(SERVER_URL)


export const saveYourAccountPageData = async (data: any): Promise<string | null> => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.post(`${SERVER_URL}/customer-account-manager/account-data`, { ...data }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getYourAccountPageData = async () => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/account-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const saveYourOrderPageData = async (data: any): Promise<string | null> => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.post(`${SERVER_URL}/customer-account-manager/order-data`, { ...data }, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getYourOrderPageData = async () => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/order-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const saveGiftCardsPageData = async (data: any): Promise<string | null> => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.post(`${SERVER_URL}/customer-account-manager/gift-cards-data`, { ...data }, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getGiftCardsPageData = async () => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/gift-cards-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const savePromoPageData = async (data: any): Promise<string | null> => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.post(`${SERVER_URL}/customer-account-manager/promo-page-data`, { ...data }, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getPromoPageData = async () => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/promo-page-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}


export const saveRecommendedPageData = async (data: any): Promise<string | null> => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.post(`${SERVER_URL}/customer-account-manager/recommended-page-data`, { ...data }, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getRecommendedPageData = async () => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/recommended-page-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}


export const saveWishlistPageData = async (data: any): Promise<string | null> => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.post(`${SERVER_URL}/customer-account-manager/wishlist-page-data`, { ...data }, {
      headers: {
        Authorization: `Bearer ${token}`,
        
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}
export const getWishlistPageData = async () => {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/wishlist-page-data`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: source.token
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}



export const getPresignURLToUpload = async (filename: string, filetype: string): Promise<PresignedURLResponse> => {
  try {
    const token = await getJwtToken();
    const result = await axios.get(`${SERVER_URL}/customer-account-manager/get-presigned-url-for-post?filename=${filename}&filetype=${filetype}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return {
      uploadURL: "",
      downloadURL: ""
    };
  } catch (err) {
    console.log(err)
    return {
      uploadURL: "",
      downloadURL: ""
    };
  }
}
export const singleImageUpload = async (presignedURL: string, imageFile: File): Promise<string | null> => {
  try {
    const token = await getJwtToken();
    var options = {
      headers: {
        'Content-Type': imageFile.type
      }
    };
    const result = await axios.put(`${presignedURL}`, imageFile, options);
    if (result.status === 201 || result.status === 200) {
      return result.data;
    }
    return null;
  } catch (err) {
    return null;
  }
}