import {
    Card,
    Stack,
    RadioButton,
    hsbToHex,
    hslToRgb,
    RGBColor,
    RGBAColor,
    hsbToRgb
} from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import Preview from "./Preview";
import ColorOptions from "../../components/Common/ColorOptions/ColorOptions";
import { colorProps, iSettingsProps } from "./Index";

function Settings(props: iSettingsProps) {
    console.log(props);

    const changeColor = (updatedColor: any, index: number) => {
        // console.log(updatedColor);
        // console.log(index);

        props.setData({
            colorProperties: [
                ...props.data.colorProperties.slice(0, index),
                {
                    label: props.data.colorProperties[index].label,
                    value: updatedColor,
                    css_name: props.data.colorProperties[index].css_name,
                    hexValue: hsbToHex({
                        brightness: updatedColor.brightness,
                        hue: updatedColor.hue,
                        saturation: updatedColor.saturation
                    }),
                    rgbValue: hsbToRgb({
                        brightness: updatedColor.brightness,
                        hue: updatedColor.hue,
                        saturation: updatedColor.saturation
                    })
                },
                ...props.data.colorProperties.slice(index + 1)
            ],
            isCustomSelected: props.data.isCustomSelected
        });
    };

    const handleChange = useCallback((_checked, newValue) => {
        if (newValue === "default") {
            props.setData({
                colorProperties: props.data.colorProperties,
                isCustomSelected: newValue
            });
        } else {
            props.setData({
                ...props.data,
                isCustomSelected: newValue
            });
        }
    }, []);
    return (
        <div className="design-settings">
            <div className="design-settings__left">
                <Card>
                    <div className="design-options">
                        <Stack vertical>
                            <label className="design-options__label label">
                                Select Colors
                            </label>
                            <RadioButton
                                label="Use Theme Colors"
                                checked={
                                    props.data.isCustomSelected === "default"
                                }
                                id="default"
                                name="colors"
                                onChange={handleChange}
                            />
                            <RadioButton
                                label="Use Custom Colors"
                                id="custom"
                                name="colors"
                                checked={
                                    props.data.isCustomSelected === "custom"
                                }
                                onChange={handleChange}
                            />
                        </Stack>
                        {props.data.isCustomSelected === "custom" ? (
                            <ColorOptions
                                handler={changeColor}
                                data={props.data.colorProperties}
                            ></ColorOptions>
                        ) : (
                            <span></span>
                        )}
                    </div>
                </Card>
            </div>
            <div className="design-settings__right">
                <Preview colors={props.data.colorProperties}></Preview>
            </div>
        </div>
    );
}

export default Settings;
