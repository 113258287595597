import { Heading, Layout, Page } from "@shopify/polaris";
import React, { useState, useContext } from "react";
import OnboardingCard from "../../components/Dashboard/OnboardingCard";
import ReviewCard from "../../components/Dashboard/ReviewCard";
import FAQCard from "../../components/Dashboard/FAQ";
import ScriptEnable from "../../components/Dashboard/ScriptEnable";
import AnalyticsCards from "../../components/Dashboard/AnalyticsCards";
import { UserContext } from "../../Contexts/AppUserContext";

function Index() {
    const { user, updateContextUser } = useContext(UserContext);
    return (
        <div>
            <Page>
                <Layout>
                    <Layout.Section>
                        <p
                            style={{
                                fontWeight: 600,
                                fontSize: "28px",
                                lineHeight: "34px"
                            }}
                        >
                            Hey {user?.fullName}! <br />
                            Welcome to Your Customer Account Dashboard
                        </p>
                        <div style={{ marginTop: "25px" }}>
                            <ScriptEnable />
                            {/* <OnboardingCard
                                data={{ 
                                    complete_status: 70,
                                    steps: [
                                        {
                                            title: "Set up main page",
                                            completed: true,
                                            details:
                                                "Elit minim culpa dolor aliqua ad dolore. Ad Lorem dolore aute consequat laboris fugiat culpa deserunt ad amet aute. "
                                        },
                                        {
                                            title: "Set up other pages & content",
                                            completed: false,
                                            details:
                                                "Elit minim culpa dolor aliqua ad dolore. Ad Lorem dolore aute consequat laboris fugiat culpa deserunt ad amet aute. "
                                        },
                                        {
                                            title: "Finish up your customer account",
                                            completed: false,
                                            details:
                                                "Elit minim culpa dolor aliqua ad dolore. Ad Lorem dolore aute consequat laboris fugiat culpa deserunt ad amet aute. "
                                        }
                                    ]
                                }}
                            /> */}
                            <AnalyticsCards />
                            <ReviewCard />
                            <FAQCard />
                        </div>
                    </Layout.Section>
                </Layout>
            </Page>
        </div>
    );
}

export default Index;
