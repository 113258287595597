import { AppProvider, Button, DisplayText, TextStyle } from '@shopify/polaris'
import React from 'react'
import {useHistory} from 'react-router-dom'
import errorIcon from '../../assets/404Icon.svg'
const ErrorPage = () => {
  const history = useHistory();
    return (
        
        <div style={{flexDirection : 'row', display : 'flex', justifyContent : 'center', alignItems : 'center', 
        paddingTop : '25px', width : '100%', position : 'absolute'}}>
            <div style={{width : '100%', paddingLeft : '10%'}}>
                <div style={{ fontSize : 180, fontWeight : 'bold'}}>
                   404
                </div>
                <div style={{paddingTop : 100}}>
                    <DisplayText size="large">Sorry, you've lost...</DisplayText>
                </div>
                <div style={{display : 'flex', flexDirection : 'row', paddingTop : 20}}>
                    <div style={{color : 'grey'}}>
                        <DisplayText size="small"> Contact us by email </DisplayText>
                    </div>
                    &nbsp; &nbsp;
                    <div style={{color : 'blue' , textDecorationLine : 'underline'}}>
                        <DisplayText size="small">support@email.com</DisplayText>
                    </div>
                </div>
                    <div style={{ paddingTop : 20 }}>
                        <Button onClick={()=>{history.push("/")}}>Back to Dashboard</Button>
                    </div>
            </div>
            {/* Image */}
            <div style={{width : '100%' , justifyContent : 'center', alignItems : 'center', display : 'flex' }}>
                <img src={errorIcon} />
            </div>
        </div>
        
    )
}

export default ErrorPage
