import {
    Button,
    ButtonGroup,
    Card,
    Collapsible,
    Icon,
    TextContainer
} from "@shopify/polaris";
import React, { useState, useCallback, useContext } from "react";
import { ChevronDownMinor, ChevronUpMinor } from "@shopify/polaris-icons";
import { SwitchOffIcon, SwitchOnIcon } from "../../../common/icons";
const CardPage = (props: any) => {
    const { openCard, handleCardOpen, changeEnableDisable } = props;
    const handleButtonActivate = () => {
        changeEnableDisable(!props.isEnabled);
    };

    return (
        <div
            style={{
                paddingTop: "10px",
                position: "relative",
                cursor: "pointer"
            }}
            onClick={(e) => {
                handleCardOpen(!openCard);
            }}
        >
            <Card>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 25,
                        backgroundColor: openCard ? "#FAFBFB" : "white"
                    }}
                >
                    <div
                        style={{
                            flexGrow: 1,
                            flexDirection: "column",
                            paddingLeft: 20
                        }}
                    >
                        <div
                            style={{
                                color: "#222B36",
                                fontSize: "14px",
                                lineHeight: "24px",
                                fontWeight: "bold"
                            }}
                        >
                            {props.heading}
                        </div>
                        <div
                            style={{
                                color: "#6D7175",
                                fontSize: "14px",
                                lineHeight: "20px"
                            }}
                        >
                            {props.subHeading}
                        </div>
                    </div>
                    <div style={{ paddingLeft: 20 }}>
                        <Button
                            plain
                            monochrome
                            ariaExpanded={openCard}
                            ariaControls="basic-collapsible"
                        >
                            {openCard
                                ? ((
                                      <Icon
                                          source={ChevronDownMinor}
                                          accessibilityLabel="upIcon-card"
                                      />
                                  ) as unknown as string)
                                : ((
                                      <Icon
                                          source={ChevronUpMinor}
                                          accessibilityLabel="upIcon-card"
                                      />
                                  ) as unknown as string)}
                        </Button>
                    </div>
                </div>
            </Card>

            <div style={{ width: "100%" }}>
                <Collapsible
                    open={openCard}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out"
                    }}
                    expandOnPrint
                >
                    <div style={{ borderTop: "2px solid #D9D9DA" }}>
                        <Card sectioned>
                            <div
                                style={{
                                    width: "100%",
                                    backgroundColor: "white"
                                }}
                            >
                                {props.CollapisbleComponent}
                            </div>
                        </Card>
                    </div>
                </Collapsible>
            </div>
        </div>
    );
};

export default CardPage;
