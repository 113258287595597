import React, { useEffect } from 'react';
import {
  handleFacebookAuth,
  handleGoogleAuth,
  getShopifyRedirectURLForCustomerAccounts,
} from '../../API';

function Index(props: any) {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const codeQueryParams = urlParams.get('code');
    const stateQueryParams = urlParams.get('state');
    const authType = props.match.params.auth_type;
    const fetchData = async () => {
      if (authType === 'google') {
        const result = await handleGoogleAuth(
          codeQueryParams,
          stateQueryParams
        );
        if (result) {
          const redirectURL = await getShopifyRedirectURLForCustomerAccounts(result.accessToken);
          if (redirectURL) {
            window.location.href = `${redirectURL}`;
          }
        }
      } else if (authType === 'facebook') {
        const result = await handleFacebookAuth(
          codeQueryParams,
          stateQueryParams
        );
        if (result) {
          const redirectURL = await getShopifyRedirectURLForCustomerAccounts(result.accessToken);
          if (redirectURL) {
            window.location.href = `${redirectURL}`;
          }
        }
      } else if (authType === 'amazon') {
      } else if (authType === 'twitter') {
      } else {
        alert('invalid url.');
      }
    };
    fetchData();
    return () => {};
  }, []);
  return <div>Loading</div>;
}

export default Index;
