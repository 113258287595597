import { Card } from "@shopify/polaris";
import SingleOption from "./SingleOption";
function ColorOptions(props: any) {
    const colorChanged = (current: string, index: number) => {
        props.handler(current, index);
    };
    return (
        <div className="design-options__colors">
            {props.data.map((item: any, index: number) => (
                <Card key={index}>
                    <SingleOption
                        colorChanged={(val: any) => {
                            colorChanged(val, index);
                        }}
                        color={item}
                        index={index}
                    ></SingleOption>
                </Card>
            ))}
        </div>
    );
}

export default ColorOptions;
