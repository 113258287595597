import "./settings.scss";

import { Page, } from "@shopify/polaris";

import List from "./List";

function Settings() {
  return (
        <Page
          title="Settings"
          subtitle="View and update your store details">
          <List></List>
        </Page>
  );
}

export default Settings;
