import "./promoSection.scss";
import {
    SkeletonBodyText,
    SkeletonDisplayText,
    Button,
    TextField,
    SkeletonThumbnail
} from "@shopify/polaris";

function PromoSection(props: any) {
    const heading = (
        <h2 className="skeleton__preview-heading promo__preview-heading">
            {props.heading ? (
                <span>
                    {props.heading.map((item: any, index: number) => (
                        <div key={`unique-promo-heading-section-el-${index}`}>
                            {item}
                        </div>
                    ))}
                </span>
            ) : (
                <SkeletonDisplayText />
            )}
        </h2>
    );
    const subheading = (
        <div className="skeleton__preview-subheading promo__preview-subheading">
            {props.subheading ? (
                <span>
                    {props.subheading.map((item: any, index: number) => (
                        <div
                            key={`unique-promo-sub-heading-section-el-${index}`}
                        >
                            {item}
                        </div>
                    ))}
                </span>
            ) : (
                <SkeletonBodyText lines={2} />
            )}
        </div>
    );

    const imageWrapper = props.src ? (
        <img src={props.src} alt="" />
    ) : (
        <SkeletonThumbnail size="medium" />
    );

    const parentClass =
        "promo__preview  skeleton__preview layout-" +
        props.layout +
        " skeleton__preview-" +
        props.device;

    return (
        <div className={parentClass}>
            {props.layout === "none" ? (
                ""
            ) : (
                <div className="promo__preview-image skeleton__preview-image">
                    {imageWrapper}
                </div>
            )}
            <div className="promo__preview-content skeleton__preview-content">
                {heading}
                {subheading}
                <div className="promo__preview-form">
                    <TextField
                        placeholder="PROMOCODE"
                        label=""
                        onChange={() => {}}
                    />
                    <Button>COPY</Button>
                </div>
            </div>
        </div>
    );
}

export default PromoSection;
