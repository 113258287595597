import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import {
    Card,
    Button,
    Modal,
    Stack,
    ChoiceList,
    Banner,
    Spinner
} from "@shopify/polaris";
import {
    enableScriptForStore,
    disableScriptForStore,
    checkScriptEnableStatus
} from "../../API/index";
import { UserContext } from "../../Contexts/AppUserContext";
import Confetti from "react-confetti";

// interface iPropTypes {
//     enabled: boolean;
//     onClickEnable?: any;
// }
function ScriptEnable(props: any) {
    const history = useHistory();
    const { user } = useContext(UserContext);
    const [isModalActive, setIsModalActive] = useState(false);
    const [cardLoading, setCardLoading] = useState(true);
    const [scriptUpdatingLoader, setScriptUpdatingLoader] = useState(false);
    const [isScriptEnabled, setIsScriptEnabled] = useState(false);
    const [showInfoBannerForData, setShowInfoBannerForMultipassData] =
        useState<any>({
            show: false,
            msg: "",
            link: "",
            dsc: ""
        });
    const [dimensionsForConfetti, setDimensionForConfetti] = useState<any>({
        height: 100,
        width: 100
    });

    const getHeightAndWidth = () => {
        const modal = document.getElementsByClassName(
            "Polaris-Modal__BodyWrapper"
        )[0] as HTMLElement;
        console.log(modal);
        var clientHeight = modal?.offsetHeight;
        var clientWidth = modal?.offsetWidth;
        console.log(clientHeight);
        console.log(clientWidth);
        setDimensionForConfetti({
            height: clientHeight,
            width: clientWidth
        });
    };
    useEffect(() => {
        let mounted = true;

        if (mounted && isModalActive) {
            getHeightAndWidth();
        }
        return () => {
            mounted = false;
        };
    }, [isModalActive]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (user) {
                setIsScriptEnabled(user.isScriptEnabled);
                console.log(user);
            }
        }
        return () => {
            mounted = false;
        };
    }, [user]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const getResults = await checkScriptEnableStatus();
                console.log(getResults);
                if (getResults && getResults.incomplete) {
                    setShowInfoBannerForMultipassData({
                        show: getResults.incomplete,
                        link: getResults.link,
                        msg: getResults.msg,
                        dsc: getResults.dsc
                    });
                    setCardLoading(false);
                } else {
                    setShowInfoBannerForMultipassData({
                        show: getResults.incomplete,
                        link: getResults.link,
                        msg: getResults.msg,
                        dsc: getResults.dsc
                    });
                    setCardLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);
    return (
        <>
            <Modal
                open={isModalActive}
                onClose={() => {
                    setIsModalActive(false);
                }}
                title=""
                // primaryAction={{
                //     content: "Back to Dashboard",
                //     onAction: () => {}
                // }}
                // secondaryActions={[
                //     {
                //         content: "Preview Customer Account",
                //         onAction: () => {}
                //     }
                // ]}
            >
                <Confetti
                    height={dimensionsForConfetti.height}
                    width={dimensionsForConfetti.width}
                />
                <div
                    id={"confetti-div-to-show"}
                    style={{
                        textAlign: "center",
                        margin: "55px"
                    }}
                >
                    <p
                        style={{
                            fontWeight: 400,
                            marginTop: "20px",
                            fontSize: "20px",
                            lineHeight: "28px"
                        }}
                    >
                        Beam {isScriptEnabled ? `Enabled` : `Disabled`}
                    </p>
                    <p style={{ color: "#6D7175", marginTop: "15px" }}>
                        Id nisi bibendum aliquet felis condimentum. Morbi quis
                        aliquae.
                    </p>
                    <div
                        style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                            display: "inline-flex"
                        }}
                    >
                        <div>
                            <Button
                                primary
                                onClick={() => {
                                    setIsModalActive(false);
                                }}
                            >
                                Back to Dashboard
                            </Button>
                        </div>
                        <div style={{ marginLeft: "15px" }}>
                            <Button
                                onClick={() => {
                                    window.open(`https://${user?.fullName}`);
                                    setIsModalActive(false);
                                }}
                            >
                                Preview Customer Account
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Card>
                <div style={{ padding: "20px" }}>
                    {!cardLoading && showInfoBannerForData.show && (
                        <>
                            <Banner
                                title={showInfoBannerForData.msg}
                                action={{
                                    content: "Resolve Now",
                                    onAction: () => {
                                        history.push(
                                            `${showInfoBannerForData.link}`
                                        );
                                    }
                                }}
                                secondaryAction={{
                                    content: "Tutorial",
                                    onAction: () => {
                                        window.open("https://youtube.com");
                                    }
                                }}
                                status="info"
                            >
                                <p>{`${showInfoBannerForData.dsc}`}</p>
                            </Banner>
                            <div style={{ marginTop: "10px" }}></div>
                        </>
                    )}

                    {cardLoading ? (
                        <div style={{ textAlign: "center" }}>
                            <Spinner />
                        </div>
                    ) : (
                        <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                                <p
                                    style={{
                                        display: "inline",
                                        fontWeight: "bold"
                                    }}
                                >
                                    <span>Beam is</span>
                                    {isScriptEnabled ? (
                                        <span
                                            style={{
                                                marginLeft: "5px",
                                                color: "#39B54A"
                                            }}
                                        >
                                            enabled
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                marginLeft: "5px",
                                                color: "#D82C0D"
                                            }}
                                        >
                                            disabled
                                        </span>
                                    )}
                                </p>
                                <p>
                                    {" "}
                                    {!isScriptEnabled
                                        ? `Click Enable to add Beam to your store.`
                                        : `Click Disable to remove Beam from your store.`}{" "}
                                </p>
                            </div>
                            <div>
                                {!isScriptEnabled ? (
                                    <Button
                                        primary
                                        loading={scriptUpdatingLoader}
                                        disabled={showInfoBannerForData.show}
                                        onClick={async () => {
                                            setScriptUpdatingLoader(true);
                                            const getResults =
                                                await enableScriptForStore();
                                            console.log(getResults);
                                            if (getResults) {
                                                setIsModalActive(true);
                                                setScriptUpdatingLoader(false);
                                                setIsScriptEnabled(
                                                    (elem) => !elem
                                                );
                                            } else {
                                                alert("some error occurred");
                                                setScriptUpdatingLoader(false);
                                            }
                                        }}
                                    >
                                        Enable
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={async () => {
                                            setScriptUpdatingLoader(true);
                                            const getResults =
                                                await disableScriptForStore();

                                            console.log(getResults);
                                            if (getResults) {
                                                setIsModalActive(true);
                                                setScriptUpdatingLoader(false);
                                                setIsScriptEnabled(
                                                    (elem) => !elem
                                                );
                                            } else {
                                                alert("some error occurred");
                                                setScriptUpdatingLoader(false);
                                            }
                                        }}
                                        disabled={showInfoBannerForData.show}
                                        loading={scriptUpdatingLoader}
                                    >
                                        Disable
                                    </Button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </>
    );
}

export default ScriptEnable;
