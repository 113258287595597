import {
    Icon,
    Button,
    SkeletonThumbnail,
    SkeletonDisplayText,
    SkeletonBodyText
} from "@shopify/polaris";

import { HeartMajor } from "@shopify/polaris-icons";
import React from "react";
import "./wishlistProduct.scss";
function WishlistProduct(props: any) {
    const parentClass =
        "skeleton__preview wishlist-product_preview wishlist-product_preview-style" +
        props.style +
        " wishlist-product_preview-" +
        props.device;
    let button = (
        <Button icon={<Icon source={HeartMajor} color={props.color} />}>
            {props.count ? props.count : ""}
        </Button>
    );
    switch (props.style) {
        case 1:
            button = (
                <Button icon={<Icon source={HeartMajor} color={props.color} />}>
                    {props.label}
                    {props.count ? props.count : ""}
                </Button>
            );
            break;
        case 2:
            button = (
                <Button>
                    {props.label}
                    {props.count ? props.count : ""}
                </Button>
            );
            break;
        default:
            button = (
                <Button icon={<Icon source={HeartMajor} color={props.color} />}>
                    {props.count ? props.count : ""}
                </Button>
            );
    }

    return (
        <div className={parentClass}>
            <div className="skeleton__preview-image wishlist-product_preview-image">
                <SkeletonThumbnail size="medium" />
            </div>
            <div className="skeleton__preview-content wishlist-product_preview-content">
                <h2 className="skeleton__preview-heading  wishlist-product_preview-heading">
                    PRODUCT TITLE
                </h2>
                <div className="skeleton__preview-subheading wishlist-product_preview-subheading">
                    $9,99
                </div>
                <div className="skeleton__preview-description wishlist-product_preview-description">
                    <SkeletonBodyText />
                </div>
                <div
                    style={
                        {
                            "--p-action-primary": props.btnColor
                        } as React.CSSProperties
                    }
                    className="wishlist-product_preview-buttons"
                >
                    <Button primary>Add To Cart</Button>
                    <div
                        className="wishlist_button"
                        style={
                            {
                                "--p-icon": props.color,
                                "--p-surface": props.backgroundColor,
                                backgroundColor: "var(--p-surface)",
                                color: props.color
                            } as React.CSSProperties
                        }
                    >
                        {button}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WishlistProduct;
