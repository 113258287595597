import {
    TextStyle,
    Card,
    RangeSlider,
    Stack,
    RadioButton,
    Heading,
    ButtonGroup,
    Form,
    Button,
    Layout,
    Popover,
    TextField,
    Modal,
    ResourceList,
    Icon
} from "@shopify/polaris";
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { Banner } from "@shopify/polaris";
import { MobileVerticalDotsMajor } from "@shopify/polaris-icons";
import { PlusMinor, ImagesMajor } from "@shopify/polaris-icons";
import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import RecommendedItemsPreview from "./Preview/index";
import PopOverComponent from "./PopOverComponentCollection";
import * as _ from "lodash";
import {
    getCollectionList,
    getRecommendedPageData,
    saveRecommendedPageData
} from "../../API/index";
import { GlobalContext } from "../../Contexts/GlobalContext";
import eventBus from "../../common/EventBus";
import "./index.scss";

const DotIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clip-rule="evenodd"
                d="M7 2C5.896 2 5 2.896 5 4C5 5.104 5.896 6 7 6C8.104 6 9 5.104 9 4C9 2.896 8.104 2 7 2ZM7 8C5.896 8 5 8.896 5 10C5 11.104 5.896 12 7 12C8.104 12 9 11.104 9 10C9 8.896 8.104 8 7 8ZM7 14C5.896 14 5 14.896 5 16C5 17.104 5.896 18 7 18C8.104 18 9 17.104 9 16C9 14.896 8.104 14 7 14ZM13 6C14.104 6 15 5.104 15 4C15 2.896 14.104 2 13 2C11.896 2 11 2.896 11 4C11 5.104 11.896 6 13 6ZM13 8C11.896 8 11 8.896 11 10C11 11.104 11.896 12 13 12C14.104 12 15 11.104 15 10C15 8.896 14.104 8 13 8ZM13 14C11.896 14 11 14.896 11 16C11 17.104 11.896 18 13 18C14.104 18 15 17.104 15 16C15 14.896 14.104 14 13 14Z"
                fill="#111213"
                fillOpacity="0.5"
            />
        </svg>
    );
};
const AddIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 3H4V1H2.5C1.67157 1 1 1.67157 1 2.5V4H3V3Z"
                fill="#235EAA"
            />
            <path d="M6 3H9V1H6V3Z" fill="#235EAA" />
            <path d="M11 3L14 3V1H11V3Z" fill="#235EAA" />
            <path d="M9 19H6V17H9V19Z" fill="#235EAA" />
            <path d="M11 19H14V17H11V19Z" fill="#235EAA" />
            <path
                d="M17 4V3L16 3V1H17.5C18.3284 1 19 1.67157 19 2.5V4H17Z"
                fill="#235EAA"
            />
            <path
                d="M3 17V16H1V17.5C1 18.3284 1.67157 19 2.5 19H4V17H3Z"
                fill="#235EAA"
            />
            <path
                d="M16 17H17V16H19V17.5C19 18.3284 18.3284 19 17.5 19H16V17Z"
                fill="#235EAA"
            />
            <path
                d="M10 6C10.5523 6 11 6.44772 11 7V9H13C13.5523 9 14 9.44771 14 10C14 10.5523 13.5523 11 13 11H11V13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13V11H7C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9H9V7C9 6.44772 9.44771 6 10 6Z"
                fill="#235EAA"
            />
            <path d="M1 9V6H3L3 9H1Z" fill="#235EAA" />
            <path d="M1 11V14H3L3 11H1Z" fill="#235EAA" />
            <path d="M17 9V6H19V9H17Z" fill="#235EAA" />
            <path d="M17 11V14H19V11H17Z" fill="#235EAA" />
        </svg>
    );
};

const ExternalAddIcon = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.00001 5.00001C8.44772 5.00001 8.00001 5.44773 8.00001 6.00002C8.00001 6.5523 8.44773 7.00001 9.00002 7.00001L11.5858 7L8.29289 10.2929C7.90237 10.6834 7.90237 11.3166 8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071L13 8.4142L13 11C13 11.5523 13.4477 12 14 12C14.5523 12 15 11.5523 15 11L15 5.99999C15 5.97501 14.9991 5.95013 14.9972 5.92539C14.9794 5.68746 14.877 5.46276 14.7071 5.29288C14.6967 5.28241 14.686 5.2722 14.6751 5.26225C14.5011 5.10281 14.2844 5.01607 14.0639 5.00203C14.0427 5.00068 14.0214 4.99999 14 4.99999L9.00001 5.00001Z"
                fill="#235EAA"
            />
            <path
                d="M1 14.5C1 13.6716 1.67157 13 2.5 13H5.5C6.32843 13 7 13.6716 7 14.5V17.5C7 18.3284 6.32843 19 5.5 19H2.5C1.67157 19 1 18.3284 1 17.5V14.5Z"
                fill="#235EAA"
            />
            <path
                d="M2.5 1C1.67157 1 1 1.67157 1 2.5V11H3V3H17V17H9V19H17.5C18.3284 19 19 18.3284 19 17.5V2.5C19 1.67157 18.3284 1 17.5 1H2.5Z"
                fill="#235EAA"
            />
        </svg>
    );
};
const RecommendedItems = () => {
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const history = useHistory();
    const [formData, setFormData] = useState<any>({
        products_per_row_desktop: 2,
        products_per_row_mobile: 2,
        choosen_style: "slider",
        collections: [],
        isEnabled: false
    });
    const [initialData, setInitialData] = useState<any>({
        products_per_row_desktop: 2,
        products_per_row_mobile: 2,
        choosen_style: "slider",
        collections: [],
        isEnabled: false
    });
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedIndexForEdit, setSelectedIndexForEdit] = useState(-1);
    const [modalForm, setModalForm] = useState({
        section_heading: "",
        selected_collection: {
            id: "",
            title: "",
            handle: ""
        },
        notes: ""
    });
    const [collectionList, setCollectionList] = useState([]);
    const [collectionLoading, setCollectionLoading] = useState(true);

    const [submitForm, setSubmitForm] = useState(false);
    const [discardForm, setDiscardForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);

    const handleDiscard = async () => {
        console.log("discard promo page");
        console.log(initialData);
        setDiscardForm(true);
    };
    const saveData = async () => {
        console.log("Calling save data....");
        setSubmitForm(false);
        updateDataSubmitting(true);
        const result = await saveRecommendedPageData(formData);
        if (result) {
            updateDataSubmitting(false);
            const tempData = {
                ...formData
            };
            setInitialData({
                ...tempData
            });
            setFormData({
                ...tempData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
    };
    useEffect(() => {
        console.log("mounted event");
        eventBus.on("BAH:discardRecommendedForm", (data: any) => {
            handleDiscard();
        });
        return () => {
            console.log(
                "unmounted event listener: discard current form in recommended cards"
            );
            eventBus.remove("BAH:discardRecommendedForm");
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:saveRecommendedForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            console.log("unmounted event");
            eventBus.remove("BAH:saveRecommendedForm");
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted && submitForm) {
            console.log("save data");
            saveData();
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);

    useEffect(() => {
        let mounted = true;

        if (mounted && discardForm) {
            setFormData(initialData);
            // const contentBlock = htmlToDraft(initialData.empty_state_text);
            // if (contentBlock) {
            //     const contentState = ContentState.createFromBlockArray(
            //         contentBlock.contentBlocks
            //     );
            //     const editorState = EditorState.createWithContent(contentState);
            //     setEditorState(editorState);
            // }
            setDiscardForm(false);
        }
        return () => {
            mounted = false;
        };
    }, [discardForm]);
    useEffect(() => {
        console.log("initial data use effect running");
        let mounted = true;
        if (mounted) {
            if (!dataLoading) {
                console.log("initial data updated");
                console.log(initialData);
                console.log(`isEqual-iD = ${_.isEqual(initialData, formData)}`);
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [initialData]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log(`formData updated`);
            console.log(formData);
            if (!dataLoading) {
                console.log(`isEqualfD = ${_.isEqual(initialData, formData)}`);
                console.log("form data");
                console.log(initialData);
                console.log(formData);
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [formData]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getRecommendedPageData();
                if (result) {
                    console.log(result);
                    setInitialData({ ...result });
                    setFormData({ ...result });
                    setDataLoading(false);
                } else {
                    setDataLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getCollectionList();
                console.log(result);
                if (result) {
                    setCollectionList(result);
                    setCollectionLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <PageWrapper
            title="Recommended items"
            subtitle="You’re able to add subheading, choose collection and how many products will be displayed per row."
            separator={true}
            showSwitchButton={true}
            loading={dataLoading}
            isDisabled={!didValueChange}
            SwitchVal={formData.isEnabled}
            onChangeSwitchVal={() => {
                setFormData({
                    ...formData,
                    isEnabled: !formData.isEnabled
                });
            }}
            id="gift-cards-page"
        >
            <Modal
                large
                open={openModal}
                onClose={() => {
                    setOpenModal(false);
                }}
                title="Select Colllection"
                primaryAction={{
                    content: isEdit ? "Edit & Save" : "Add",
                    onAction: () => {
                        console.log(isEdit);
                        console.log(selectedIndexForEdit);

                        if (!isEdit) {
                            let tempCollection = formData.collections;
                            setFormData({
                                ...formData,
                                collections: [
                                    { ...modalForm },
                                    ...tempCollection
                                ]
                            });
                            setOpenModal(false);
                        } else {
                            if (selectedIndexForEdit >= 0) {
                                let tempCollection = formData.collections;
                                let tempItem =
                                    tempCollection[selectedIndexForEdit];
                                tempItem = { ...modalForm };
                                tempCollection[selectedIndexForEdit] = tempItem;
                                console.log(tempCollection);
                                setFormData({
                                    ...formData,
                                    collections: [...tempCollection]
                                });
                                setOpenModal(false);
                            }
                        }
                    }
                }}
                secondaryActions={[
                    {
                        content: "Cancel",
                        onAction: () => {
                            setModalForm({
                                section_heading: "",
                                selected_collection: {
                                    id: "",
                                    title: "",
                                    handle: ""
                                },
                                notes: ""
                            });
                            setOpenModal(false);
                        }
                    }
                ]}
            >
                <Modal.Section>
                    <div>
                        <Stack vertical>
                            <Stack.Item fill>
                                <TextField
                                    placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec imperdiet leo nec nulla sodales rhoncus."
                                    value={modalForm.section_heading}
                                    onChange={(val: any) => {
                                        setModalForm({
                                            ...modalForm,
                                            section_heading: val
                                        });
                                    }}
                                    label={
                                        <TextStyle variation="strong">
                                            Section Heading
                                        </TextStyle>
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item fill>
                                <div>
                                    <PopOverComponent
                                        data={collectionList}
                                        collectionLoading={collectionLoading}
                                        onClearButton={() => {}}
                                        selectedCollection={
                                            modalForm.selected_collection
                                        }
                                        onCollectionSelection={(
                                            selected_data: any
                                        ) => {
                                            setModalForm({
                                                ...modalForm,
                                                selected_collection:
                                                    selected_data
                                            });
                                        }}
                                    />
                                </div>
                            </Stack.Item>
                            <Stack.Item fill>
                                <div>
                                    <TextField
                                        multiline={4}
                                        value={modalForm.notes}
                                        onChange={(val: any) => {
                                            setModalForm({
                                                ...modalForm,
                                                notes: val
                                            });
                                        }}
                                        label="Notes (Optional)"
                                        placeholder={`Fall ${new Date().getFullYear()} Collection`}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>
                    </div>
                </Modal.Section>
            </Modal>

            {showSuccessMessage && (
                <Banner
                    title="Saved your recommended page data"
                    status="success"
                    action={{
                        content: "Go To Dashboard",
                        onAction: () => {
                            history.push("/");
                        }
                    }}
                    onDismiss={() => {
                        setShowSuccessMessage(false);
                    }}
                />
            )}
            {showErrorMessage && (
                <Banner
                    title="Something went wrong while saving."
                    status="critical"
                    action={{
                        content: "Go To Dashboard",
                        onAction: () => {
                            history.push("/");
                        }
                    }}
                    onDismiss={() => {
                        setShowErrorMessage(false);
                    }}
                />
            )}

            <div style={{ marginBottom: "10px" }}></div>
            <Layout>
                <Layout.Section oneHalf>
                    <div>
                        <div>
                            <Card sectioned>
                                <div>
                                    <Stack vertical>
                                        <TextStyle variation="strong">
                                            Products per row on desktop
                                        </TextStyle>
                                        <RangeSlider
                                            label=""
                                            output
                                            min={0}
                                            max={8}
                                            value={
                                                formData.products_per_row_desktop
                                            }
                                            onChange={(val: any) => {
                                                setFormData({
                                                    ...formData,
                                                    products_per_row_desktop:
                                                        val
                                                });
                                            }}
                                            prefix={<p>1</p>}
                                            suffix={
                                                <p>
                                                    {
                                                        formData.products_per_row_desktop
                                                    }
                                                </p>
                                            }
                                        />

                                        <TextStyle variation="strong">
                                            Products per row on mobile
                                        </TextStyle>
                                        <RangeSlider
                                            label=""
                                            output
                                            min={0}
                                            max={8}
                                            value={
                                                formData.products_per_row_mobile
                                            }
                                            onChange={(val: any) => {
                                                setFormData({
                                                    ...formData,
                                                    products_per_row_mobile: val
                                                });
                                            }}
                                            prefix={<p>1</p>}
                                            suffix={
                                                <p>
                                                    {
                                                        formData.products_per_row_mobile
                                                    }
                                                </p>
                                            }
                                        />
                                    </Stack>
                                </div>

                                <div style={{ marginTop: "15px" }}>
                                    <Stack vertical>
                                        <TextStyle variation="strong">
                                            Choose Style
                                        </TextStyle>
                                        <div
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    choosen_style: "slider"
                                                });
                                            }}
                                        >
                                            <RadioButton
                                                label="Slider"
                                                checked={
                                                    formData.choosen_style ===
                                                    "slider"
                                                }
                                                id="Slider"
                                                name="style"
                                            />
                                        </div>
                                        <div
                                            onClick={() => {
                                                setFormData({
                                                    ...formData,
                                                    choosen_style: "grid"
                                                });
                                            }}
                                        >
                                            {" "}
                                            <RadioButton
                                                label="Grid"
                                                id="Grid"
                                                name="style"
                                                checked={
                                                    formData.choosen_style ===
                                                    "grid"
                                                }
                                            />
                                        </div>
                                    </Stack>
                                </div>

                                <div
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                >
                                    <Heading>COLLECTIONS</Heading>
                                </div>
                                {formData.collections &&
                                formData.collections.length > 0 ? (
                                    <>
                                        {formData.collections.map(
                                            (elem: any, index: number) => {
                                                console.log(elem);
                                                return (
                                                    <div
                                                        key={`collection-index-${index}`}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "row",
                                                            alignItems:
                                                                "center",
                                                            padding: 25,
                                                            border: "#eee 2px solid",
                                                            borderRadius: "3px",
                                                            backgroundColor:
                                                                "white"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingLeft: 10,
                                                                cursor: "grab"
                                                            }}
                                                        >
                                                            <DotIcon />
                                                        </div>
                                                        <div
                                                            style={{
                                                                flexDirection:
                                                                    "column",
                                                                paddingLeft: 20,
                                                                flexGrow: 1
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    color: "#222B36",
                                                                    fontSize:
                                                                        "14px",
                                                                    lineHeight:
                                                                        "24px",
                                                                    fontWeight:
                                                                        "bold"
                                                                }}
                                                            >
                                                                {
                                                                    elem.section_heading
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#6D7175",
                                                                    fontSize:
                                                                        "14px",
                                                                    lineHeight:
                                                                        "20px"
                                                                }}
                                                            >
                                                                {
                                                                    elem
                                                                        .selected_collection
                                                                        .title
                                                                }
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                onClick={() => {
                                                                    setModalForm(
                                                                        {
                                                                            section_heading:
                                                                                formData
                                                                                    .collections[
                                                                                    index
                                                                                ][
                                                                                    `section_heading`
                                                                                ],
                                                                            selected_collection:
                                                                                formData
                                                                                    .collections[
                                                                                    index
                                                                                ][
                                                                                    `selected_collection`
                                                                                ],
                                                                            notes: formData
                                                                                .collections[
                                                                                index
                                                                            ][
                                                                                `notes`
                                                                            ]
                                                                        }
                                                                    );
                                                                    setIsEdit(
                                                                        true
                                                                    );
                                                                    setSelectedIndexForEdit(
                                                                        index
                                                                    );
                                                                    setOpenModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    "10px"
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() => {
                                                                    let tempCollection =
                                                                        formData.collections;
                                                                    let removeItems =
                                                                        tempCollection.filter(
                                                                            (
                                                                                child_elem: any,
                                                                                child_index: number
                                                                            ) => {
                                                                                return (
                                                                                    index !==
                                                                                    child_index
                                                                                );
                                                                            }
                                                                        );
                                                                    setFormData(
                                                                        {
                                                                            ...formData,
                                                                            collections:
                                                                                removeItems
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        padding: 25,
                                        border: "#eee 2px solid",
                                        borderRadius: "3px",
                                        backgroundColor: "white",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        setModalForm({
                                            section_heading: "",
                                            selected_collection: {
                                                id: "",
                                                title: "",
                                                handle: ""
                                            },
                                            notes: ""
                                        });
                                        setIsEdit(false);
                                        setOpenModal(true);
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: 10
                                        }}
                                    >
                                        <AddIcon />
                                    </div>
                                    <div
                                        style={{
                                            flexDirection: "column",
                                            paddingLeft: 20,
                                            flexGrow: 1
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#235EAA",
                                                fontSize: "14px",
                                                lineHeight: "24px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            Add Collection
                                        </div>
                                        <div
                                            style={{
                                                color: "#6D7175",
                                                fontSize: "14px",
                                                lineHeight: "20px"
                                            }}
                                        ></div>
                                    </div>
                                    <div>
                                        <ExternalAddIcon />
                                    </div>
                                </div>

                                <div
                                    style={{
                                        marginTop: 40,
                                        marginBottom: 10
                                    }}
                                >
                                    <ButtonGroup>
                                        <Button
                                            size="medium"
                                            primary
                                            loading={dataSubmitting}
                                            disabled={!didValueChange}
                                            onClick={() => {
                                                saveData();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <RecommendedItemsPreview />
                </Layout.Section>
            </Layout>
        </PageWrapper>
    );
};

export default RecommendedItems;
