import { ColorPicker, Popover, Button, hsbToHex } from "@shopify/polaris";
import { PaintBrushMajor } from "@shopify/polaris-icons";
import { useState, useCallback } from "react";
import "./style.scss";
function SingleOption(props: any) {
    const [popoverActive, setPopoverActive] = useState(false);

    const togglePopoverActive = useCallback(
        () => setPopoverActive((popoverActive) => !popoverActive),
        []
    );

    const activator = (
        <div className="design-settings__button">
            <div className="label">
                <span
                    onClick={togglePopoverActive}
                    style={{
                        background: `${hsbToHex(props.color.value)}`
                    }}
                    className="selected-color"
                ></span>
                <h3>{props.color.label}</h3>
            </div>
            <Button onClick={togglePopoverActive} icon={PaintBrushMajor}>
                Edit
            </Button>
        </div>
    );

    return (
        <div>
            <Popover
                active={popoverActive}
                activator={activator}
                onClose={togglePopoverActive}
                ariaHaspopup={false}
            >
                <ColorPicker
                    onChange={(current) => {
                        props.colorChanged(current);
                    }}
                    color={props.color.value}
                ></ColorPicker>
            </Popover>
        </div>
    );
}

export default SingleOption;
