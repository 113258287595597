import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { Banner } from "@shopify/polaris";
import MenuPage from "./components/MenuPage";
import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import * as _ from "lodash";
import {
    HSBColor,
    HSBLAColor,
    hsbToRgb,
    rgbToHsb,
    rgbToHex
} from "@shopify/polaris";
import { GlobalContext } from "../../Contexts/GlobalContext";
import eventBus from "../../common/EventBus";
import { getWishlistPageData, saveWishlistPageData } from "../../API";
function Index(props: any) {
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const history = useHistory();
    const [formData, setFormData] = useState<any>({
        isEnabled: false,
        header_appearance: {
            selected_style: "floating",
            choose_placement_point: "bottom",
            wishlist_module_name: "",
            display_number_of_items: false,
            floating_button_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            }
        },
        button_options: {
            selected_style: "dg_1",
            label_before_adding: "",
            label_after_adding: "",
            bottom_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            },
            text_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            }
        },
        product_options: {
            placement_pos: "top-left",
            label_before_adding: "",
            label_after_adding: "",
            bottom_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            }
        }
    });
    const [initialData, setInitialData] = useState<any>({
        isEnabled: false,
        header_appearance: {
            selected_style: "floating",
            choose_placement_point: "bottom",
            wishlist_module_name: "",
            display_number_of_items: false,
            floating_button_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            }
        },
        button_options: {
            selected_style: "dg_1",
            label_before_adding: "",
            label_after_adding: "",
            bottom_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            },
            text_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            }
        },
        product_options: {
            placement_pos: "top-left",
            label_before_adding: "",
            label_after_adding: "",
            bottom_color: {
                hsb: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                },
                rgb: {
                    r: 0,
                    g: 0,
                    b: 0
                },
                hex: "#FFFFF"
            }
        }
    });
    let colorPropertiesForFloatingButton = [
        [
            {
                label: "Button Color For Header",
                value: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                }
            }
        ]
    ];
    let colorPropertiesForProductCard = [
        [
            {
                label: "Color For Product Card",
                value: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                }
            }
        ]
    ];
    let colorPropertiesForButtonOptionsBG = [
        [
            {
                label: "Background Color",
                value: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                }
            }
        ]
    ];
    let colorPropertiesForButtonOptionsText = [
        [
            {
                label: "Text Color",
                value: {
                    hue: 1,
                    saturation: 0,
                    brightness: 1
                }
            }
        ]
    ];
    const [colorDataForHeader, setColorDataForHeader] = useState(
        colorPropertiesForFloatingButton[0]
    );
    const [colorDataForButtonOptionBG, setColorDataForButtonOptionBG] =
        useState(colorPropertiesForButtonOptionsBG[0]);
    const [colorDataForButtonOptionText, setColorDataForButtonOptionText] =
        useState(colorPropertiesForButtonOptionsText[0]);
    const [colorDataForProductCard, setColorDataForProductCard] = useState(
        colorPropertiesForProductCard[0]
    );

    const changeColor = (
        updatedColor: HSBColor,
        type: string,
        index: number
    ) => {
        if (type === "header") {
            setColorDataForHeader([
                ...colorDataForHeader.slice(0, index),
                {
                    label: colorDataForHeader[index].label,
                    value: updatedColor
                },
                ...colorDataForHeader.slice(index + 1)
            ]);
            setFormData({
                ...formData,
                header_appearance: {
                    ...formData.header_appearance,
                    floating_button_color: {
                        hsb: updatedColor,
                        rgb: hsbToRgb(updatedColor),
                        hex: rgbToHex(hsbToRgb(updatedColor))
                    }
                }
            });
        } else if (type === "product") {
            setColorDataForProductCard([
                ...colorDataForProductCard.slice(0, index),
                {
                    label: colorDataForProductCard[index].label,
                    value: updatedColor
                },
                ...colorDataForProductCard.slice(index + 1)
            ]);
            setFormData({
                ...formData,
                product_options: {
                    ...formData.product_options,
                    bottom_color: {
                        hsb: updatedColor,
                        rgb: hsbToRgb(updatedColor),
                        hex: rgbToHex(hsbToRgb(updatedColor))
                    }
                }
            });
        } else if (type === "button_option_color_bg") {
            setColorDataForButtonOptionBG([
                ...colorDataForButtonOptionBG.slice(0, index),
                {
                    label: colorDataForButtonOptionBG[index].label,
                    value: updatedColor
                },
                ...colorDataForButtonOptionBG.slice(index + 1)
            ]);
            setFormData({
                ...formData,
                button_options: {
                    ...formData.button_options,
                    bottom_color: {
                        hsb: updatedColor,
                        rgb: hsbToRgb(updatedColor),
                        hex: rgbToHex(hsbToRgb(updatedColor))
                    }
                }
            });
        } else if (type === "button_option_text_color") {
            setColorDataForButtonOptionText([
                ...colorDataForButtonOptionText.slice(0, index),
                {
                    label: colorDataForButtonOptionText[index].label,
                    value: updatedColor
                },
                ...colorDataForButtonOptionText.slice(index + 1)
            ]);
            setFormData({
                ...formData,
                button_options: {
                    ...formData.button_options,
                    text_color: {
                        hsb: updatedColor,
                        rgb: hsbToRgb(updatedColor),
                        hex: rgbToHex(hsbToRgb(updatedColor))
                    }
                }
            });
        }
    };

    useEffect(() => {
        console.log(formData);
    }, [formData]);

    const [submitForm, setSubmitForm] = useState(false);
    const [discardForm, setDiscardForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);

    const handleDiscard = async () => {
        console.log("discard promo page");
        console.log(initialData);
        setDiscardForm(true);
    };
    const saveData = async () => {
        console.log("Calling save data....");
        setSubmitForm(false);
        updateDataSubmitting(true);
        const result = await saveWishlistPageData(formData);
        if (result) {
            updateDataSubmitting(false);
            const tempData = {
                ...formData
            };
            setInitialData({
                ...tempData
            });
            setFormData({
                ...tempData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
    };
    useEffect(() => {
        console.log("mounted event");
        eventBus.on("BAH:discardWishlistForm", (data: any) => {
            handleDiscard();
        });
        return () => {
            console.log(
                "unmounted event listener: discard current form in wishlist cards"
            );
            eventBus.remove("BAH:discardWishlistForm");
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:saveWishlistForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            console.log("unmounted event");
            eventBus.remove("BAH:saveWishlistForm");
        };
    }, []);

    useEffect(() => {
        let mounted = true;

        if (mounted && submitForm) {
            console.log("save data");
            saveData();
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);

    useEffect(() => {
        let mounted = true;

        if (mounted && discardForm) {
            setFormData(initialData);
            // const contentBlock = htmlToDraft(initialData.empty_state_text);
            // if (contentBlock) {
            //     const contentState = ContentState.createFromBlockArray(
            //         contentBlock.contentBlocks
            //     );
            //     const editorState = EditorState.createWithContent(contentState);
            //     setEditorState(editorState);
            // }
            setDiscardForm(false);
        }
        return () => {
            mounted = false;
        };
    }, [discardForm]);
    useEffect(() => {
        console.log("initial data use effect running");
        let mounted = true;
        if (mounted) {
            if (!dataLoading) {
                console.log("initial data updated");
                console.log(initialData);
                console.log(`isEqual-iD = ${_.isEqual(initialData, formData)}`);
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [initialData]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log(`formData updated`);
            console.log(formData);
            if (!dataLoading) {
                console.log(`isEqualfD = ${_.isEqual(initialData, formData)}`);
                console.log("form data");
                console.log(initialData);
                console.log(formData);
                updateValueChange(!_.isEqual(initialData, formData));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [formData]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getWishlistPageData();
                if (result) {
                    console.log(result);
                    setInitialData({ ...result });
                    setFormData({ ...result });
                    setDataLoading(false);
                } else {
                    setDataLoading(false);
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <>
            {" "}
            <PageWrapper
                title="Wishlist"
                subtitle="Edit your wishlist preferences"
                separator={true}
                showSwitchButton={true}
                loading={dataLoading}
                isDisabled={!didValueChange}
                SwitchVal={formData.isEnabled}
                onChangeSwitchVal={() => {
                    setFormData({
                        ...formData,
                        isEnabled: !formData.isEnabled
                    });
                }}
                id="promo-codes-page"
            >
                {/* <Settings componentChanged={componentChanged} /> */}
                {showSuccessMessage && (
                    <Banner
                        title="Saved your account data"
                        status="success"
                        action={{
                            content: "Go To Dashboard",
                            onAction: () => {
                                history.push("/");
                            }
                        }}
                        onDismiss={() => {
                            setShowSuccessMessage(false);
                        }}
                    />
                )}
                {showErrorMessage && (
                    <Banner
                        title="Something went wrong while saving."
                        status="critical"
                        action={{
                            content: "Go To Dashboard",
                            onAction: () => {
                                history.push("/");
                            }
                        }}
                        onDismiss={() => {
                            setShowErrorMessage(false);
                        }}
                    />
                )}

                <div style={{ marginBottom: "10px" }}></div>
                <MenuPage
                    data={formData}
                    isDataSubmitting={dataSubmitting}
                    onDataChange={(data: any) => {
                        setFormData({ ...data });
                    }}
                    onChangeColor={changeColor}
                    isDisabled={!didValueChange}
                    colorDataForHeader={colorDataForHeader}
                    colorDataForButtonOptionBG={colorDataForButtonOptionBG}
                    colorDataForButtonOptionText={colorDataForButtonOptionText}
                    colorDataForProductCard={colorDataForProductCard}
                />
            </PageWrapper>
        </>
    );
}

export default Index;
