import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    Page,
    Card,
    TextField,
    Button,
    Banner,
    Spinner,
    Select,
    Layout,
    SkeletonBodyText,
    SkeletonDisplayText,
    SkeletonPage,
    TextContainer
} from "@shopify/polaris";
import { saveGeneralSettings, getGeneralSettings } from "../../../API";
import { listOfTimezones } from "./timezone";
interface iFormData {
    store_details: {
        store_title: string;
        store_url: string;
        timezone: string;
    };
    customer_notifications: {
        sender_name: string;
        reply_to_email: string;
    };
    billing_details: {
        billing_email: string;
    };
}
function GeneralSettings(props: any) {
    const history = useHistory();
    const formattedOptions = listOfTimezones.map((elem, index) => {
        return {
            ...elem,
            value: elem.name,
            label: elem.name
        };
    });
    const [formData, setFormData] = useState<iFormData>({
        store_details: {
            store_title: "",
            store_url: "",
            timezone: ""
        },
        customer_notifications: {
            sender_name: "",
            reply_to_email: ""
        },
        billing_details: {
            billing_email: ""
        }
    });
    const [multipassToken, setMultipassToken] = useState("");
    const [showBanner, setShowBanner] = useState(false);
    const [dataSaving, setDataSaving] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [bannerData, setBannerData] = useState<any>({
        type: "success",
        title: "Saved successfully !",
        button_title: "Go To Dashboard",
        link: "/"
    });
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            const fetchData = async () => {
                const result = await getGeneralSettings();
                if (result) {
                    setFormData({
                        ...result["settings"]
                    });

                    setMultipassToken(result["multipass_token"]);
                    setInitialLoading(false);
                }
            };
            fetchData();
        }

        return () => {
            mounted = false;
        };
    }, []);
    const saveForm = async () => {
        setDataSaving(true);
        const result = await saveGeneralSettings(formData, multipassToken);
        if (result) {
            setBannerData({
                type: "success",
                title: "Saved successfully !",
                button_title: "Go To Dashboard",
                link: "/"
            });
            setShowBanner(true);
            setDataSaving(false);
        } else {
            setBannerData({
                type: "critical",
                title: "Saved successfully !",
                button_title: "Go To Dashboard",
                link: "/"
            });
            setShowBanner(true);
            setDataSaving(false);
        }
    };
    if (initialLoading) {
        return (
            <Page title="General" subtitle="View and update your store details">
                <div
                    style={{
                        border: "1px solid #E5E7E9",
                        marginTop: "20px",
                        marginBottom: "20px"
                    }}
                ></div>
                <Layout>
                    <Layout.Section>
                        <div style={{ marginTop: "20px" }}></div>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="medium" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <div style={{ marginTop: "20px" }}></div>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <div style={{ marginTop: "20px" }}></div>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <div style={{ marginTop: "20px" }}></div>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <SkeletonBodyText lines={1} />
                    </Layout.Section>
                </Layout>
            </Page>
            // <div style={{ textAlign: "center", marginTop: "15px" }}>
            //     <Spinner />
            // </div>
        );
    }
    return (
        <Page title="General" subtitle="View and update your store details">
            <div>
                {showBanner && (
                    <>
                        <Banner
                            title={bannerData.title}
                            status={bannerData.type}
                            action={{
                                content: bannerData.button_title,
                                onAction: () => {
                                    history.push(`${bannerData.link}`);
                                }
                            }}
                        />
                        <div style={{ marginTop: "15px" }}></div>
                    </>
                )}

                <Card>
                    <div style={{ padding: "30px" }}>
                        <p
                            style={{
                                fontSize: "13px",
                                fontStyle: "normal",
                                color: "#000000",
                                fontWeight: 600
                            }}
                        >
                            STORE DETAILS
                        </p>
                        <div style={{ display: "flex", marginTop: "25px" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    label="Store Title"
                                    value={formData.store_details.store_title}
                                    onChange={(val: any) => {
                                        setFormData({
                                            ...formData,
                                            store_details: {
                                                ...formData.store_details,
                                                store_title: val
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: "25px" }}>
                                <TextField
                                    label="Store URL"
                                    value={formData.store_details.store_url}
                                    onChange={(val: any) => {
                                        setFormData({
                                            ...formData,
                                            store_details: {
                                                ...formData.store_details,
                                                store_url: val
                                            }
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        {/* <div style={{ display: "flex", marginTop: "30px" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    label="Store currency"
                                    value=""
                                    onChange={() => {}}
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: "25px" }}>
                                <TextField
                                    label="Currency display type"
                                    value=""
                                    onChange={() => {}}
                                />
                            </div>
                        </div> */}
                        <div style={{ display: "flex", marginTop: "30px" }}>
                            <div style={{ width: "50%" }}>
                                <Select
                                    label="Timezone"
                                    value={formData.store_details.timezone}
                                    options={formattedOptions}
                                    onChange={(val: any) => {
                                        console.log(val);
                                        setFormData({
                                            ...formData,
                                            store_details: {
                                                ...formData.store_details,
                                                timezone: val
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div
                                style={{ width: "50%", marginLeft: "25px" }}
                            ></div>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <Card>
                    <div style={{ padding: "30px", marginTop: "15px" }}>
                        <p
                            style={{
                                fontSize: "13px",
                                fontStyle: "normal",
                                color: "#000000",
                                fontWeight: 600
                            }}
                        >
                            CUSTOMER NOTIFICATIONS
                        </p>
                        <p style={{ color: "#6D7175" }}>
                            This will be the email and name that your customers
                            will see when they receive email notifications.
                        </p>
                        <div style={{ display: "flex", marginTop: "25px" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    label="Sender Name"
                                    value={
                                        formData.customer_notifications
                                            .sender_name
                                    }
                                    onChange={(val: any) => {
                                        setFormData({
                                            ...formData,
                                            customer_notifications: {
                                                ...formData.customer_notifications,
                                                sender_name: val
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ width: "50%", marginLeft: "25px" }}>
                                <TextField
                                    label="Reply-to-email"
                                    value={
                                        formData.customer_notifications
                                            .reply_to_email
                                    }
                                    onChange={(val: any) => {
                                        setFormData({
                                            ...formData,
                                            customer_notifications: {
                                                ...formData.customer_notifications,
                                                reply_to_email: val
                                            }
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <Card>
                    <div style={{ padding: "30px", marginTop: "15px" }}>
                        <p
                            style={{
                                fontSize: "13px",
                                fontStyle: "normal",
                                color: "#000000",
                                fontWeight: 600
                            }}
                        >
                            BILLING DETAILS
                        </p>
                        <p style={{ color: "#6D7175" }}>
                            We will use this email for billing.
                        </p>
                        <div style={{ display: "flex", marginTop: "25px" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    label="Billing Email"
                                    value={
                                        formData.billing_details.billing_email
                                    }
                                    onChange={(val: any) => {
                                        setFormData({
                                            ...formData,
                                            billing_details: {
                                                ...formData.billing_details,
                                                billing_email: val
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div
                                style={{ width: "50%", marginLeft: "25px" }}
                            ></div>
                        </div>
                    </div>
                </Card>
            </div>
            <div>
                <Card>
                    <div style={{ padding: "30px", marginTop: "15px" }}>
                        <p
                            style={{
                                fontSize: "13px",
                                fontStyle: "normal",
                                color: "#000000",
                                fontWeight: 600
                            }}
                        >
                            MULTIPASS DETAILS
                        </p>
                        <p style={{ color: "#6D7175" }}>
                            We need multipass token to enable customer accounts
                            for you..
                        </p>
                        <div style={{ display: "flex", marginTop: "25px" }}>
                            <div style={{ width: "50%" }}>
                                <TextField
                                    label="Multipass Token"
                                    value={multipassToken}
                                    onChange={(val: any) => {
                                        setMultipassToken(val);
                                    }}
                                />
                            </div>
                            <div
                                style={{ width: "50%", marginLeft: "25px" }}
                            ></div>
                        </div>
                    </div>
                </Card>
            </div>
            <div style={{ display: "flex", marginTop: "25px" }}>
                <div>
                    <Button
                        primary
                        loading={dataSaving}
                        onClick={() => {
                            saveForm();
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div style={{ marginLeft: "15px" }}>
                    <Button
                        disabled={dataSaving}
                        onClick={() => {
                            history.push("/");
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Page>
    );
}

export default GeneralSettings;
