import PageWrapper from "../../components/Common/PageWrapper/PageWrapper";
import Settings from "./Settings";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import * as _ from "lodash";
import { saveColorSettings, getColorSettings } from "../../API";

import { GlobalContext } from "../../Contexts/GlobalContext";
import {
    Card,
    Stack,
    RadioButton,
    Banner,
    hsbToHex,
    hslToRgb,
    RGBColor,
    RGBAColor,
    hsbToRgb
} from "@shopify/polaris";
import eventBus from "../../common/EventBus";
import "./design.scss";

export interface colorProps {
    label: string;
    css_name: string;
    value: {
        hue: number;
        saturation: number;
        brightness: number;
        alpha: number;
    };
    hexValue: string;
    rgbValue: RGBAColor | RGBColor;
}
export interface dataPropsForSettings {
    colorProperties: colorProps[];
    isCustomSelected: string;
}
export interface iSettingsProps {
    data: dataPropsForSettings;
    setData: (data: dataPropsForSettings) => any;
}

function Design() {
    const history = useHistory();
    const {
        updateValueChange,
        didValueChange,
        dataSubmitting,
        updateDataSubmitting
    } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);

    let colorProperties: colorProps[] = [
        {
            label: "Page Background",
            css_name: "page_background",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 1,
                alpha: 1
            },
            hexValue: "#ffffff",
            rgbValue: {
                red: 255,
                blue: 255,
                green: 255
            }
        },
        {
            label: "Section Background",
            css_name: "section_background",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 1,
                alpha: 1
            },
            hexValue: "#ffffff",
            rgbValue: {
                red: 255,
                blue: 255,
                green: 255
            }
        },
        {
            label: "Heading",
            css_name: "heading",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 0,
                alpha: 1
            },
            hexValue: "#000000",
            rgbValue: {
                red: 0,
                blue: 0,
                green: 0
            }
        },
        {
            label: "Sub Heading",
            css_name: "sub_heading",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 0,
                alpha: 1
            },
            hexValue: "#000000",
            rgbValue: {
                red: 0,
                blue: 0,
                green: 0
            }
        },
        {
            label: "Text",
            css_name: "text",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 0,
                alpha: 1
            },
            hexValue: "#000000",
            rgbValue: {
                red: 0,
                blue: 0,
                green: 0
            }
        },
        {
            label: "Links",
            css_name: "links",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 0,
                alpha: 1
            },
            hexValue: "#000000",
            rgbValue: {
                red: 0,
                blue: 0,
                green: 0
            }
        },
        {
            label: "Buttons",
            css_name: "buttons",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 0,
                alpha: 1
            },
            hexValue: "#000000",
            rgbValue: {
                red: 0,
                blue: 0,
                green: 0
            }
        },
        {
            label: "Errors",
            css_name: "errors",
            value: {
                hue: 1,
                saturation: 0,
                brightness: 0,
                alpha: 1
            },
            hexValue: "#000000",
            rgbValue: {
                red: 0,
                blue: 0,
                green: 0
            }
        }
    ];
    const [data, setData] = useState<dataPropsForSettings>({
        colorProperties,
        isCustomSelected: "default"
    });
    const [submitForm, setSubmitForm] = useState(false);

    const [discardForm, setDiscardForm] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [initialData, setInitialData] = useState({
        colorProperties,
        isCustomSelected: "default"
    });
    const handleDiscard = async () => {
        console.log("discard design settings page");
        console.log(initialData);
        setDiscardForm(true);
    };
    const saveData = async () => {
        console.log("Calling save data....");
        setSubmitForm(false);
        updateDataSubmitting(true);
        const result = await saveColorSettings(
            data.colorProperties,
            data.isCustomSelected === "custom"
        );
        if (result) {
            updateDataSubmitting(false);
            const tempData = {
                ...data
            };
            setInitialData({
                ...tempData
            });
            setData({
                ...tempData
            });

            setShowSuccessMessage(true);
        } else {
            updateDataSubmitting(false);
            setShowErrorMessage(true);
        }
    };

    useEffect(() => {
        let mounted = true;

        if (mounted && discardForm) {
            setData(initialData);
            setDiscardForm(false);
        }
        return () => {
            mounted = false;
        };
    }, [discardForm]);
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            console.log("mounted event");
            eventBus.on("BAH:discardDesignSettingsForm", (data: any) => {
                handleDiscard();
            });
        }
        return () => {
            console.log(
                "unmounted event listener: discard current form in settings page"
            );
            eventBus.remove("BAH:discardDesignSettingsForm");

            mounted = false;
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            console.log("COMPONENT MOUNTED EVENT LISTENRER");
            eventBus.on("BAH:saveDesignSettingsForm", (data: any) => {
                setSubmitForm(true);
            });
        }
        return () => {
            console.log("unmounted event");
            eventBus.remove("BAH:saveDesignSettingsForm");
            mounted = false;
        };
    }, []);
    useEffect(() => {
        let mounted = true;

        if (mounted && submitForm) {
            console.log("save data");
            saveData();
        }
        return () => {
            mounted = false;
        };
    }, [submitForm]);
    useEffect(() => {
        console.log("initial data use effect running");
        let mounted = true;
        if (mounted) {
            if (!loading) {
                console.log("initial data updated");
                console.log(initialData);
                console.log(`isEqual-iD = ${_.isEqual(initialData, data)}`);
                updateValueChange(!_.isEqual(initialData, data));
            }
        }

        return () => {
            mounted = false;
            updateValueChange(false);
        };
    }, [initialData, data]);
    useEffect(() => {
        const fetchData = async () => {
            const getResults = await getColorSettings();
            console.log(getResults);
            if (getResults) {
                setData({
                    isCustomSelected: getResults.isCustomColorEnabled
                        ? "custom"
                        : "default",
                    colorProperties: getResults.color
                });
                setInitialData({
                    isCustomSelected: getResults.isCustomColorEnabled
                        ? "custom"
                        : "default",
                    colorProperties: getResults.color
                });
                setLoading(false);
            } else {
                alert("Something went wrong");
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    return (
        <PageWrapper
            title="Design"
            subtitle="Edit colors for your customer account"
            // separator={true}
            loading={loading}
            showPrimaryButtons={true}
            handleCancel={() => {
                handleDiscard();
            }}
            handleSave={() => {
                saveData();
            }}
            isDisabled={!didValueChange}
            id="design-page"
        >
            {/* <Settings componentChanged={componentChanged} /> */}
            {showSuccessMessage && (
                <Banner
                    title="Saved your design preferences"
                    status="success"
                    action={{
                        content: "Go To Dashboard",
                        onAction: () => {
                            history.push("/");
                        }
                    }}
                    onDismiss={() => {
                        setShowSuccessMessage(false);
                    }}
                />
            )}
            {showErrorMessage && (
                <Banner
                    title="Something went wrong while saving."
                    status="critical"
                    action={{
                        content: "Go To Dashboard",
                        onAction: () => {
                            history.push("/");
                        }
                    }}
                    onDismiss={() => {
                        setShowErrorMessage(false);
                    }}
                />
            )}

            <div style={{ marginBottom: "10px" }}></div>
            <Settings
                data={data}
                setData={(custom_data: any) => {
                    setData(custom_data);
                }}
            />
        </PageWrapper>
    );
}

export default Design;
