import React, { useState, useCallback } from "react";
import {
    Button,
    Stack,
    TextStyle,
    FormLayout,
    TextField,
    RadioButton,
    Checkbox
} from "@shopify/polaris";
import "../Promo.scss";
import { FreeShippingTypes } from "..";
interface PropsType {
    IndexVal: number;
    data: FreeShippingTypes;
    onDataChange: (index: number, val: FreeShippingTypes) => void;
}
function FreeShipping(props: PropsType) {
    const { data, onDataChange, IndexVal } = props;
    const handleUpdatedData = (fieldName: string, val: any) => {
        onDataChange(IndexVal, {
            ...props.data,
            [fieldName]: val
        });
    };
    return (
        <>
            <div className="redemptionStyle">
                <TextStyle variation="strong">Reward value</TextStyle>
            </div>
            <FormLayout>
                <Stack vertical>
                    <TextField
                        type="number"
                        label="Point Cost"
                        min={0}
                        value={`${data.reward_points_cost}`}
                        onChange={(val: any) => {
                            handleUpdatedData(`reward_points_cost`, val);
                        }}
                    />
                    <Checkbox
                        label="set a maximum shipping amount this reward can be applied to"
                        checked={data.max_shipping_amount_set}
                        onChange={(val: any) => {
                            handleUpdatedData(`max_shipping_amount_set`, val);
                        }}
                    />
                    <TextField
                        type="number"
                        label="Discount value"
                        min={0}
                        max={100}
                        value={`${data.reward_discount_value}`}
                        onChange={(val: any) => {
                            handleUpdatedData(`reward_discount_value`, val);
                        }}
                    />
                </Stack>
            </FormLayout>
        </>
    );
}

export default FreeShipping;
