import {
    Button,
    ButtonGroup,
    Card,
    Collapsible,
    Icon,
    TextContainer
} from "@shopify/polaris";
import React, { useState, useCallback, useContext } from "react";
import dot from "../../assets/dotIcon.svg";
import {
    ChevronDownMinor,
    ChevronUpMinor,
    CircleTickOutlineMinor,
    CircleCancelMajor
} from "@shopify/polaris-icons";
import { SwitchOffIcon, SwitchOnIcon } from "../../common/icons";
const CardPage = (props: any) => {
    const { openCard, handleCardOpen, changeEnableDisable } = props;

    const handleButtonActivate = () => {
        changeEnableDisable(!props.isEnabled);
    };

    return (
        <div
            style={{
                paddingTop: "10px",
                position: "relative",
                cursor: "pointer"
            }}
            onClick={(e) => {
                if (openCard == props.type) {
                    handleCardOpen("default");
                } else {
                    handleCardOpen(props.type);
                }
            }}
        >
            <Card>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 25,
                        backgroundColor:
                            openCard == props.type ? "#FAFBFB" : "white"
                    }}
                >
                    <div
                        style={{ paddingLeft: 10, cursor: "grab" }}
                        {...props.dragHandle}
                    >
                        <img src={dot} alt="dot icon" height={20} />
                    </div>
                    <div
                        style={{
                            flexDirection: "column",
                            paddingLeft: 20
                        }}
                    >
                        <div
                            style={{
                                color: "#222B36",
                                fontSize: "14px",
                                lineHeight: "24px",
                                fontWeight: "bold"
                            }}
                        >
                            {props.heading}
                        </div>
                        <div
                            style={{
                                color: "#6D7175",
                                fontSize: "14px",
                                lineHeight: "20px"
                            }}
                        >
                            {props.subHeading}
                        </div>
                    </div>
                    {props.isFormCompelted ? (
                        <div style={{ flexGrow: 1, marginLeft: "25px" }}>
                            <div
                                style={{
                                    display: "inline-flex",
                                    flex: "0 0 auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "3.2rem",
                                    height: "3.2rem",
                                    marginRight: "1.6rem",
                                    borderRadius: "50%",
                                    borderColor: "var(--p-icon-success)",
                                    color: "var(--p-icon-success)",
                                    fill: "var(--p-icon-success)"
                                }}
                            >
                                <CircleTickOutlineMinor height="25" />
                            </div>
                        </div>
                    ) : (
                        <div style={{ flexGrow: 1, marginLeft: "25px" }}>
                            <div
                                style={{
                                    display: "inline-flex",
                                    flex: "0 0 auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "3.2rem",
                                    height: "3.2rem",
                                    marginRight: "1.6rem",
                                    borderRadius: "50%",
                                    borderColor: "var(--p-icon-critical)",
                                    color: "var(--p-icon-critical)",
                                    fill: "var(--p-icon-critical)"
                                }}
                            >
                                <CircleCancelMajor height="25" />
                            </div>
                        </div>
                    )}
                    <div style={{ paddingLeft: 10 }}>
                        {!props.isEnabled ? (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleButtonActivate();
                                }}
                            >
                                <SwitchOffIcon />
                            </div>
                        ) : (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleButtonActivate();
                                }}
                            >
                                <SwitchOnIcon />
                            </div>
                        )}
                    </div>
                    <div style={{ paddingLeft: 20 }}>
                        <Button
                            plain
                            monochrome
                            ariaExpanded={openCard == props.type}
                            ariaControls="basic-collapsible"
                        >
                            {openCard != props.type
                                ? ((
                                      <Icon
                                          source={ChevronDownMinor}
                                          accessibilityLabel="upIcon-card"
                                      />
                                  ) as unknown as string)
                                : ((
                                      <Icon
                                          source={ChevronUpMinor}
                                          accessibilityLabel="upIcon-card"
                                      />
                                  ) as unknown as string)}
                        </Button>
                    </div>
                </div>
            </Card>

            <div style={{ width: "100%" }}>
                <Collapsible
                    open={openCard == props.type}
                    id="basic-collapsible"
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out"
                    }}
                    expandOnPrint
                >
                    <div style={{ borderTop: "2px solid #D9D9DA" }}>
                        <Card sectioned>
                            <div
                                style={{
                                    width: "100%",
                                    backgroundColor: "white"
                                }}
                            >
                                {props.CollapisbleComponent}
                            </div>
                        </Card>
                    </div>
                </Collapsible>
            </div>
        </div>
    );
};

export default CardPage;
