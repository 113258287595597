import {
    Page,
    Layout,
    Button,
    Card,
    Select,
    Spinner,
    TextContainer,
    SkeletonBodyText,
    SkeletonDisplayText
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import {
    getEmailNotificationSettings,
    saveEmailNotificationSettings
} from "../../../API";
interface iFormData {
    productUpdates: boolean;
    blogAndNewsletters: boolean;
    offersAndPromos: boolean;
    appAnalytics: boolean;
    frequency_for_profit: string;
}
function NotificationSettings() {
    const [formData, setFormData] = useState<iFormData>({
        productUpdates: false,
        blogAndNewsletters: false,
        offersAndPromos: false,
        appAnalytics: false,
        frequency_for_profit: "daily"
    });
    const [initialLoading, setInitialLoading] = useState(true);
    const [dataSaving, setDataSaving] = useState<any>({
        type: "none",
        saving: false
    });
    const saveData = async (type: string, val: any) => {
        setDataSaving({
            type,
            saving: true
        });
        const saveThisData = {
            ...formData
        };
        if (type === "promo") {
            saveThisData.offersAndPromos = val;
        }
        if (type === "updates") {
            saveThisData.productUpdates = val;
        }
        if (type === "app_profit") {
            saveThisData.appAnalytics = val;
        }
        if (type === "frequency") {
            saveThisData.frequency_for_profit = val;
        }
        const result = await saveEmailNotificationSettings(saveThisData);
        if (result) {
        } else {
        }
        setFormData({
            ...saveThisData
        });
        setDataSaving({
            type: "none",
            saving: false
        });
    };

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            const fetchData = async () => {
                const result = await getEmailNotificationSettings();
                if (result) {
                    setInitialLoading(false);
                    setFormData({ ...result });
                }
            };
            fetchData();
        }
        return () => {
            mounted = false;
        };
    }, []);

    if (initialLoading) {
        return (
            // <div style={{ textAlign: "center", marginTop: "25px" }}>
            //     <Spinner />
            // </div>
            <Page title="Notifications" subtitle="Edit notification settings">
                <div
                    style={{
                        border: "1px solid #E5E7E9",
                        marginTop: "20px",
                        marginBottom: "20px"
                    }}
                ></div>
                <Layout>
                    <Layout.Section oneHalf>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="medium" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                    </Layout.Section>
                    <Layout.Section oneHalf>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="medium" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                        <Card sectioned>
                            <TextContainer>
                                <SkeletonDisplayText size="small" />
                                <SkeletonBodyText />
                            </TextContainer>
                        </Card>
                    </Layout.Section>
                </Layout>
            </Page>
        );
    }
    return (
        <Page title="Notifications" subtitle="Edit notification settings">
            <div
                style={{
                    border: "1px solid #E5E7E9",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
            ></div>
            <Layout>
                <Layout.Section oneHalf>
                    <p
                        style={{
                            color: "#222B36",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 600
                        }}
                    >
                        App Profitability
                    </p>
                    <p style={{ color: "#5C5F62", marginTop: "10px" }}>
                        How much money is made from our app
                    </p>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <Card>
                        <div style={{ padding: "25px" }}>
                            {formData.appAnalytics ? (
                                <Button
                                    disabled={dataSaving.saving}
                                    loading={
                                        dataSaving.saving &&
                                        dataSaving.type === "app_profit"
                                    }
                                    onClick={() => {
                                        saveData("app_profit", false);
                                    }}
                                >
                                    {" "}
                                    Disable
                                </Button>
                            ) : (
                                <Button
                                    primary
                                    disabled={dataSaving.saving}
                                    loading={
                                        dataSaving.saving &&
                                        dataSaving.type === "app_profit"
                                    }
                                    onClick={() => {
                                        saveData("app_profit", true);
                                    }}
                                >
                                    {" "}
                                    Enable
                                </Button>
                            )}

                            <div
                                style={{
                                    border: "1px solid #E5E7E9",
                                    marginTop: "20px",
                                    marginBottom: "20px"
                                }}
                            ></div>
                            <Select
                                disabled={dataSaving.saving}
                                label="
                                Select Email Frequency"
                                options={[
                                    { label: "Daily", value: "daily" },
                                    { label: "Weekly", value: "weekly" },
                                    { label: "Monthly", value: "monthly" }
                                ]}
                                value={formData.frequency_for_profit}
                                onChange={(val: any) => {
                                    saveData("frequency", val);
                                }}
                            />
                            {dataSaving.saving &&
                                dataSaving.type === "frequency" && (
                                    <div style={{ marginTop: "15px" }}>
                                        <Spinner />
                                    </div>
                                )}
                        </div>
                    </Card>
                </Layout.Section>
            </Layout>
            <div
                style={{
                    border: "1px solid #E5E7E9",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
            ></div>
            <Layout>
                <Layout.Section oneHalf>
                    <p
                        style={{
                            color: "#222B36",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 600
                        }}
                    >
                        Updates
                    </p>

                    <p style={{ color: "#5C5F62", marginTop: "10px" }}>
                        Id nisi bibendum aliquet felis condimentum.
                    </p>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <Card>
                        <div style={{ padding: "25px" }}>
                            {formData.productUpdates ? (
                                <Button
                                    disabled={dataSaving.saving}
                                    loading={
                                        dataSaving.saving &&
                                        dataSaving.type === "updates"
                                    }
                                    onClick={() => {
                                        saveData("updates", false);
                                    }}
                                >
                                    {" "}
                                    Unsubscribe
                                </Button>
                            ) : (
                                <Button
                                    primary
                                    disabled={dataSaving.saving}
                                    loading={
                                        dataSaving.saving &&
                                        dataSaving.type === "updates"
                                    }
                                    onClick={() => {
                                        saveData("updates", true);
                                    }}
                                >
                                    {" "}
                                    Subscribe
                                </Button>
                            )}
                        </div>
                    </Card>
                </Layout.Section>
            </Layout>
            <div
                style={{
                    border: "1px solid #E5E7E9",
                    marginTop: "20px",
                    marginBottom: "20px"
                }}
            ></div>
            <Layout>
                <Layout.Section oneHalf>
                    <p
                        style={{
                            color: "#222B36",
                            fontSize: "16px",
                            lineHeight: "24px",
                            fontWeight: 600
                        }}
                    >
                        Promotions
                    </p>
                    <p style={{ color: "#5C5F62", marginTop: "10px" }}>
                        Id nisi bibendum aliquet felis condimentum.
                    </p>
                </Layout.Section>
                <Layout.Section oneHalf>
                    <Card>
                        <div style={{ padding: "25px" }}>
                            {formData.offersAndPromos ? (
                                <Button
                                    disabled={dataSaving.saving}
                                    loading={
                                        dataSaving.saving &&
                                        dataSaving.type === "promo"
                                    }
                                    onClick={() => {
                                        saveData("promo", false);
                                    }}
                                >
                                    {" "}
                                    Unsubscribe
                                </Button>
                            ) : (
                                <Button
                                    primary
                                    disabled={dataSaving.saving}
                                    loading={
                                        dataSaving.saving &&
                                        dataSaving.type === "promo"
                                    }
                                    onClick={() => {
                                        saveData("promo", true);
                                    }}
                                >
                                    {" "}
                                    Subscribe
                                </Button>
                            )}
                        </div>
                    </Card>
                </Layout.Section>
            </Layout>
        </Page>
    );
}

export default NotificationSettings;
