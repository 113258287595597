import React, { useState, useEffect, useCallback, useContext } from "react";
import {
    Stack,
    RadioButton,
    Layout,
    DropZone,
    Thumbnail,
    Caption,
    TextField,
    Button
} from "@shopify/polaris";
import { NoteMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import eventBus from "../../../common/EventBus";
import { ImageDataTypes } from "..";

interface PropsType {
    handleUpdatedData: any;
    data: ImageDataTypes;
}
function DropZoneComponent(props: PropsType) {
    let [file, setFile] = useState<any>();
    const { handleUpdatedData, data } = props;
    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFile((file: any) => acceptedFiles[0]),
        []
    );

    useEffect(() => {
        if (file) {
            handleUpdatedData(URL.createObjectURL(file));
        }
    }, [file]);
    useEffect(() => {
        eventBus.on("BAH:discardCurrentForm", (data: any) => {
            setFile(null);
        });
        return () => {
            eventBus.remove("BAH:discardCurrentForm");
        };
    }, []);
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

    const fileUpload = !file && <DropZone.FileUpload />;
    const uploadedFile = file && (
        <Stack>
            <img
                height={100}
                alt={file.name}
                src={
                    validImageTypes.includes(file.type)
                        ? (window.URL.createObjectURL(
                              file
                          ) as unknown as string)
                        : (NoteMinor as unknown as string)
                }
            />
            <div>
                {file.name} <Caption>{file.size} bytes</Caption>
            </div>
        </Stack>
    );

    return (
        <DropZone
            type={"image"}
            accept="image/*"
            allowMultiple={false}
            variableHeight={true}
            onDrop={handleDropZoneDrop}
        >
            {uploadedFile}
            {fileUpload}
        </DropZone>
    );
}

export default DropZoneComponent;
